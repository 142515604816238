import { elements } from '@evlop/web-components';
import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pages from "..";
import PagesList from "../../../../components/Admin/PagesList";
import { pagesCollection } from '../../../../redux/reducers';
const { Button, Link, Row, Col, Icon, Container, Section } = elements;

const AppPages = () => {
    const allPages = useSelector(s=>s.pages.records.data);
    const pages = _.filter(allPages, {platform: 'APP'});
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(pagesCollection.all());
    }, [dispatch])
    
    return (
        <Pages>
            <Row spaceBetween middle>
                <Col><h4>App pages</h4></Col>
                <Col><Link linkStyle="button" to="/admin/pages/app/add"><Icon color="white" size="xxs" className="lni lni-plus" /> Add Page</Link></Col>
            </Row>
            <PagesList pages={pages}/>
        </Pages>
    );
};

export default AppPages;
