import React from 'react';
import Developer from "..";
import breadForm from "../../../../generators/breadForm";
import {Worker} from "./index";

const AddScheduledTaskForm = breadForm(Worker, {
    code: 'function task(req){\n}\n\nmodule.exports=task;\n',
    timezone: 'UTC',
});

const AddScheduledTask = () => {
    return (<Developer><AddScheduledTaskForm /></Developer>  );
}
 
export default AddScheduledTask;
