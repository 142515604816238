import React from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/theme-monokai";


const CodeEditorField = ({form, field, placeholder, label, children, ...props})=>{
    return (
            <div>
                <label > {label} </label>
                <AceEditor 
                    mode="javascript"
                    theme="monokai"
                    showGutter={true}
                    highlightActiveLine={true}
                    enableBasicAutocompletion
                    tabSize={2}
                    showPrintMargin={false}
                    placeholder={placeholder} 
                    {...field} 
                    {...props}
                    onChange={(value)=>form.setFieldValue(field.name, value)} 
                    padding={10}
                    name={'code_editor'}
                    editorProps={{ $blockScrolling: true, $highlightPending: true }}
                    style={{width: '100%'}}
                     />             
            </div>
        )
};

CodeEditorField.defaultProps={
    minLines: 5,
    maxLines: 15,
    showGutter: false,
    showLineNumbers: false,
}

export default CodeEditorField;
