import React from "react";
import {elements} from '@evlop/web-components'

const SliderField = ({field, placeholder, label, hint, large, small, min=0, step=0.01, max=1})=>(
    <div className="form-group">
        <label className={`form-label ${large && 'label-lg'} ${small && 'label-sm'}`}>{label}</label>
        <elements.Container>
            <elements.Row middle>
                <elements.Col auto>
                    <input style={{width: '100%'}} type="range" min={min} max={max} step={step} className={` ${large && 'input-lg'} ${small && 'input-sm'}`} {...field} />
                </elements.Col>
                <elements.Col>
                    <input type="number" min={min} max={max} step={step} {...field} style={{textAlign: 'center', border: '1px solid #e8e8e8', backgroundColor: '#fff', outline: 'none', padding: '8px 3px', marginLeft: 4}} />
                </elements.Col>
            </elements.Row>
        </elements.Container>
    </div>
    );

export default SliderField;
