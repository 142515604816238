import React, { useMemo } from "react";
import Page from "../../../components/Page/index";
import {useSelector} from "react-redux";

const PageLoader = ({pageType, pageId}) => {
    const config = useSelector(s=>s.config);
    const {data:{[pageType]: pageTypeId}} = config;
    const showPageId = pageId || pageTypeId;

    return <Page showPageId={showPageId} />;
};


export default PageLoader;

