import React from "react";
import ReactDOM from "react-dom";
import "./externals";
import "./config";
import "./index.css";
import App from "./App";
import { pagesCollection, menusCollection, collectionsCollection } from "./redux/reducers/index";
import { getConfig, Model } from "@evlop/commons";
import registerModule from "./registerModule";
import createStore from "./redux/store";
import boot from "./boot";
import { keyBy } from "lodash";
import configureApp from "./config";


export default function initApp(installedPlugins){
    const store = createStore(installedPlugins);
    configureApp(store, installedPlugins);
    store.dispatch({type: 'INIT_APP'});
    boot(store);

    Promise.all([
        store.dispatch(menusCollection.all()),
        store.dispatch(pagesCollection.all()),
        store.dispatch(collectionsCollection.all()),
        store.dispatch(getConfig('web')),
        store.dispatch(getConfig('theme')),
        store.dispatch(getConfig('app')),
    ]).then(([,,{response: {data: collections}}])=>{
        const models = collections.map(collection=> {
            class CollectionModel extends Model{};
            const schema = keyBy(collection.formFields, 'name');
            CollectionModel.config(schema, {modelName: collection.name, form: collection.formFields, ...collection.options});
            return CollectionModel;
        })

        registerModule(store)({models});

        ReactDOM.hydrate(<App store={store} />, document.getElementById('root'));
    }).catch(()=>{
        alert('Error occourred')
    });
}