export function createNestedTree(items=[], parent = null){
    const result = items.filter(item=>((!parent && !item.parent) || item.parent === parent));
    const remainingItems = items.filter(item=>result.indexOf(item)===-1);
    return result.map(item=>{
        const newItem = {...item};
        newItem.children = createNestedTree(remainingItems, newItem.id);
        return newItem;
    });
}

export function getChildren (items=[], parent = null){
    const result = items.filter(item=>((!parent && !item.parent) || item.parent === parent));
    const remainingItems = items.filter(item=>result.indexOf(item)===-1);
    return result.reduce((previousItems, item)=>{
        const children = getChildren(remainingItems, item.id);
        return [...previousItems, ...children];
    }, result);
}

export function isFunction(toCheck){
    return typeof toCheck === "function"
}

export function loadPackage(name, version){
    const url = isExternalUrl(name)? `${name}/index.js` :`https://cdn.jsdelivr.net/npm/${name}@${version}/dist/index.js`;
    return loadJSONPModule({name: 'registerModule', url});
}

export const loadJSONPModule = ({url, name}) => {
    return fetch(url)
        .then(r=>r.text())
        .then(moduleData=>eval(`const ${name} = a => a;${moduleData};`))
};

export function isExternalUrl(url){
    const externalRegexp = /\w+:/;
    return externalRegexp.test(url);
}

export function slugify(text)
{
    return text.toString().toLowerCase()
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
        .replace(/\-\-+/g, '-')         // Replace multiple - with single -
        .replace(/^-+/, '')             // Trim - from start of text
        .replace(/-+$/, '');            // Trim - from end of text
}

export const delay = (time)=> new Promise(resolve=>{
   setTimeout(resolve, time);
});

export function shallowCompare(newObj, prevObj){
    const [k1, k2] = [Object.keys(newObj), Object.keys(prevObj)];
    if(k1.length !== k2.length) return true;

    for(const key of k1){
        if(newObj[key] !== prevObj[key]) return true;
    }
    return false;
}


