import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from "react-redux";
import { openMenuEditModal } from "../../../redux/reducers/editor";
import Select from 'react-select';
import FormGenerator from '../../../generators/FormGenerator';

const actions = [
    {label: 'None', value: ''},
    {label: 'Go to Page', value: 'page'},
    {label: 'Go to URL', value: 'link'},
    {label: 'Scope Action', value: 'bindKey'},
];

const AppActionSelectField = ({field: { name, value } = {}, form, label, platform, ...props})=>{
    // action type extracted from value
    const valueActionType = value && Object.keys(value)[0];

    const [actionType, setActionType] =  useState(valueActionType);

    useEffect(()=>{
        if(valueActionType && actionType !== valueActionType){
            setActionType(valueActionType);
        }
    }, [valueActionType]);

    const onChangeActionType = useCallback((selectedOption)=>{
        form.setFieldValue(name, null);
        setActionType(selectedOption.value);
    }, [name, form]);

    const selectedAction = useMemo(()=>{
        return actions.find(a=> a.value === actionType);
    }, [actionType])

    const fields = useMemo(()=>{
        switch(actionType){
            case 'page': 
                return [
                    { name: actionType, type: 'page', label: 'Page', platform: 'APP', placeholder: 'Select a Page'},
                    {type: 'page-params', name: `params`, label: 'Params', pageField: `${name}.page`}
                ];
            case 'link':
                return [{name: actionType, label: 'URL', placeholder: 'eg. https://example.com' }];
            case 'bindKey':
                return [{ name: actionType, label: 'Scope variable', placeholder: 'eg. user.logout' } ];
            default:
                return [];
        }
    }, [actionType]);

    return (
        <div>
            <label>{label}</label>
            <Select {...props} value={selectedAction} onChange={onChangeActionType} options={actions}/>
            <FormGenerator parent={name} fields={fields} />
        </div>
        );
}


const mapStateToProps = state=>({
    pages: state.pages
});

const mapDispatchToProps = dispatch=>({
    editMenu: id => dispatch(openMenuEditModal(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppActionSelectField);
