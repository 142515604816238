import React from 'react';
import { useSelector } from 'react-redux';
import Publishing from "..";
import breadForm from "../../../../generators/breadForm";
import {AppBuild} from "./index";

const AppBuildsForm = breadForm(AppBuild);

const AddAppBuild = () => {
    const appConfig = useSelector(s=>s.config.data.app);
    return ( 
    <Publishing>
        <AppBuildsForm defaultVaues={appConfig} />
    </Publishing> );
}
 
export default AddAppBuild;
