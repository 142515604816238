import { saveConfig } from "@evlop/commons";
import { withFormik } from "formik";
import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import FormGenerator from "../../../generators/FormGenerator";
import { useRouteMatch } from "react-router";
import TabMenu from "../../../components/Admin/TabMenu";
import { Button } from "@evlop/web-components/dist/src/Elements";

const onSubmit = async ({__configName, ...values}, { setSubmitting, props: {dispatch}})=>{
    try{
        await dispatch(saveConfig(values, __configName));
    } catch(e){
        alert('An Error ocourred while saving');
    }
    setSubmitting(false);
};

const Settings = ({handleSubmit, values, setFieldValue, isSubmitting, setValues, config, ...props}) => {
    const {params: {configName}} = useRouteMatch();
    const {fields:{[configName]: configFields = []}, fields: configs, data: {[configName]: data} } = config

    useEffect(()=>{
        setFieldValue('__configName', configName);
    }, [configName, setFieldValue]);

    const menuItems = useMemo(()=>{
        return Object.keys(configs).map(configName=>({
            key: configName, 
            label: configName, 
            routeName: 'ADMIN_CONFIGURATION', 
            params: {configName},
        }))
    }, [configs]);

    useEffect(()=>{
        if(data && Object.keys(data).length)
            setValues({...data, __configName: configName});
    }, [data]);

    return (
        <div className="admin">
            <h1>Settings</h1>
            <TabMenu menuItems={menuItems} />
            <form key={configName} onSubmit={handleSubmit}> 
                <FormGenerator fields={configFields}/>
                <Button disabled={isSubmitting} className="btn btn-primary">Save Settings</Button>
            </form>
        </div>
    );
};

const mapStateToProps = state=>({config:state.config});

export default connect(mapStateToProps)(withFormik({
    mapPropsToValues: ()=>({}),
    handleSubmit:onSubmit,
    form: 'Settings'
})(Settings));
