import { useCollectionRecord } from '@evlop/commons';
import { toPath } from 'lodash';
import React, { useMemo } from 'react';
import { connect } from "react-redux";
import FormGenerator from '../../../generators/FormGenerator';
import RecordSelectField from './RecordSelectField';

const DataConnectionSelectField = ({externalData:{resolvers: dataResolvers = [], connections=[]} = {}, ...props})=>{
    
    const optionsFieldName = useMemo(()=>{
        const optionsPathSegments = toPath(props.field.name)
        optionsPathSegments.splice(-1, 1, 'options')
        return optionsPathSegments.join('.');
    }, [props.field.name]);

    const connection = useCollectionRecord('data-connections', props.field.value);

    const dataResolver = useMemo(()=>{
        if(!connection) return null;
        return dataResolvers.find(dataResolver => dataResolver.connectionType === connection.type);
    }, [props.field.value, dataResolvers, connection]);

    return (<>
        <RecordSelectField {...props} collectionName="data-connections" />
        {!!dataResolver &&<FormGenerator parent={optionsFieldName} fields={dataResolver.connectionDataSourceOptions} /> }
    </>)
}


const mapStateToProps = state=>({
    externalData: state.externalData
});

export default connect(mapStateToProps)(DataConnectionSelectField);
