import React from "react";

const CheckboxField = ({field, form, placeholder, label, hint, large, small})=>(
    <div>
        <label>
            <input type="checkbox" checked={!!field.value} onChange={({target})=>form.setFieldValue(field.name, target.checked)} /> 
            {` ${label}`}
        </label>
        <div>{hint}</div>
    </div>
    );
export default CheckboxField;
