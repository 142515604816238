import { request } from '@evlop/commons';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from "react";
import Select from 'react-select/async';
import { useDispatch } from 'react-redux';

const mapOptionToValue = s => s && s.value

const RecordSelectField = ({field:{value, name, onChange}, form, multiple, useFullValue = false, label, hint, collectionName, ...props})=> {
    const [selectedOptions, setSelectedOption] = useState()
    const dispatch = useDispatch();
    const loadOptions = useCallback((query)=>{
        return dispatch(request(`${collectionName}`, {search: query}))
        .then(({response: {data = []} = {}}) => data.map((d)=> ({label: d.title || d.name, value: d.id})))
    }, [collectionName, dispatch]);

    const onSelect = useCallback(value=>{
        setSelectedOption(value);
        if(multiple){
           return form.setFieldValue(name, value && value.map(mapOptionToValue))
        }
        return form.setFieldValue(name, value && mapOptionToValue(value))
    }, [multiple, name, form])

    useEffect(()=>{
        if(selectedOptions || !value || (Array.isArray(value) && !value.length)) return
        const ids = _.castArray(value);
        const requests = ids.map(id=> dispatch(request(`${collectionName}/${id}`)))
        Promise.all(requests)
            .then(responses => responses.map(action => action.response))
            .then(records => records.map(rec => ({label: rec.title || rec.name, value: rec.id})))
            .then(records=>{
                if(multiple) setSelectedOption(records)
                else setSelectedOption(records[0])
            });
    }, [value, multiple, collectionName, selectedOptions, dispatch])

    return (
        <div>
            <label>{label}</label>
            <Select isClearable isMulti={multiple} onChange={onSelect} value={selectedOptions} label={label} defaultOptions loadOptions={loadOptions} {...props} />
        </div>
    );
};

export default RecordSelectField;
