import React, {useEffect} from "react";
import {withRouter} from "react-router-dom";
import {login} from "../../redux/reducers/user";
import {connect} from "react-redux";

export const AdminLogin = ({user, login, history}) => {
    const {isLoggedIn} = user;
    useEffect(()=>{
        if(!isLoggedIn){
            login({force: true});
        }else{
            history.push('/');
            // alert()
        }
    }, [isLoggedIn]);
    return (
        <div>
            Please <a to={'/'} onClick={login}>Login</a> to continue
        </div>
    );
};

const mapStateToProps = state=>({
    user: state.user,
});

const mapDispatchToProps = dispatch=>({
    login: ()=>dispatch(login(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminLogin));
