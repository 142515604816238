import { chain, defaults, find, get, size } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from "react-redux";
import { matchPath } from 'react-router';
import FormGenerator from '../../../generators/FormGenerator';
import SelectField from "./SelectField";

const ComponentSelectField = ({platform = 'WEB', componentType: type = 'block', componentModel, fieldNameWithoutParent, ...props})=>{
    const components = useSelector(s=>s.components.list)
    const options = useMemo(()=>{
        return chain(components)
        .filter({platform})
        .filter(c=> matchPath(c.type, { path: type }))
        .map(component=>({label: component.displayName || component.name, value: component.uuid}))
        .value()
    }, [components, platform, type])
    const componentId = get(props, 'field.value');
    const selectedComponent = useMemo(()=>find(components, {uuid: componentId}), [components, componentId]);
    const configurationOptions = get(selectedComponent, 'configurationOptions', []);
    const {form} = props;

    const componentConfigurationOptionsName = useMemo(()=>{
        const fieldName = String(get(props.field, 'name', ''))
        return fieldName.substring(0, size(fieldName) - size(fieldNameWithoutParent)) + componentModel;
    }, [props.field, fieldNameWithoutParent, componentModel])

    const onChange = useCallback(newComponentId=>{
        const component = find(components, {uuid: newComponentId});
        const currentComponentConfig = get(form, ['values', componentConfigurationOptionsName]);
        const defaultValues = get(component, 'defaultValues');
        form.setFieldValue(componentConfigurationOptionsName, defaults(currentComponentConfig, defaultValues));
    }, [components, form, componentConfigurationOptionsName]);

    return (<>
        <SelectField {...props} onChange={onChange} options={options}/>
        <FormGenerator parent={componentConfigurationOptionsName} fields={configurationOptions} />
    </>)
}

export default ComponentSelectField;
