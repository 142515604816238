import React, {useCallback} from "react";
import {useDispatch} from "react-redux";
import {closeModal} from "../../redux/reducers/modals";
import styled from "styled-components";

const Overlay = styled.div({
    position: 'fixed',
    top: 0,
    left:0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.25)',
}, ({theme:{maxZIndex}})=>({
    zIndex: maxZIndex - 1
}));
const StyledModal = styled.div({
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 600,
    maxWidth: 'calc(100vw - 5px)',
    maxHeight: 'calc(100vh - 5px)',
    minHeight:200,
}, ({theme:{maxZIndex}})=>({
    zIndex: maxZIndex
}));

const Modal = ({id, component: Component, props}) => {

    const dispatch = useDispatch();

    const close = useCallback(()=>{
        dispatch(closeModal(id));
    }, [dispatch, id]);

    return (
        <>
            <Overlay onClick={close} />
            <StyledModal active>
                <Component closeModal={close} modalId={id} {...props}/>
            </StyledModal>
        </>

    );
};

export default Modal;
