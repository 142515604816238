import React from 'react'
import styled from 'styled-components'
import { DropTarget } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'
import {COMPONENT} from "./ComponentTypes";

const DropableContainer = styled.div(({theme})=>({
        display: 'none',
        transition: 'all 0.25s',
        height: 'auto',
        backgroundColor: theme.adminPrimaryColor,
        border: '2px dashed #17559b',
        color: '#fff',
        padding: 20,
    }),
    ({canDrop})=>canDrop && {
        display: 'block',
        opacity: 0,
    },
    ({canDrop, isOver})=>canDrop && isOver && {
        visibility: 'visible',
        opacity: 1,
    });

const Dropable = ({isOver, canDrop, connectDropTarget}) => {
    return connectDropTarget(
        <div style={{paddingBottom: 200}}>
            <DropableContainer canDrop={canDrop} isOver={isOver}>
                Drag here and release to add selected component here
            </DropableContainer>
        </div>
    )
};

export default DropTarget(
    ()=>[COMPONENT, NativeTypes.URL],
    {
        drop(props, monitor) {
            props.onDrop(monitor.getItem());
        },
    },
    (connect, monitor) => ({
        connectDropTarget: connect.dropTarget(),
        isDragging: false,
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
    }),
)(Dropable)
