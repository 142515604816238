import { Form, withFormik } from "formik";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import FormGenerator from "../../generators/FormGenerator";

let oldValue = {};

const onChange = _.debounce((newValues, d, {iframe:{current:{contentWindow}}, options:{id},} ) => {

    const keys = _.union(_.keys(newValues), _.keys(oldValue));
    _.forEach(keys, (key)=>{
        if(oldValue[key]!== newValues[key]){
            contentWindow.postMessage({type: 'elementValueUpdated', id, key, name: key, value: newValues[key]}, '*');
        }
    })
    oldValue = newValues;
}, 200);

const ConfigurationForm = (props) => {
    const { setFieldValue, setValues, changePanel, isActive, iframe} = props;

    const [scope, setScope] = useState({});
    const [options, setOptions] = useState({id: null, name: '', values:{}, fields:[]});
    const {id, fields} = options;

    useEffect(() => {
        if(!isActive){
            setOptions({id: null, values: [], fields: []})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive]);

    useEffect(()=>{
        const { current:{contentWindow} } = iframe;
        contentWindow.postMessage({type: 'elementFocused', id}, '*');
        if(id){
            changePanel('CONFIGURATION_FORM');
        }
        return ()=> { contentWindow.postMessage({type: 'elementBlurred', id}, '*'); }
    }, [changePanel, id, iframe]);

    useEffect(()=>{
        onChange(props.values, {}, {options, iframe})
    },[id, iframe, options, props.values]);

    const onWindowMessage = useCallback(({ data = {} })=>{
        const { type, id: elementId, values, fields} = data;
        switch(type) {
            case 'updateConfiguration':
                setOptions({id: elementId, values, fields});
                props.resetForm();
                oldValue = values;
                setValues(values);
                break;
            case 'changeConfigurationValue':{
                const {name: key, value } = data;
                setFieldValue(key, value);
                break;
            }
            case 'scopeUpdated':
                const { scope } = data;
                try{
                    setScope(JSON.parse(scope));
                } catch(e){}
                break;
            default:

        }
    }, [setOptions, props, setValues, setFieldValue]);

    useEffect(()=>{
        window.addEventListener('message', onWindowMessage)
        return ()=> window.removeEventListener('message', onWindowMessage)
    }, [onWindowMessage]);

    return (
        <Form>
            <div style={{padding: 10, width: 300, display: isActive ? 'block' : 'none'}}>
                <FormGenerator key={id} fields={fields} />
            </div>
        </Form>
    );
};

export default withFormik({})(ConfigurationForm);
