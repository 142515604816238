import { Model } from '@evlop/commons';
import React from 'react';
import Publishing from '..';
import breadBackendListView from "../../../../generators/breadBackendListView";

export const schema = {
    name:{label: 'Domain Name'},
};

export const options = {
    modelName: 'domains',
    listPageRouteName : 'DOMAINS',
    detailsPageRouteName : 'DOMAINS_EDIT',
    singularName: 'Domain',
    collectionName: 'domains',
    schema,
};

export class Domain extends Model{}
Domain.config(schema, options);

const DomainsListView = breadBackendListView(Domain);

const Domains = () => {
    return ( 
    <Publishing>
        <DomainsListView />
    </Publishing> );
}

export default Domains;
