import { useCollectionRecord, collectionUpdateRecord, collectionAddRecord } from '@evlop/commons';
import { elements } from '@evlop/web-components';
import { Form, withFormik } from "formik";
import _ from "lodash";
import pluralize from "pluralize";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { slugify } from "../shared/helpers";
import FormGenerator from "./FormGenerator";

const { Button, Row, Col, Section } = elements;


const breadForm =  ({ formFields: fields, slugifyField, singularName, pluralName = pluralize(singularName), collectionName = pluralName}, staticDefaultValues = {})=>connect()(withRouter(
    withFormik({
    mapPropsToValues: ({defaultVaues = {}})=>({...staticDefaultValues, ...defaultVaues}),
    handleSubmit: (values, {props, setSubmitting})=>new Promise((resolve, reject)=>{
        const {match:{params:{id}}, history, dispatch} = props;
        const data = {...values};

        if( slugifyField && values[slugifyField]){
            data.slug = slugify(values[slugifyField]);
        }
        const action = id? collectionUpdateRecord(collectionName, id, data) : collectionAddRecord(collectionName, data);
        dispatch(action)
            .then(({error, success, response})=>{
                if(success){
                    resolve();
                    history.goBack();
                } else if(error) {
                    reject(response);
                }
            }).then(()=>{
                setSubmitting(false);
            })
    }),
})((props)=>{


    const {handleSubmit, setValues, isSubmitting, match:{params:{id} = {}} = {}} = props;

    const record = useCollectionRecord(collectionName, id);
    
    useEffect(()=>{
        if(!id) return;
        setValues(_.omit(record, ['id']));
    }, [id, record, setValues]);


    return (
        <>
            <h1>{!!id? 'Edit' : 'Add'} {singularName}</h1>
            <Form onSubmit={handleSubmit}>
                <FormGenerator fields={fields}/>
                <Section margins={[10, 0]}>
                <Row right>
                    <Col>
                        <Button sharp disabled={isSubmitting}>Save {singularName}</Button>
                    </Col>
                </Row>
                </Section>
            </Form>
        </>
    )
})));

export default breadForm;
