import { filter, reduce, sortBy } from "lodash";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

const  withPlatform = platform => Component => () =>{
    const {components} = useSelector(s => s.components);
    const providerComponents = useMemo(
        () =>
          sortBy(
            filter(components, {platform, type: 'provider'}),
            'priority',
          ),
        [components],
      );
    
      return useMemo(
        () =>
          reduce(
            providerComponents,
            (_children, ProviderComponent) => (
              <ProviderComponent
                key={ProviderComponent.uuid}
                children={_children}
              />
            ),
            <Component />,
          ),
        [providerComponents],
      );
}

export default withPlatform;