import React from 'react';
import Developer from "..";
import breadForm from "../../../../generators/breadForm";
import {Worker} from "./index";

const AddWorkerForm = breadForm(Worker, {
    code: 'function task(req){\n}\n\nmodule.exports=task;\n',
});

const AddWorker = () => {
    return (<Developer><AddWorkerForm /></Developer>  );
}
 
export default AddWorker;
