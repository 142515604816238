import {
  addAdminMenuItem,
  addComponentToList,
  registerComponent,
  registerDataSource,
  registerFormField,
  registerModel, 
  withCollection,
  withCollectionRecord,
} from "@evlop/commons";
import { constantCase } from "change-case";
import pluralize from "pluralize";
import breadBackendListView from "../../generators/breadBackendListView";
import breadForm from "../../generators/breadForm";
import { getPath, registerAdminPage, registerPageType } from "../../redux/reducers/routes";
import { assign } from "lodash";



const registerModule = (store) => function (plugin) {
  const {dispatch} = store;
  const {
    name: pluginName = 'unknown',
    version: pluginVersion = 'unknown',
    components = [],
    appComponents = [],
    adminPages = [],
    initializers = [],
    boot = initializers,
    models = [],
    formFields = [],
    pageTypes = [],
    dataSources = [],
  } = plugin;

components.forEach((component)=>{
    assign(component,{package: pluginName,platform: 'WEB'})
    if(component instanceof Function || component.PreviewComponent) {
        dispatch(registerComponent(component));
    }
    dispatch(addComponentToList(component));
});

appComponents.forEach((component)=>{
    assign(component,{package: pluginName, platform: 'APP'})
    if(component instanceof Function || component.PreviewComponent) {
        dispatch(registerComponent(component));
    }
    dispatch(addComponentToList(component));
});


formFields.forEach(formFiled=>{
    dispatch(registerFormField({
        selector: formFiled.selector,
        component: formFiled
    }));
});

pageTypes.forEach(pageType=>dispatch(registerPageType(pageType)));

adminPages.forEach(page=>dispatch(registerAdminPage(page.routeId, {
    path: page.path,
    component: page,
})));

boot.forEach(( booter)=>booter(store.dispatch, store));

models.forEach(model=>{
    dispatch(registerModel(model));
    const { hasCMS = true, isAccessibeleFromFrontend=true, collectionName='', singularName='', pluralName = pluralize(singularName)} = model;
    if(collectionName && hasCMS) {
        const routeName = constantCase(pluralName);
        const routeNameSingular = constantCase(singularName);
        store.dispatch(registerAdminPage(`${routeName}_ADMIN_LIST`, {
            path: `/${collectionName}`,
            component: breadBackendListView(model)
        }));
        store.dispatch(registerAdminPage(`${routeNameSingular}_ADD`, {
            path: `/${collectionName}/add`,
            component: breadForm(model)
        }));

        store.dispatch(registerAdminPage(`${routeNameSingular}_EDIT`, {
            path: `/${collectionName}/:id/edit`,
            component: breadForm(model)
        }));

        store.dispatch(addAdminMenuItem(`${routeName}_MENU`, {
            name: pluralName,
            path: ()=>store.dispatch(getPath(`${routeName}_ADMIN_LIST`)),
            permission: 'MANAGE_COLLECTIONS'
        }));
    }

    if(isAccessibeleFromFrontend){
        store.dispatch(registerPageType(withCollection(collectionName)));
        store.dispatch(registerPageType(withCollectionRecord(collectionName)));
    }
});

dataSources.forEach(dataSource => {
    store.dispatch(registerDataSource(dataSource));
});
}

export default registerModule;