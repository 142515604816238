import {restApiCollection} from "@mukti107/redux-rest";

const collection = restApiCollection;

export const pagesCollection = collection('Page');
export const pluginsCollection = collection('Plugin');
export const menusCollection = collection('Menu');
export const layoutsCollection = collection('Layout');
export const domainCollection = collection('Domain');
export const collectionsCollection = collection('collections');
