global.reactVirtualized = require('react-virtualized');

global.changeCase = require('change-case');

global.axios = require('axios');

global.jsonp = require('jsonp');

global.lodash = require('lodash');

global.pluralize = require('pluralize');

global.polished = require('polished');

global.propTypes = require('prop-types');

global.queryString = require('query-string');

global.react = require('react');

global.reactColor = require('react-color');

global.reactContenteditable = require('react-contenteditable');

global.reactDnd = require('react-dnd');

global.reactDndHtml5Backend = require('react-dnd-html5-backend');

global.reactDom = require('react-dom');

global.reactHelmet = require('react-helmet');

global.reactRedux = require('react-redux');

global.reactRouter = require('react-router');

global.reactRouterDom = require('react-router-dom');

global.reactSelect = require('react-select');

global.reactSlick = require('react-slick');

global.recompose = require('recompose');

global.redux = require('redux');

global.reduxForm = require('redux-form');

global.reduxThunk = require('redux-thunk');

global.socketIoClient = require('socket.io-client');

global.styledComponents = require('styled-components');

global.reactNative = require('react-native-web');

global.reduxRest = require('@mukti107/redux-rest');

global.reactNavigation = {};

global.contexts = require('@evlop/commons');

global.actions = require('@evlop/commons');

global.hooks = require('@evlop/commons');

global.commons = require('@evlop/commons');

global.webComponents = require('@evlop/web-components');

global.nativeComponents = require('@evlop/native-components');

global.isWeb = true;
