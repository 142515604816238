import React, {useCallback, useMemo} from "react";
import styled from 'styled-components';
import {useSelector} from "react-redux";
import { matchPath } from "react-router";
import { get } from "lodash";

const DragableElement = styled.div({
    overflow: 'hidden',
    width: 'calc(50% - 10px)',
    border: '1px solid #ccc',
    margin:5,
    height: 100,
    textAlign: 'center',
    fontSize: 14,
    cursor: 'move',
    backgroundColor: '#fff',
    transition:'transform 0.25s',
    position: 'relative',
    ':hover':{
        transform: 'scale(1.07)',
        zIndex: 1,
    },
}, ({thumbnail})=>({
    backgroundImage: `url("${thumbnail}")`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
}));

const ComponentName = styled.div({
    position: 'absolute',
    right: 0,
    left: 0,
    bottom: 0,
    overflow: 'hidden',
    padding: 4,
    backgroundColor: 'rgba(0,0,0,0.03)'
});

const getComponentPreviewUrl= component=>{
    if(component.thumbnail) return component.thumbnail;
    if(component.source && component.source.url.includes(':9000')) return null;

    const hash = get(component, 'uuid') //  btoa(JSON.stringify(component));
    const url = `${window.location.origin}/component-preview/${hash}`;
    return `https://render.createwebsite.io/?url=${url}`
};

const Components = ({isActive, platform, type = 'block', changePanel}) => {

    const {list} = useSelector(s=>s.components);

    const components = useMemo(()=>{
        return list.filter(c=> c.platform === platform && matchPath(c.type, { path: type }))
    }, [list, platform, type]);

    const onDrag = useCallback((e)=>{
        const uuid = e.target.getAttribute('data-uuid');
        e.dataTransfer.setData('text/plain', `component:${uuid}`)
    }, []);

    return (
        <div style={{display: isActive? 'block' : "none",}}>
            <div style={{
                width: 300,
                padding: -5,
                display:  'flex',
                flexWrap: 'wrap'
            }}>
            {components
            .map(component=> {
                const thumbnail = getComponentPreviewUrl(component);
                return (
                <DragableElement
                    thumbnail={thumbnail}
                    key={component.uuid}
                    data-uuid={component.uuid}
                    onDragStart={onDrag}
                    draggable={true}>
                        <ComponentName>
                            {component.displayName || component.name}
                        </ComponentName>
                </DragableElement>
                )
                })
            }
            </div>
        </div>
    );
};

export default Components;
