import { find, get, map } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import CreatableSelect from 'react-select/creatable';

const TagsField = ({field, form, multiple = true, placeholder, label, hint, options=[]})=>{
    
    const value = useMemo(()=>{
        if(!field.value) return;
        if(multiple){
            map(field.value, v=>({label: v, value: v}))
        } else {
            return find(options, {value: field.value}) || {label: field.value, value: field.value}
        }
    }, [field.value, multiple, options]);

    const onChange = useCallback((selection)=>{
        if(multiple){
            form.setFieldValue(field.name, map(selection, 'value'))
        } else {
            form.setFieldValue(field.name, get(selection, 'value'))
        }
    }, [field.name, form, multiple])

    return (
    <div>
    <label>{label}</label>
        <CreatableSelect
            isMulti={multiple}
            placeholder={placeholder}
            onChange={onChange}
            formatCreateLabel={i=>`${i}`}
            noOptionsMessage={()=> 'Type and press enter to add'}
            options={options}
            value={value}
        />
        <small className="form-input-hint">{hint}</small>
</div>)};

export default TagsField;
