import React from 'react';
import Developer from "..";
import breadForm from "../../../../generators/breadForm";
import {Collection} from "./index";

const AddCollectionForm = breadForm(Collection);

const AddCollection = () => {
    return (<Developer><AddCollectionForm /></Developer>  );
}
 
export default AddCollection;
