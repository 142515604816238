import { getPath } from '@evlop/commons';
import { Tab, Link } from '@evlop/web-components/dist/src/Elements';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { matchPath, useLocation } from 'react-router';
import TabMenu from '../../../components/Admin/TabMenu';

const menuItems = [
    { routeName: 'LIST_WEB_PAGES', label: 'Website' },
    { routeName: 'LIST_APP_PAGES', label: 'App (iOS/Android)' },
]

const Pages = ({children}) => {
    return (
    <div>
        <h1>Pages</h1>
            <TabMenu menuItems={menuItems} />
        {children}
    </div>);
}
 
export default Pages;