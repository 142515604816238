import React from "react";
import styled from 'styled-components';
const Input = styled.input({
    width: '100%',
    outline: 0,
    borderRadius: 0,
    border: '#e8e8e8 1px solid',
    padding: 9,
    transition: 'all 1s',
    ':focus':{
        borderColor: '#ccc'
    }
})

const InputField = ({field, placeholder, label, children, ...props})=>{
    return (
            <div>
                <label > {label} </label>
                <Input placeholder={placeholder} {...field} {...props} /> 
            </div>
        )
};

export default InputField;
