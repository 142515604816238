import React, { useCallback, useEffect, useMemo, useState } from "react";
import Editor from 'react-ace';

const JSONInputField = ({form, field, defaultValue = {}, placeholder, label, children, ...props})=>{
    const [value, setValue] = useState(JSON.stringify(defaultValue || {}, null, 2));
    useEffect(()=>{
        if(field.value && value !== field.value){
            setValue(JSON.stringify(field.value, null, 2))
        }
    }, [field.value]);

    const onChange = useCallback((value)=>{
        try{
            setValue(value);
            const jsonValue = JSON.parse(value);
            console.log(field.name, jsonValue);
            form.setFieldValue(field.name, jsonValue)
        } catch(e){
            console.log(e);
        }
    }, [field.name, form.setFieldValue, setValue]);
    return (
            <div>
                <label > {label} </label>
                <Editor 
                    placeholder={placeholder} 
                    showPrintMargin={false}
                    padding={10}
                    {...props}
                    value={value}
                    onChange={onChange} 
                    style={{width: '100%'}}
                    name={'json_editor'}
                     /> 
            </div>
        )
};

export default JSONInputField;
