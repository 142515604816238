import { generateFontSizes, generateLineHeights, getColorVariants } from "@evlop/commons";
import _, { get } from "lodash";
import React from "react";
import { Helmet } from 'react-helmet';
import { connect, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { ThemeProvider as NativeThemeProvider } from "styled-components/native";
import AppModals from "./AppModals";
import AppRoutes from "./AppRoutes";
import defaultTheme from "./theme";

const Home = (props)=>{
    const {
        config: {data:{theme: siteTheme } = {}} = {},
        themeForm:{ values: themeTemporary } = {},
    } = props;

    const {activeFonts} = useSelector(s=>s.fonts);

    const theme = React.useMemo(()=>{
        const newTheme = {...defaultTheme};

        const usedFonts = [...activeFonts];

        if(siteTheme) Object.assign(newTheme, siteTheme);
        if(themeTemporary) Object.assign(newTheme, themeTemporary);

        const {primaryColor, secondaryColor, infoColor, successColor, warningColor, dangerColor, whiteColor, blackColor, transparentColor} = newTheme;

        const themeColors = {
            primary: primaryColor,
            secondary: secondaryColor,
            info: infoColor,
            success: successColor,
            warning: warningColor,
            danger: dangerColor,
            gray: "#696969",
            white: whiteColor,
            black: blackColor,
            transparent: transparentColor,
        };

        const colorsProxy = getColorVariants(themeColors);

        Object.assign(newTheme, {
            baseColors: themeColors,
            colors: colorsProxy,
            fontSizes: generateFontSizes(newTheme.fontSize || 16),
            lineHeights: generateLineHeights(newTheme.fontSize || 16),
            activeFonts: usedFonts,
        });

        return newTheme;

    }, [siteTheme, themeTemporary, activeFonts]);


    return (
            <ThemeProvider theme={theme}>
                <NativeThemeProvider theme={theme}>
                    <>
                        <Helmet>
                            <link rel="stylesheet" type="text/css" href={get(theme, 'font.url')} />
                            <link rel="stylesheet" type="text/css" href={get(theme, 'headingFont.url')} />
                            <link rel="stylesheet" type="text/css" href={get(theme, 'buttonFont.url')} />
                            {theme.activeFonts.map(font=><link rel="stylesheet" key={font.url} type="text/css" href={get(font, 'url')} />)}
                        </Helmet>
                        <AppModals/>
                        <AppRoutes/>
                    </>
                </NativeThemeProvider>
            </ThemeProvider>
            );
};


const mapStateToProps = state => ({
    config: state.config,
    themeForm: state.form.theme,
});

export default connect(mapStateToProps)(Home);
