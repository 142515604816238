import React from 'react';
import Developer from '..';
import breadForm from "../../../../generators/breadForm";
import {RestApi} from "./index";

const defaultCode = `/**
* To import model for collection
* https://docs.createwebsite.io/importing
* const { Product } = require('@model')
*/
async function api(req, res, next){
  /**
   * TO-DO: Write the function implementations here
   * get current user calling this api, returns null if user is not logged in
   * const user = await req.getUser();
   */
  res.send({message: "This api works"});
}

module.exports=api;
`

const AddRestApiForm = breadForm(RestApi, {
    code: defaultCode,
    method: 'get'
});

const AddRestApi = () => {
    return ( <Developer><AddRestApiForm/></Developer> );
}
 
export default AddRestApi;
