import React from 'react';
import TabMenu from '../../../components/Admin/TabMenu';


const menuItems = [
    { routeName: 'USERS_ADMIN_LIST', label: 'Users' },
    { routeName: 'USER_ROLES_ADMIN_LIST', label: 'User Roles' },
]

const UserManagement = ({children}) => {
    return (
    <div>
        <h1>User management</h1>
        <TabMenu menuItems={menuItems} />
        {children}
    </div>);
}
 
export default UserManagement;