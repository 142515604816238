import React, {useCallback} from "react";
import {showModal} from "../../../redux/reducers/modals";
import ImageSelectorModal from "../../modals/ImageSelectorModal";
import defaultImage from "../../../assets/default-image.png";
import {useDispatch} from "react-redux";
import {elements} from '@evlop/web-components';


const ImageUpload = ({label, form: {setFieldValue}, field, field:{value, name, onChange} = {}}) => {

    const dispatch = useDispatch();

    const showImageChooser = useCallback(()=>{
        dispatch(showModal(ImageSelectorModal, {onSelect: v=> setFieldValue(name, v)}));
    }, [dispatch, setFieldValue, name]);

    return (
        <div style={{margin: 5}}>
            {!!label && (<>{label}<br/></>)}            
            <div>
                <div style={{ display: 'flex', flexDirection: 'row', border: '1px solid #ccc', alignItems: 'center', backgroundColor: '#e8e8e8' }}>
                    <elements.Image resizeMode="contain" size="xxs" aspectRatio={1}  src={value || defaultImage }/>
                    <input style={{flex: 1, border: 0, outline: 'none', flexBasis: '100%', height: 40, padding: 5}} {...field} />
                    <button type="button" onClick={showImageChooser} style={{padding: 5, cursor: 'pointer', border: 'none', height: 40, width: 40, backgroundColor: 'transparent'}}>
                        <elements.Icon size="xxs" className="lni lni-cloud-upload"></elements.Icon>
                    </button>
                </div>
                
            </div>
        </div>
    )
};

export default ImageUpload;
