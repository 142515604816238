import { getPath } from '@evlop/commons';
import { Button, Tab } from '@evlop/web-components/dist/src/Elements';
import React from 'react';

import TabMenu from '../../../components/Admin/TabMenu';

const menuItems = [
    { routeName: 'DOMAIN_ADMIN_LIST', label: 'Manage domain name' },
    { routeName: 'APP_BUILD_ADMIN_LIST', label: 'Manage app builds' },
]

const Publishing = ({children}) => {
    return (
    <div>
        <h1>Publishing</h1>
        <TabMenu menuItems={menuItems} />
        {children}
    </div>);
}
 
export default Publishing;