import React, {useMemo} from "react";
import SelectField from "./SelectField";
import { useTheme, fontVariations } from '@evlop/commons';

const FontSizeSelectField = (props)=>{
    const {fontSize = 16} = useTheme();

    const options = useMemo(()=>{
        return fontVariations.map((variation, index)=>({
            label: `${Math.round(fontSize * variation.scale)}px (${variation.scale.toFixed(1)}rem)`,
            value: variation.name,
        }))
    }, [fontSize]);

    const {field: { value }} = props;

    return (
    <SelectField
        isClearable
        {...props}
        options={options}
        selected={value}
    />)
};

export default FontSizeSelectField;
