import { useAuth } from "@evlop/commons";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import { withProps } from "recompose";
import FloatingToolbar from "../../components/Page/FloatingToolbar";
import ComponentPreview from "../ComponentPreview";
import AuthToken from "./AuthToken";
import PageLoader from "./pageLoader";
import withPlatform from "./pageLoader/withPlatform";

const Frontend = () => {

    const [isEditing, setIsEditing] = useState(false);
    const siteRoutes = useSelector(s=>s.routes.site);
    const pages = useSelector(s=>s.pages.records.data);
    const user = useAuth();

    const pageComponents = React.useMemo(()=>{
        const newPageComponents = {};
        for(const page of pages){
            if(!newPageComponents[page.id]) {
                newPageComponents[page.id] = withPlatform(page.platform)(withProps({pageId: page.id})(PageLoader));
            }
        }
        return newPageComponents;
    }, [pages]);

    const onWindowMessage = useCallback(({data} = {})=>{
        const {type} = data;
        switch (type) {
            case 'enablePageEditing':
                setIsEditing(true);
                break;
            default:
        }
    }, [setIsEditing]);

    useEffect(()=>{
        window.addEventListener('message', onWindowMessage)
        return ()=> window.removeEventListener('message', onWindowMessage)
    }, [onWindowMessage]);

    const isAdmin = user.can('MANAGE_PAGES');

    return (
        <>
            <div className="frontend">
                <Switch>
                    {pages.map(page=><Route key={page.id} exact path={`${page.slug}`} component={pageComponents[page.id]}/>)}
                    {siteRoutes.map(({id, ...routeParams })=><Route key={id} {...routeParams}/>)}
                    <Route path="/auth_token" component={AuthToken}/>
                    <Route path="/component-preview/:componentId" component={ComponentPreview} />
                    {/*<Route key='ROUTE_404' path="**" component={pageComponents["404PAGE"]} />*/}
                </Switch>
            </div>
            { isAdmin && !isEditing && <FloatingToolbar/>}
        </>
    );
};

export default Frontend;
