import React, { useMemo } from 'react';
import {connect} from "react-redux";
import {openMenuEditModal} from "../../../redux/reducers/editor";
import SelectField from "./SelectField";


const PageSelectField = ({pages:{collection:pages = {}} = {}, platform, ...props})=>{

    const options = useMemo(()=>{
        let pageOptions = Object.values(pages);
        if(platform){
            pageOptions = pageOptions.filter(p=> p.platform === platform)
        }
        pageOptions = pageOptions.map(p=>({label: p.name, value: p.id}))
        return pageOptions;
    }, [pages, platform])

    return (<SelectField {...props} options={options}/>)
}


const mapStateToProps = state=>({
    pages: state.pages
});

const mapDispatchToProps = dispatch=>({
    editMenu: id => dispatch(openMenuEditModal(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageSelectField);
