export const themeFields= [
    {
        type: 'panel',
        label: 'Font Styles',
        children:[
            {
                type: 'font',
                name: 'font',
                label: 'Default Font',
                isClearable: false,
            },
            {
                type: 'font',
                name: 'buttonFont',
                label: 'Button/Link Font',
                isClearable: false,
            },
            {
                type: 'slider',
                name: 'fontSize',
                min: 10,
                max: 25,
                step: 1,
                label: 'Base Font Size ',
            },
        ]
    },
    {
        type: 'panel',
        label: 'Theme Colors',
        children:[
            {
                type: 'raw-color',
                name: 'primaryColor',
                label: 'Primary Color'
            },
            {
                type: 'raw-color',
                name: 'secondaryColor',
                label: 'Secondary Color'
            },
            {
                type: 'raw-color',
                name: 'infoColor',
                label: 'Info Color'
            },
            {
                type: 'raw-color',
                name: 'successColor',
                label: 'Success Color'
            },
            {
                type: 'raw-color',
                name: 'dangerColor',
                label: 'Danger Color'
            },
            {
                type: 'raw-color',
                name: 'warningColor',
                label: 'Warning Color'
            },
            {
                type: 'raw-color',
                name: 'blackColor',
                label: 'Dark Color'
            },
            {
                type: 'raw-color',
                name: 'whiteColor',
                label: 'Light Color'
            },
        ]
    }
];
