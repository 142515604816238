import { getSignedUrl } from "@evlop/commons";
import Axios from "axios";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Editor from "rich-markdown-editor";


const RichTextField = ({ form:{setFieldValue}, field: {name, value = ''}, label, hint })=>{
    const lastValueFromEditor = useRef('');

    // editor itself maintains the changes value, so this state is used only when we need to override
    // this editor value might not be same as "lastValueFromEditor" and "value"
    const [editorValue, setEditorValue] = useState(value);

    const dispatch = useDispatch();
    const onChange = useCallback(debounce((content)=>{
        const newValue = content().split("\n").filter(l=>l!=='\\').join('\n');
        console.log(newValue)
        lastValueFromEditor.current =newValue;
        setFieldValue(name, newValue)
    }, 200), [setFieldValue, name]);

    const uploadImage = useCallback((file)=>{
        return dispatch(getSignedUrl({name: file.name, type: file.type})).then(({response: {url, signedUrl}}) => {
            const options = {
                headers: {
                    'Content-Type': file.type,
                    'x-amz-acl': 'public-read'
                }
            };
            return Axios.put(signedUrl, file, options).then(res => {
                return url;
            });
        });
    }, [dispatch]);

    useEffect(()=>{
        if(value && lastValueFromEditor.current === value) return;
        setEditorValue(value);
    }, [value]);

    return (
    <div>
    <label>{label}</label>
        <Editor
            onChange={onChange}
            value={editorValue}
            uploadImage={uploadImage}
        />
        <small>{hint}</small>
</div>)};

export default RichTextField;
