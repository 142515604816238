import React, {useMemo} from "react";
import SelectField from "./SelectField";
import { useFonts } from '@evlop/commons';

const FontSelectField = (props)=>{
    const {webFonts, appFonts, googleFonts} = useFonts();
    const fonts = useMemo(()=>  [...webFonts, ...googleFonts], [webFonts, googleFonts]);

    const {field: {value = {}, onChange}} = props;
    return (
    <SelectField
        isClearable
        {...props}
        options={fonts}
        useFullValue
        selected={value}
        input = {{value, onChange}}
    />)
};

export default FontSelectField;
