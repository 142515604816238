import {
    addAdminMenuItem,
    addComponentToList,
    registerComponent,
    registerDataSource,
    registerFormField,
    registerModel, 
    registerStyleComponent,
    withCollection,
    withCollectionRecord,
} from "@evlop/commons";
import { constantCase } from "change-case";
import pluralize from "pluralize";
import breadBackendListView from "./generators/breadBackendListView";
import breadForm from "./generators/breadForm";
import { getPath, registerAdminPage, registerPageType } from "./redux/reducers/routes";
import { combineReducers } from "redux";

const injectReducer = store => (name, reducer)=>{
    if(!store.activeReducers) {
        console.log('cannot inject reducers, as it does not contain existing reducers')
        return;
    }
    store.activeReducers = {...store.activeReducers, [name]:reducer};
    store.replaceReducer(combineReducers(store.activeReducers))
};

const registerModule = (store) => (packageContents)=>{
    const name = 'default';
    const version = 'master';
    const {dispatch} = store;
    const {
        reducers = [],
        components = [],
        appComponents = [],
        initializers = [],
        boot = initializers,
        models = [],
        formFields = [],
        styles = [],
        pageTypes = [],
        adminPages = [],
        dataSources = [],
    } = packageContents;
    
        reducers.forEach((reducerModule)=>{
            const {name, key=name, reducer = reducerModule} = reducerModule;
            injectReducer(store)(key, reducer);
        });

        components.forEach((component)=>{
            if(component instanceof Function || component.PreviewComponent) {
                component.platform = 'WEB';
                dispatch(registerComponent(component));
            }

            dispatch(addComponentToList({
                package: name,
                platform: 'WEB',
                ...component
            }));
        });

        appComponents.forEach((component)=>{
            dispatch(addComponentToList({
                package: name,
                platform: 'APP',
                ...component,
            }));
        });


        formFields.forEach(formFiled=>{
            dispatch(registerFormField({
                selector: formFiled.selector,
                component: formFiled
            }));
        });

        styles.forEach(style=>{
            dispatch(registerStyleComponent({
                name: style.name,
                description: style.description,
                component: style
            }));
        });

        pageTypes.forEach(pageType=>dispatch(registerPageType(pageType)));

        adminPages.forEach(page=>dispatch(registerAdminPage(page.routeId, {
            path: page.path,
            component: page,
        })));

        boot.forEach(( booter)=>booter(store.dispatch, store));

        models.forEach(model=>{
            dispatch(registerModel(model));
            const { hasCMS = true, isAccessibeleFromFrontend=true, collectionName='', singularName='', pluralName = pluralize(singularName)} = model;
            if(collectionName && hasCMS) {
                const routeName = constantCase(pluralName);
                const routeNameSingular = constantCase(singularName);
                store.dispatch(registerAdminPage(`${routeName}_ADMIN_LIST`, {
                    path: `/${collectionName}`,
                    component: breadBackendListView(model)
                }));
                store.dispatch(registerAdminPage(`${routeNameSingular}_ADD`, {
                    path: `/${collectionName}/add`,
                    component: breadForm(model)
                }));

                store.dispatch(registerAdminPage(`${routeNameSingular}_EDIT`, {
                    path: `/${collectionName}/:id/edit`,
                    component: breadForm(model)
                }));

                store.dispatch(addAdminMenuItem(`${routeName}_MENU`, {
                    name: pluralName,
                    path: ()=>store.dispatch(getPath(`${routeName}_ADMIN_LIST`)),
                    permission: 'MANAGE_COLLECTIONS'
                }));
            }

            if(isAccessibeleFromFrontend){
                store.dispatch(registerPageType(withCollection(collectionName)));
                store.dispatch(registerPageType(withCollectionRecord(collectionName)));
            }
        });

        dataSources.forEach(dataSource => {
            store.dispatch(registerDataSource(dataSource));
        });

};

export default registerModule;