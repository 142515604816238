import {PUT} from "@mukti107/redux-rest";
import {VIEW_PAGE} from "./routes";

export const MODE_VIEW_PAGE = "mode/PAGE_VIEW";
export const MODE_EDIT_PAGE = "mode/PAGE_EDIT";

export const ADD_BLOCK = 'ADD_PAGE_BLOCK';
export const UPDATE_BLOCK = 'UPDATE_BLOCK';
export const UPDATE_BLOCK_DATA = 'UPDATE_BLOCK_DATA';
export const MOVE_BLOCK = 'MOVE_PAGE_BLOCK';
export const REMOVE_BLOCK = 'REMOVE_PAGE_BLOCK';
export const SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE';
export const SET_ACTIVE_PAGE_ID = 'SET_ACTIVE_PAGE_ID';

export const SAVE_PAGE_REQUEST = 'SAVE_PAGE_REQUEST';
export const SAVE_PAGE_SUCCESS = 'SAVE_PAGE_SUCCESS';
export const SAVE_PAGE_FAILURE = 'SAVE_PAGE_FAILURE';

export function addBlockToPage(block, index=-1){
    return {
        type: ADD_BLOCK,
        block,
        index,
    };
}

export function updateBlock(uuid, data){
    return {
        type: UPDATE_BLOCK,
        uuid,
        data,
    };
}

export function updateBlockData(uuid, data){
    return {
        type: UPDATE_BLOCK_DATA,
        uuid,
        data,
    };
}

export function removeBlockFromPage(uuid){
    return {
        type: REMOVE_BLOCK,
        uuid,
    };
}

export function moveBlock(sourceIndex, destinationIndex){
    return {
        type: MOVE_BLOCK,
        sourceIndex,
        destinationIndex
    };
}

export function setActivePageId(id){
    return {
        type: SET_ACTIVE_PAGE_ID,
        id
    }
}

export function setActivePage(page){
    return {
        type: SET_ACTIVE_PAGE,
        page,
    };
}

export function savePage(id, data){
    return {
        endpoint: 'pages/:id',
        types:[SAVE_PAGE_REQUEST, SAVE_PAGE_SUCCESS, SAVE_PAGE_FAILURE],
        pathParams: {id},
        method: PUT,
        isRestRequest: true,
        data
    };
}

let initialState = {
    id: '',
    mode: VIEW_PAGE,
    blocks: []
};

export default (state = initialState, action)=>{
    switch (action.type) {
        case ADD_BLOCK: {
            const blocks = [...state.blocks];
            if(action.index >=0 ){
                blocks.splice(action.index, 0, action.block);
            }else{
                blocks.push(action.block);
            }
            return {
                ...state,
                blocks
            };
        }
        case REMOVE_BLOCK:
            return{
                ...state,
                blocks: state.blocks.filter(b=>b.uuid!==action.uuid)
            };
        case MOVE_BLOCK: {
            const {sourceIndex, destinationIndex} = action;
            const blocks = [...state.blocks];
            const item = blocks.splice(sourceIndex, 1)[0];
            blocks.splice(sourceIndex>destinationIndex? destinationIndex: destinationIndex - 1, 0, item);
            return {
                ...state,
                blocks
            };
        }
        case UPDATE_BLOCK:
            return{
                ...state,
                    blocks: state.blocks.map(block=>block.uuid === action.uuid?{...block, ...action.data}: block)
            };
        case UPDATE_BLOCK_DATA:
            return{
                ...state,
                blocks: state.blocks.map(block=>block.uuid === action.uuid?{...block, data: {...block.data}}: block)
            };
        case SET_ACTIVE_PAGE: {
                return {
                    ...action.page,
                };
            }
        case SET_ACTIVE_PAGE_ID:
            return{
                ...state,
                id: action.id
            };
        default:
            return state;
    }

}
