import { get } from "lodash";
import React, { useCallback } from "react";
import Select from 'react-select';

const SelectField = ({field:{name, value}, onChange, form: {setFieldValue} = {}, multiple = false, useFullValue = false, label, hint, options=[], ...props})=> {
    let selection = value;
    if(typeof value === "string"){
        selection = options.find(o=> o.value === value);
    } else if(Array.isArray(value) && !useFullValue){
        selection = value.map(v=> options.find(o=> o.value === v))
    }

    const onChangeSelection = useCallback((selectedOption) => {
        if(multiple && !useFullValue){
            const newValue = selectedOption.map(item=> item.value );

            if(onChange) onChange(newValue);
            return setFieldValue(name, newValue);
        }

        if(onChange) onChange(useFullValue? selectedOption : get(selectedOption, 'value'))
        setFieldValue( name, useFullValue? selectedOption : get(selectedOption, 'value'))
        
    }, [multiple, name, onChange, setFieldValue, useFullValue]);

    return (
        <div>
            <label>{label}</label>
            <Select {...props} label={label} isSearchable options={options} value={selection} isMulti={multiple} onChange={onChangeSelection} />
        </div>
    );
};

export default SelectField;
