import React, {useCallback, useEffect, useState} from "react";
import GridItem from "../Grid/GridItem";
import styled from 'styled-components';
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {getPath, VIEW_PAGE} from "../../../redux/reducers/routes";
import {pagesCollection} from "../../../redux/reducers";
import {elements} from '@evlop/web-components';
const { Button, Link, Icon } = elements;

const PageListItemContainer = styled.div({
    position: 'absolute',
    padding: 5,
    left:0,
    right: 0,
    bottom: 0,
});

const PageListItemActions = styled.div({
    opacity: 0,
    position: 'absolute',
    padding: 5,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    transition: 'opacity 0.25s'
});

const PageListItemWrapper = styled(GridItem)({
    position: 'relative',
    width: '100%',
    height: '100%',
    paddingTop: '60%',
    backgroundColor: '#e8e8e8',
    [`&:hover ${PageListItemActions}`]:{
        opacity: 1,
    }
});

const PageListItem = ({id, slug, name, platform='WEB'}) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const [path, setPath] = useState({});

    useEffect(()=>{
            setPath({
                edit: dispatch(getPath(`EDIT_${platform}_PAGE`, {id})),
                view: dispatch(getPath(VIEW_PAGE, {id, slug})),
            });
        }, [platform, id, dispatch]);

    const deletePage = useCallback(()=>{
        const sure = window.confirm('This page will be permanently deleted. You won\'t be able to undo delete operation.');
        if(sure){
            dispatch(pagesCollection.delete(id));
        }
    }, [id, dispatch]);

    const editLayout = useCallback(()=>{
        const hash = btoa(window.location.origin+path.view);
        history.push('/editor/'+hash)
    }, [path]);

    return (
        <PageListItemWrapper>
            <PageListItemContainer>
                {name}
            </PageListItemContainer>
            <PageListItemActions>
                <Link linkStyle="button" size="xxs" to={path.view}><Icon size="xxs" color="white" className="lni lni-eye" /></Link>
                <Link linkStyle="button" size="xxs" to={path.edit}><Icon size="xxs" color="white" className="lni lni-pencil" /></Link>
                <Button sharp size="xxs" onClick={editLayout}><Icon size="xxs" color="white" className="lni lni-layout" /></Button>
                <Button sharp size="xxs" onClick={deletePage}><Icon size="xxs" color="white" className="lni lni-trash" /></Button>
            </PageListItemActions>
        </PageListItemWrapper>
    );
};

export default PageListItem;
