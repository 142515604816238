import { DataProvider, useParams, DataSource, PageProvider, ToolbarProvider, updateContext, useUser, useScope } from "@evlop/commons";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { submit } from "redux-form";
import styled from "styled-components";
import { savePage } from "../../redux/reducers/page";
import PageHeader from "./PageHeader";
import PageLayout from "./PageLayout";
import ThemeChangesListner from "./ThemeChangesListner";
import _, { reduce } from 'lodash';

const PageContainer = styled.div(({platform})=> (platform === 'AP') && {
    maxWidth: 400,
    margin: 'auto',
    borderRadius: 5,
    marginTop: 5,
    border: '5px solid #e8e8e8',
    height: 'calc(100vh - 60px)',
    overflowY: 'scroll'
});

const Page = props => {
    const {showPageId} = props;
    const dispatch = useDispatch();
    const history=useHistory();
    const params = useParams();
    const components = useSelector(s=>s.components.components);
    const context = useSelector(s=>s.context);
    const parentScope = useScope();
    const pages = useSelector(s=>s.pages);
    const [isEditing, setEditable] = useState(false);
    const [pageUpdated, setPageUpdated] = useState({});

    const config = useSelector(s=>s.config.data);
    const user = useUser();

    const page = useMemo(()=>{
        if(showPageId) {
            const page = pages.records.data.find(p=> p.id === showPageId)
            if(page) return {...page, ...pageUpdated};
        }
        return {};
    }, [showPageId, pages, pageUpdated]);

    useEffect(()=>{
        if(page){
            window.top.postMessage({type: 'pageLoaded', page}, '*');
        }
    }, [page]);

    const { blocks = [], dataSources} = page;

    const setBlocks = useCallback(blocks=>{
        setPageUpdated(page=>({...page, blocks}));
    }, []);


    const saveThisPage = React.useCallback(()=>{
        window.top.postMessage({type: 'savePageStarted'}, '*');
        Promise.all([dispatch(savePage(showPageId, pageUpdated)), dispatch(submit('theme'))]).then(()=>{
            window.top.postMessage({type: 'savePageCompleted'}, '*');
        }).catch(()=>{
            window.top.postMessage({type: 'savePageFailed'}, '*');
        });
    }, [pageUpdated, showPageId]);

    const editPage = React.useCallback(()=>{
        const url = btoa(window.location.pathname+window.location.search);
        history.push(`/editor/${url}`);
    }, [showPageId]);

    useEffect(()=>{
        let adminActions = [];
        if(!isEditing){
            adminActions.push({
                action: editPage,
                icon: 'lni lni-pencil-alt',
                label: 'Edit This Page'
            })
        }
        dispatch(updateContext('page', {
            id: showPageId,
            adminActions
        }));

    }, [saveThisPage, isEditing, editPage]);

    const pageData = useMemo(()=>{
        return {...page,isEditing,};
    }, [page, isEditing]);

    const contextData = useMemo(()=>{
        return {
            ...context,
            ...parentScope,
            page: _.omit(page, 'blocks'),
            config,
            user,
            params,
        };
    }, [ page, params, config, user, context, parentScope]);

    const toolbarContext = useMemo(()=>{
        return {stack:[]};
    }, []);

    const onWindowMessage = useCallback(({data} = {})=>{
        const {type, data: messageData} = data;
        switch (type) {
            case 'savePage':
                saveThisPage();
            break;
            case 'getPageDataSources':
                const dataSources = _.get(page, 'dataSources', []);
                window.top.postMessage({type: 'setPageDataSources', data: dataSources}, '*');
                break;
            case 'pageDataSourcesUpdated':
                console.log('datasourceUpdated', messageData);
                setPageUpdated(p=>({...p, dataSources: messageData}))
            break;
            case 'enablePageEditing':
                setEditable(true);
            break;
            default:
        }

    }, [saveThisPage, page]);

    useEffect(()=>{
        window.addEventListener('message', onWindowMessage)
        return ()=> window.removeEventListener('message', onWindowMessage)
    }, [onWindowMessage]);

    const pageTypes = useSelector(s=>s.routes.pageTypes);

    const pageTypeComponents = useMemo(()=>{
        return pageTypes.filter(p=> p instanceof Function && p.routeName === page.routeName);
    }, [pageTypes, page.routeName]);


    const pageContentRendere = (
        <PageContainer platform={page.platform}>
            <PageHeader/>
            <PageLayout onChange={setBlocks} editable={isEditing} components={components} blocks={blocks}/>
        </PageContainer>
    );

    const contentWithDataSources = reduce(dataSources, (prev,{name, dataSource})=><DataSource name={name} dataSource={dataSource} children={prev} />, pageContentRendere);

    // wrap page render inside page type component
    const pageRenderWithPageTypes = pageTypeComponents.reduce((children, PageTypeComponent, i)=>(
        <PageTypeComponent>{children}</PageTypeComponent>
    ), contentWithDataSources);

    return (
        <PageProvider value={pageData}>
            <DataProvider value={contextData}>
                <ToolbarProvider value={toolbarContext}>
                    {pageRenderWithPageTypes}
                    {isEditing && <ThemeChangesListner  />}
                </ToolbarProvider>
            </DataProvider>
        </PageProvider>
        );
};

export default Page;
