import React from "react";
import {connect} from "react-redux";
import SelectField from "./SelectField";


const PageTypeSelectField = ({pageTypes = [], ...props})=>(
    <SelectField isClearable {...props} options={pageTypes.map(({pageName, path, routeName})=>({label: pageName, path, routeName, value:routeName}))}/>
);


const mapStateToProps = state=>({
    pageTypes: state.routes.pageTypes
});

export default connect(mapStateToProps)(PageTypeSelectField);
