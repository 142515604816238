import { Model } from "@evlop/commons";
import breadBackendListView from "../../../generators/breadBackendListView";


export const schema = {
    name:{label: 'Name'},
    version:{label: 'Version'},
};

export const options = {
    modelName: 'plugin',
    listPageRouteName : 'PLUGINS',
    detailsPageRouteName : 'PLUGINS_EDIT',
    singularName: 'Plugin',
    collectionName: 'plugins',
    schema,
};

export class Plugin extends Model{}
Plugin.config(schema, options);

const Plugins = breadBackendListView(Plugin);

export default Plugins;
