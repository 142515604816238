import { addSettingsField, dataSources, registerAdminPage, registerAppFonts, registerDataSource, registerFormField, registerIcon, registerPageType, registerSitePage, registerWebFonts } from '@evlop/commons';
import { batch } from 'react-redux';
import AppActionSelectField from './components/Admin/FormFields/AppActionSelectField';
import CheckboxField from "./components/Admin/FormFields/CheckboxField";
import CodeEditorField from './components/Admin/FormFields/CodeEditorField';
import ColorPickerField from "./components/Admin/FormFields/ColorPickerField";
import ComponentSelectField from './components/Admin/FormFields/ComponentSelectField';
import ConnectionTypeSelectField from './components/Admin/FormFields/ConnectionTypeSelectField';
import DataConnectionSelectField from './components/Admin/FormFields/DataConnectionSelectField';
import DataSourceSelectField from './components/Admin/FormFields/DataSourceSelectField';
import FontSelectField from "./components/Admin/FormFields/FontSelectField";
import FontSizeSelectField from "./components/Admin/FormFields/FontSizeSelectField";
import IconSelectField from "./components/Admin/FormFields/IconSelectField";
import ImageUpload from "./components/Admin/FormFields/ImageUpload";
import InputField from "./components/Admin/FormFields/InputField";
import JSONInputField from './components/Admin/FormFields/JSONInputField';
import MenuSelectField from "./components/Admin/FormFields/MenuSelectField";
import PageParamsField from './components/Admin/FormFields/PageParamsField';
import PageSelectField from "./components/Admin/FormFields/PageSelectField";
import PageTypeSelectField from "./components/Admin/FormFields/PageTypeSelectField";
import RecordSelectField from "./components/Admin/FormFields/RecordSelectField";
import RichTextField from "./components/Admin/FormFields/RichTextField";
import SelectField from "./components/Admin/FormFields/SelectField";
import SliderField from "./components/Admin/FormFields/SliderField";
import TagsField from "./components/Admin/FormFields/TagsField";
import TextareaField from "./components/Admin/FormFields/TextareaField";
import ThemeColorPicker from "./components/Admin/FormFields/ThemeColorPickerField";
import WebActionSelectField from './components/Admin/FormFields/WebActionSelectField';
import AdminDashboard from "./pages/Admin/Dashboard";
import Menus from "./pages/Admin/menus";
import AddMenu from "./pages/Admin/menus/AddMenu";
import AppPages from "./pages/Admin/pages/app";
import AddAppPage from "./pages/Admin/pages/app/AddAppPage";
import WebPages from "./pages/Admin/pages/web";
import AddWebPage from "./pages/Admin/pages/web/AddWebPage";
import Plugins from "./pages/Admin/plugins";
import AddPlugin from "./pages/Admin/plugins/AddPlugin";
import AppBuilds from './pages/Admin/publishing/app-builds';
import AddAppBuild from './pages/Admin/publishing/app-builds/AddAppBuild';
import DomainsListView from "./pages/Admin/publishing/domains";
import AddDomains from "./pages/Admin/publishing/domains/AddDomain";
import Collections from './pages/Admin/resources/collections';
import AddCollection from './pages/Admin/resources/collections/AddCollection';
import EditCollection from './pages/Admin/resources/collections/EditCollection';
import RestApis from './pages/Admin/resources/rest-apis';
import AddRestApi from './pages/Admin/resources/rest-apis/AddRestApi';
import ScheduledTasks from './pages/Admin/resources/scheduled-tasks';
import AddScheduledTask from './pages/Admin/resources/scheduled-tasks/AddScheduledTask';
import Workers from './pages/Admin/resources/workers';
import AddWorker from './pages/Admin/resources/workers/AddWorker';
import Settings from "./pages/Admin/settings";
import UserRoles from './pages/Admin/users/user-roles';
import AddUserRole from './pages/Admin/users/user-roles/AddUserRole';
import Users from './pages/Admin/users/users';
import AddUser from './pages/Admin/users/users/AddUser';
import AdminLogin from "./pages/Login";


const sitePages = [
        {
            id: "LOGIN",
            exact:true,
            path:"/login",
            component: AdminLogin
        },
        {
            id: "LOGOUT",
            exact:true,
            path:"/login",
            component: AdminLogin
        },
    ];
const adminPages = [
    {
        id: "ADMIN_DASHBOARD",
        exact:true,
        path:"/",
        component: AdminDashboard
    },
    {
        id: "DOMAIN_ADMIN_LIST",
        exact:true,
        path:"/domains",
        component:DomainsListView
    },
    {
        id: "DOMAIN_ADD",
        exact:true,
        path:"/domains/add",
        component:AddDomains,
    },
    {
        id: "DOMAIN_EDIT",
        exact:true,
        path:"/domains/:id/edit",
        component:AddDomains
    },
    {
        id: "WORKERS_ADMIN_LIST",
        exact:true,
        path:"/resources/workers",
        component:Workers,
    },
    {
        id: "WORKER_ADD",
        exact:true,
        path:"/resources/workers/add",
        component:AddWorker
    },
    {
        id: "WORKER_EDIT",
        exact:true,
        path:"/resources/workers/:id/edit",
        component:AddWorker
    },
    {id: "REST_APIS_ADMIN_LIST",exact:true,path:"/resources/rest-apis",component:RestApis,},
    {id: "REST_API_ADD",exact:true,path:"/resources/rest-apis/add",component:AddRestApi},
    {id: "REST_API_EDIT",exact:true,path:"/resources/rest-apis/:id/edit",component:AddRestApi},
    
    {id: "SCHEDULED_TASKS_ADMIN_LIST",exact:true,path:"/resources/scheduled-tasks",component:ScheduledTasks,},
    {id: "SCHEDULED_TASK_ADD",exact:true,path:"/resources/scheduled-tasks/add",component:AddScheduledTask},
    {id: "SCHEDULED_TASK_EDIT",exact:true,path:"/resources/scheduled-tasks/:id/edit",component:AddScheduledTask},

    {id: "COLLECTIONS_ADMIN_LIST",exact:true,path:"/resources/collections", component:Collections,},
    {id: "COLLECTION_ADD",exact:true,path:"/resources/collections/add", component:AddCollection},
    {id: "COLLECTION_EDIT",exact:true,path:"/resources/collections/:id/edit", component:EditCollection},
    {
        id: "LIST_WEB_PAGES",
        exact:true,
        path:"/pages/web",
        component:WebPages
    },
    {
        id: "ADD_WEB_PAGE",
        exact:true,
        path:"/pages/web/add",
        component:AddWebPage
    },
    {
        id: "EDIT_WEB_PAGE",
        exact:true,
        path:"/pages/web/:id/edit",
        component:AddWebPage
    },
    {
        id: "LIST_APP_PAGES",
        exact:true,
        path:"/pages/app",
        component:AppPages
    },
    {
        id: "ADD_APP_PAGE",
        exact:true,
        path:"/pages/app/add",
        component:AddAppPage
    },
    {
        id: "EDIT_APP_PAGE",
        exact:true,
        path:"/pages/app/:id/edit",
        component:AddAppPage
    },
    {
        id: "MENUS_ADMIN_LIST",
        exact:true,
        path:"/menus",
        component:Menus
    },
    {
        id: "MENU_ADD",
        exact:true,
        path:"/menus/add",
        component:AddMenu
    },
    {
        id: "MENU_EDIT",
        exact:true,
        path:"/menus/:id/edit",
        component:AddMenu
    },
    {
        id: "PLUGINS_ADMIN_LIST",
        exact:true,
        path:"/plugins",
        component:Plugins
    },
    {
        id: "PLUGIN_ADD",
        exact:true,
        path:"/plugins/add",
        component:AddPlugin
    },
    {
        id: "PLUGIN_EDIT",
        exact:true,
        path:"/plugins/:id/edit",
        component:AddPlugin
    },
    {
        id: "USERS_ADMIN_LIST",
        exact:true,
        path:"/user-management/users",
        component:Users
    },
    {
        id: "USER_EDIT",
        exact:true,
        path:"/user-management/users/:id/edit",
        component:AddUser,
    },
    {
        id: "USER_ROLES_ADMIN_LIST",
        exact:true,
        path:"/user-management/user-roles",
        component:UserRoles
    },
    {
        id: "USER_ROLE_EDIT",
        exact:true,
        path:"/user-management/user-roles/:id/edit",
        component:AddUserRole,
    },
    {
        id: "USER_ROLE_ADD",
        exact:true,
        path:"/user-management/user-roles/add",
        component:AddUserRole,
    },
    {
        id: "APP_BUILD_ADMIN_LIST",
        exact:true,
        path:"/app-builds",
        component:AppBuilds
    },
    {
        id: "APP_BUILD_ADD",
        exact:true,
        path:"/app-builds/add",
        component:AddAppBuild,
    },
    {
        id: "ADMIN_CONFIGURATION",
        exact:true,
        path:"/config/:configName",
        component:Settings
    },
];
const pageTypes = [];
const webFonts = [
    {
        value: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
        label: "System"
    },
    {
        value: "Georgia, serif",
        label: "Georgia, serif"
    },
    {
        value: `"Palatino Linotype", "Book Antiqua", Palatino, serif`,
        label: `"Palatino Linotype", "Book Antiqua", Palatino, serif`
    },
    {
        value: `"Times New Roman", Times, serif`,
        label: `"Times New Roman", Times, serif`
    },
    {
        value: `Arial, Helvetica, sans-serif`,
        label: `Arial, Helvetica, sans-serif`
    },
    {
        value: `"Arial Black", Gadget, sans-serif`,
        label: `"Arial Black", Gadget, sans-serif`
    },
    {
        value: `"Comic Sans MS", cursive, sans-serif`,
        label: `"Comic Sans MS", cursive, sans-serif`
    },
    {
        value: `Impact, Charcoal, sans-serif`,
        label: `Impact, Charcoal, sans-serif`
    },
    {
        value: `"Lucida Sans Unicode", "Lucida Grande", sans-serif`,
        label: `"Lucida Sans Unicode", "Lucida Grande", sans-serif`
    },
    {
        value: `Tahoma, Geneva, sans-serif`,
        label: `Tahoma, Geneva, sans-serif`
    },
    {
        value: `"Trebuchet MS", Helvetica, sans-serif`,
        label: `Verdana, Geneva, sans-serif`
    },
    {
        value: `Verdana, Geneva, sans-serif`,
        label: `Verdana, Geneva, sans-serif`
    },
    {
        value: `"Courier New", Courier, monospace`,
        label: `"Courier New", Courier, monospace`
    },
    {
        value: `"Lucida Console", Monaco, monospace`,
        label: `"Lucida Console", Monaco, monospace`
    }
];
const appFonts = [
    {
        value: "Arial",
        label: "Arial"
    },
    {
        value: "Roboto",
        label: "Robot"
    },{
        value: "Courier New",
        label: "Courier New"
    }
    ,{
        value: "Verdana",
        label: "Verdana"
    },
    {
        value: "Georgia",
        label: "Georgia"
    }
];


const formFields = [
    {
        selector:'select',
        component: SelectField,
    },
    {
        selector:['checkbox', 'boolean'],
        component: CheckboxField,
    },
    {
        selector:['slider'],
        component: SliderField,
    },
    {
        selector:'tags',
        component: TagsField,
    },
    {
        selector:['textarea', 'long-text'],
        component: TextareaField,
    },
    {
        selector:'rich-text',
        component: RichTextField,
    },
    {
        selector:['menu-selector', 'menu-select', 'menu'],
        component: MenuSelectField,
    },
    {
        selector:'font',
        component: FontSelectField,
    },
    {
        selector:['font-size', 'fontSize'],
        component: FontSizeSelectField,
    },
    {
        selector:'icon',
        component: IconSelectField,
    },
    {
        selector:['raw-color'],
        component: ColorPickerField,
    },
    {
        selector:['color', 'color-picker'],
        component: ThemeColorPicker,
    },
    {
        selector:['code', 'code-editor'],
        component: CodeEditorField,
    },
    {
        selector:['json'],
        component: JSONInputField,
    },
    {
        selector:'image',
        component: ImageUpload,
    },
    {
        selector:'page',
        component: PageSelectField,
    },
    {
        selector:'page-params',
        component: PageParamsField,
    },
    {
        selector:'component',
        component: ComponentSelectField,
    },
    {
        selector:'external-data-connection-type',
        component: ConnectionTypeSelectField,
    },
    {
        selector:'external-data-connection',
        component: DataConnectionSelectField,
    },
    {
        selector:'page-type',
        component: PageTypeSelectField,
    },
    {
        selector:'reference',
        component: RecordSelectField,
    },
    {
        selector: ['action', 'web-action'],
        component: WebActionSelectField
    },
    {
        selector: ['action', 'app-action'],
        component: AppActionSelectField,
    },
    {
        selector: ['data-source'],
        component: DataSourceSelectField,
    },
    {
        selector:['input', 'text', '', undefined],
        component: InputField,
    }
];

const settingsFields = [
    {
        name: 'title',
        label: 'Site Title',
        configName: 'web',
    },
    {
        name: 'subtitle',
        label: 'Site Tag line',
        configName: 'web',
    },
    {
        name: 'logo',
        type: 'image',
        label: 'Logo',
        configName: 'web',
    },
    {
        name: 'favicon',
        type: 'image',
        label: 'Favicon',
        configName: 'web',
    },
    {
        name: 'displayName',
        label: 'App Name',
        configName: 'app',
    },
    {
        configName: 'app',
        label: 'Drawer Component',
        name: 'drawerContent',
        type: 'component', 
        platform: 'APP',
    },
    {
        name: 'bundleIdentifier',
        label: 'Bundle Identifier',
        configName: 'app',
    },
    {
        name: 'version',
        label: 'Current Version',
        configName: 'app',
    },
    {
        name: 'icon',
        type: 'image',
        label: 'App Icon',
        configName: 'app',
    },
    {
        name: 'splashImage',
        type: 'image',
        label: 'Splash Screen Image',
        configName: 'app',
    },
    {
        configName: 'app',
        label: 'Deeplink Schema(s)', 
        name: 'deeplinkSchemas',
        type: 'tags', 
        placeholder: "eg. myapp://  https://myapp.com",
    }
];

const icons = [
    {label: 'bg-square', className: 'lni lni-bg-square', value:'bg-square'},
    {label: 'bg-circle', className: 'lni lni-bg-circle', value:'bg-circle'},
    {label: '500px', className: 'lni lni-500px', value:'500px'},
    {label: 'add-files', className: 'lni lni-add-files', value:'add-files'},
    {label: 'alarm-clock', className: 'lni lni-alarm-clock', value:'alarm-clock'},
    {label: 'alarm', className: 'lni lni-alarm', value:'alarm'},
    {label: 'airbnb', className: 'lni lni-airbnb', value:'airbnb'},
    {label: 'adobe', className: 'lni lni-adobe', value:'adobe'},
    {label: 'amazon-pay', className: 'lni lni-amazon-pay', value:'amazon-pay'},
    {label: 'amazon', className: 'lni lni-amazon', value:'amazon'},
    {label: 'amex', className: 'lni lni-amex', value:'amex'},
    {label: 'anchor', className: 'lni lni-anchor', value:'anchor'},
    {label: 'amazon-original', className: 'lni lni-amazon-original', value:'amazon-original'},
    {label: 'android-original', className: 'lni lni-android-original', value:'android-original'},
    {label: 'android', className: 'lni lni-android', value:'android'},
    {label: 'angellist', className: 'lni lni-angellist', value:'angellist'},
    {label: 'angle-double-down', className: 'lni lni-angle-double-down', value:'angle-double-down'},
    {label: 'angle-double-left', className: 'lni lni-angle-double-left', value:'angle-double-left'},
    {label: 'angle-double-right', className: 'lni lni-angle-double-right', value:'angle-double-right'},
    {label: 'angle-double-up', className: 'lni lni-angle-double-up', value:'angle-double-up'},
    {label: 'angular', className: 'lni lni-angular', value:'angular'},
    {label: 'apartment', className: 'lni lni-apartment', value:'apartment'},
    {label: 'app-store', className: 'lni lni-app-store', value:'app-store'},
    {label: 'apple-pay', className: 'lni lni-apple-pay', value:'apple-pay'},
    {label: 'apple', className: 'lni lni-apple', value:'apple'},
    {label: 'archive', className: 'lni lni-archive', value:'archive'},
    {label: 'arrow-down-circle', className: 'lni lni-arrow-down-circle', value:'arrow-down-circle'},
    {label: 'arrow-left-circle', className: 'lni lni-arrow-left-circle', value:'arrow-left-circle'},
    {label: 'arrow-left', className: 'lni lni-arrow-left', value:'arrow-left'},
    {label: 'arrow-right-circle', className: 'lni lni-arrow-right-circle', value:'arrow-right-circle'},
    {label: 'arrow-right', className: 'lni lni-arrow-right', value:'arrow-right'},
    {label: 'arrow-top-left', className: 'lni lni-arrow-top-left', value:'arrow-top-left'},
    {label: 'arrow-top-right', className: 'lni lni-arrow-top-right', value:'arrow-top-right'},
    {label: 'arrow-up-circle', className: 'lni lni-arrow-up-circle', value:'arrow-up-circle'},
    {label: 'arrow-up', className: 'lni lni-arrow-up', value:'arrow-up'},
    {label: 'arrows-horizontal', className: 'lni lni-arrows-horizontal', value:'arrows-horizontal'},
    {label: 'arrows-vertical', className: 'lni lni-arrows-vertical', value:'arrows-vertical'},
    {label: 'atlassian', className: 'lni lni-atlassian', value:'atlassian'},
    {label: 'aws', className: 'lni lni-aws', value:'aws'},
    {label: 'arrow-down', className: 'lni lni-arrow-down', value:'arrow-down'},
    {label: 'ambulance', className: 'lni lni-ambulance', value:'ambulance'},
    {label: 'agenda', className: 'lni lni-agenda', value:'agenda'},
    {label: 'backward', className: 'lni lni-backward', value:'backward'},
    {label: 'baloon', className: 'lni lni-baloon', value:'baloon'},
    {label: 'ban', className: 'lni lni-ban', value:'ban'},
    {label: 'bar-chart', className: 'lni lni-bar-chart', value:'bar-chart'},
    {label: 'behance-original', className: 'lni lni-behance-original', value:'behance-original'},
    {label: 'bitbucket', className: 'lni lni-bitbucket', value:'bitbucket'},
    {label: 'bitcoin', className: 'lni lni-bitcoin', value:'bitcoin'},
    {label: 'blackboard', className: 'lni lni-blackboard', value:'blackboard'},
    {label: 'blogger', className: 'lni lni-blogger', value:'blogger'},
    {label: 'bluetooth', className: 'lni lni-bluetooth', value:'bluetooth'},
    {label: 'bold', className: 'lni lni-bold', value:'bold'},
    {label: 'bolt-alt', className: 'lni lni-bolt-alt', value:'bolt-alt'},
    {label: 'bolt', className: 'lni lni-bolt', value:'bolt'},
    {label: 'book', className: 'lni lni-book', value:'book'},
    {label: 'bookmark-alt', className: 'lni lni-bookmark-alt', value:'bookmark-alt'},
    {label: 'bookmark', className: 'lni lni-bookmark', value:'bookmark'},
    {label: 'bootstrap', className: 'lni lni-bootstrap', value:'bootstrap'},
    {label: 'bricks', className: 'lni lni-bricks', value:'bricks'},
    {label: 'bridge', className: 'lni lni-bridge', value:'bridge'},
    {label: 'briefcase', className: 'lni lni-briefcase', value:'briefcase'},
    {label: 'brush-alt', className: 'lni lni-brush-alt', value:'brush-alt'},
    {label: 'brush', className: 'lni lni-brush', value:'brush'},
    {label: 'bubble', className: 'lni lni-bubble', value:'bubble'},
    {label: 'bug', className: 'lni lni-bug', value:'bug'},
    {label: 'bulb', className: 'lni lni-bulb', value:'bulb'},
    {label: 'bullhorn', className: 'lni lni-bullhorn', value:'bullhorn'},
    {label: 'burger', className: 'lni lni-burger', value:'burger'},
    {label: 'bus', className: 'lni lni-bus', value:'bus'},
    {label: 'cake', className: 'lni lni-cake', value:'cake'},
    {label: 'calculator', className: 'lni lni-calculator', value:'calculator'},
    {label: 'calendar', className: 'lni lni-calendar', value:'calendar'},
    {label: 'camera', className: 'lni lni-camera', value:'camera'},
    {label: 'candy-cane', className: 'lni lni-candy-cane', value:'candy-cane'},
    {label: 'candy', className: 'lni lni-candy', value:'candy'},
    {label: 'capsule', className: 'lni lni-capsule', value:'capsule'},
    {label: 'car-alt', className: 'lni lni-car-alt', value:'car-alt'},
    {label: 'car', className: 'lni lni-car', value:'car'},
    {label: 'caravan', className: 'lni lni-caravan', value:'caravan'},
    {label: 'cart-full', className: 'lni lni-cart-full', value:'cart-full'},
    {label: 'cart', className: 'lni lni-cart', value:'cart'},
    {label: 'certificate', className: 'lni lni-certificate', value:'certificate'},
    {label: 'checkbox', className: 'lni lni-checkbox', value:'checkbox'},
    {label: 'checkmark-circle', className: 'lni lni-checkmark-circle', value:'checkmark-circle'},
    {label: 'checkmark', className: 'lni lni-checkmark', value:'checkmark'},
    {label: 'chef-hat', className: 'lni lni-chef-hat', value:'chef-hat'},
    {label: 'chevron-down-circle', className: 'lni lni-chevron-down-circle', value:'chevron-down-circle'},
    {label: 'chevron-down', className: 'lni lni-chevron-down', value:'chevron-down'},
    {label: 'chevron-left-circle', className: 'lni lni-chevron-left-circle', value:'chevron-left-circle'},
    {label: 'chevron-left', className: 'lni lni-chevron-left', value:'chevron-left'},
    {label: 'chevron-right-circle', className: 'lni lni-chevron-right-circle', value:'chevron-right-circle'},
    {label: 'chevron-right', className: 'lni lni-chevron-right', value:'chevron-right'},
    {label: 'chevron-up-circle', className: 'lni lni-chevron-up-circle', value:'chevron-up-circle'},
    {label: 'chevron-up', className: 'lni lni-chevron-up', value:'chevron-up'},
    {label: 'chrome', className: 'lni lni-chrome', value:'chrome'},
    {label: 'circle-minus', className: 'lni lni-circle-minus', value:'circle-minus'},
    {label: 'circle-plus', className: 'lni lni-circle-plus', value:'circle-plus'},
    {label: 'clipboard', className: 'lni lni-clipboard', value:'clipboard'},
    {label: 'close', className: 'lni lni-close', value:'close'},
    {label: 'cloud-check', className: 'lni lni-cloud-check', value:'cloud-check'},
    {label: 'cloud-download', className: 'lni lni-cloud-download', value:'cloud-download'},
    {label: 'cloud-network', className: 'lni lni-cloud-network', value:'cloud-network'},
    {label: 'cloud-sync', className: 'lni lni-cloud-sync', value:'cloud-sync'},
    {label: 'cloud-upload', className: 'lni lni-cloud-upload', value:'cloud-upload'},
    {label: 'cloud', className: 'lni lni-cloud', value:'cloud'},
    {label: 'cloudy-sun', className: 'lni lni-cloudy-sun', value:'cloudy-sun'},
    {label: 'code-alt', className: 'lni lni-code-alt', value:'code-alt'},
    {label: 'code', className: 'lni lni-code', value:'code'},
    {label: 'codepen', className: 'lni lni-codepen', value:'codepen'},
    {label: 'coffee-cup', className: 'lni lni-coffee-cup', value:'coffee-cup'},
    {label: 'cog', className: 'lni lni-cog', value:'cog'},
    {label: 'cogs', className: 'lni lni-cogs', value:'cogs'},
    {label: 'coin', className: 'lni lni-coin', value:'coin'},
    {label: 'comments-alt', className: 'lni lni-comments-alt', value:'comments-alt'},
    {label: 'comments-reply', className: 'lni lni-comments-reply', value:'comments-reply'},
    {label: 'comments', className: 'lni lni-comments', value:'comments'},
    {label: 'compass', className: 'lni lni-compass', value:'compass'},
    {label: 'construction-hammer', className: 'lni lni-construction-hammer', value:'construction-hammer'},
    {label: 'construction', className: 'lni lni-construction', value:'construction'},
    {label: 'consulting', className: 'lni lni-consulting', value:'consulting'},
    {label: 'control-panel', className: 'lni lni-control-panel', value:'control-panel'},
    {label: 'cpanel', className: 'lni lni-cpanel', value:'cpanel'},
    {label: 'creative-commons', className: 'lni lni-creative-commons', value:'creative-commons'},
    {label: 'credit-cards', className: 'lni lni-credit-cards', value:'credit-cards'},
    {label: 'crop', className: 'lni lni-crop', value:'crop'},
    {label: 'cross-circle', className: 'lni lni-cross-circle', value:'cross-circle'},
    {label: 'crown', className: 'lni lni-crown', value:'crown'},
    {label: 'css3', className: 'lni lni-css3', value:'css3'},
    {label: 'cup', className: 'lni lni-cup', value:'cup'},
    {label: 'customer', className: 'lni lni-customer', value:'customer'},
    {label: 'cut', className: 'lni lni-cut', value:'cut'},
    {label: 'dashboard', className: 'lni lni-dashboard', value:'dashboard'},
    {label: 'database', className: 'lni lni-database', value:'database'},
    {label: 'delivery', className: 'lni lni-delivery', value:'delivery'},
    {label: 'dev', className: 'lni lni-dev', value:'dev'},
    {label: 'diamond-alt', className: 'lni lni-diamond-alt', value:'diamond-alt'},
    {label: 'diamond', className: 'lni lni-diamond', value:'diamond'},
    {label: 'diners-club', className: 'lni lni-diners-club', value:'diners-club'},
    {label: 'dinner', className: 'lni lni-dinner', value:'dinner'},
    {label: 'direction-alt', className: 'lni lni-direction-alt', value:'direction-alt'},
    {label: 'direction-ltr', className: 'lni lni-direction-ltr', value:'direction-ltr'},
    {label: 'direction-rtl', className: 'lni lni-direction-rtl', value:'direction-rtl'},
    {label: 'direction', className: 'lni lni-direction', value:'direction'},
    {label: 'discord', className: 'lni lni-discord', value:'discord'},
    {label: 'discover', className: 'lni lni-discover', value:'discover'},
    {label: 'display-alt', className: 'lni lni-display-alt', value:'display-alt'},
    {label: 'display', className: 'lni lni-display', value:'display'},
    {label: 'docker', className: 'lni lni-docker', value:'docker'},
    {label: 'dollar', className: 'lni lni-dollar', value:'dollar'},
    {label: 'domain', className: 'lni lni-domain', value:'domain'},
    {label: 'download', className: 'lni lni-download', value:'download'},
    {label: 'dribbble', className: 'lni lni-dribbble', value:'dribbble'},
    {label: 'drop', className: 'lni lni-drop', value:'drop'},
    {label: 'dropbox-original', className: 'lni lni-dropbox-original', value:'dropbox-original'},
    {label: 'dropbox', className: 'lni lni-dropbox', value:'dropbox'},
    {label: 'drupal-original', className: 'lni lni-drupal-original', value:'drupal-original'},
    {label: 'drupal', className: 'lni lni-drupal', value:'drupal'},
    {label: 'dumbbell', className: 'lni lni-dumbbell', value:'dumbbell'},
    {label: 'edge', className: 'lni lni-edge', value:'edge'},
    {label: 'emoji-cool', className: 'lni lni-emoji-cool', value:'emoji-cool'},
    {label: 'emoji-friendly', className: 'lni lni-emoji-friendly', value:'emoji-friendly'},
    {label: 'emoji-happy', className: 'lni lni-emoji-happy', value:'emoji-happy'},
    {label: 'emoji-sad', className: 'lni lni-emoji-sad', value:'emoji-sad'},
    {label: 'emoji-smile', className: 'lni lni-emoji-smile', value:'emoji-smile'},
    {label: 'emoji-speechless', className: 'lni lni-emoji-speechless', value:'emoji-speechless'},
    {label: 'emoji-suspect', className: 'lni lni-emoji-suspect', value:'emoji-suspect'},
    {label: 'emoji-tounge', className: 'lni lni-emoji-tounge', value:'emoji-tounge'},
    {label: 'empty-file', className: 'lni lni-empty-file', value:'empty-file'},
    {label: 'enter', className: 'lni lni-enter', value:'enter'},
    {label: 'envato', className: 'lni lni-envato', value:'envato'},
    {label: 'envelope', className: 'lni lni-envelope', value:'envelope'},
    {label: 'eraser', className: 'lni lni-eraser', value:'eraser'},
    {label: 'euro', className: 'lni lni-euro', value:'euro'},
    {label: 'exit-down', className: 'lni lni-exit-down', value:'exit-down'},
    {label: 'exit-up', className: 'lni lni-exit-up', value:'exit-up'},
    {label: 'exit', className: 'lni lni-exit', value:'exit'},
    {label: 'eye', className: 'lni lni-eye', value:'eye'},
    {label: 'facebook-filled', className: 'lni lni-facebook-filled', value:'facebook-filled'},
    {label: 'facebook-messenger', className: 'lni lni-facebook-messenger', value:'facebook-messenger'},
    {label: 'facebook-original', className: 'lni lni-facebook-original', value:'facebook-original'},
    {label: 'facebook-oval', className: 'lni lni-facebook-oval', value:'facebook-oval'},
    {label: 'facebook', className: 'lni lni-facebook', value:'facebook'},
    {label: 'figma', className: 'lni lni-figma', value:'figma'},
    {label: 'files', className: 'lni lni-files', value:'files'},
    {label: 'firefox-original', className: 'lni lni-firefox-original', value:'firefox-original'},
    {label: 'firefox', className: 'lni lni-firefox', value:'firefox'},
    {label: 'fireworks', className: 'lni lni-fireworks', value:'fireworks'},
    {label: 'first-aid', className: 'lni lni-first-aid', value:'first-aid'},
    {label: 'flag-alt', className: 'lni lni-flag-alt', value:'flag-alt'},
    {label: 'flag', className: 'lni lni-flag', value:'flag'},
    {label: 'flags', className: 'lni lni-flags', value:'flags'},
    {label: 'flickr', className: 'lni lni-flickr', value:'flickr'},
    {label: 'basketball', className: 'lni lni-basketball', value:'basketball'},
    {label: 'behance', className: 'lni lni-behance', value:'behance'},
    {label: 'forward', className: 'lni lni-forward', value:'forward'},
    {label: 'frame-expand', className: 'lni lni-frame-expand', value:'frame-expand'},
    {label: 'flower', className: 'lni lni-flower', value:'flower'},
    {label: 'full-screen', className: 'lni lni-full-screen', value:'full-screen'},
    {label: 'funnel', className: 'lni lni-funnel', value:'funnel'},
    {label: 'gallery', className: 'lni lni-gallery', value:'gallery'},
    {label: 'game', className: 'lni lni-game', value:'game'},
    {label: 'gift', className: 'lni lni-gift', value:'gift'},
    {label: 'git', className: 'lni lni-git', value:'git'},
    {label: 'github-original', className: 'lni lni-github-original', value:'github-original'},
    {label: 'github', className: 'lni lni-github', value:'github'},
    {label: 'goodreads', className: 'lni lni-goodreads', value:'goodreads'},
    {label: 'google-drive', className: 'lni lni-google-drive', value:'google-drive'},
    {label: 'google-pay', className: 'lni lni-google-pay', value:'google-pay'},
    {label: 'fresh-juice', className: 'lni lni-fresh-juice', value:'fresh-juice'},
    {label: 'folder', className: 'lni lni-folder', value:'folder'},
    {label: 'bi-cycle', className: 'lni lni-bi-cycle', value:'bi-cycle'},
    {label: 'graph', className: 'lni lni-graph', value:'graph'},
    {label: 'grid-alt', className: 'lni lni-grid-alt', value:'grid-alt'},
    {label: 'grid', className: 'lni lni-grid', value:'grid'},
    {label: 'google-wallet', className: 'lni lni-google-wallet', value:'google-wallet'},
    {label: 'grow', className: 'lni lni-grow', value:'grow'},
    {label: 'hammer', className: 'lni lni-hammer', value:'hammer'},
    {label: 'hand', className: 'lni lni-hand', value:'hand'},
    {label: 'handshake', className: 'lni lni-handshake', value:'handshake'},
    {label: 'harddrive', className: 'lni lni-harddrive', value:'harddrive'},
    {label: 'headphone-alt', className: 'lni lni-headphone-alt', value:'headphone-alt'},
    {label: 'headphone', className: 'lni lni-headphone', value:'headphone'},
    {label: 'heart-filled', className: 'lni lni-heart-filled', value:'heart-filled'},
    {label: 'heart-monitor', className: 'lni lni-heart-monitor', value:'heart-monitor'},
    {label: 'heart', className: 'lni lni-heart', value:'heart'},
    {label: 'helicopter', className: 'lni lni-helicopter', value:'helicopter'},
    {label: 'helmet', className: 'lni lni-helmet', value:'helmet'},
    {label: 'help', className: 'lni lni-help', value:'help'},
    {label: 'highlight-alt', className: 'lni lni-highlight-alt', value:'highlight-alt'},
    {label: 'highlight', className: 'lni lni-highlight', value:'highlight'},
    {label: 'home', className: 'lni lni-home', value:'home'},
    {label: 'hospital', className: 'lni lni-hospital', value:'hospital'},
    {label: 'hourglass', className: 'lni lni-hourglass', value:'hourglass'},
    {label: 'html5', className: 'lni lni-html5', value:'html5'},
    {label: 'image', className: 'lni lni-image', value:'image'},
    {label: 'inbox', className: 'lni lni-inbox', value:'inbox'},
    {label: 'indent-decrease', className: 'lni lni-indent-decrease', value:'indent-decrease'},
    {label: 'indent-increase', className: 'lni lni-indent-increase', value:'indent-increase'},
    {label: 'infinite', className: 'lni lni-infinite', value:'infinite'},
    {label: 'information', className: 'lni lni-information', value:'information'},
    {label: 'instagram-filled', className: 'lni lni-instagram-filled', value:'instagram-filled'},
    {label: 'instagram-original', className: 'lni lni-instagram-original', value:'instagram-original'},
    {label: 'instagram', className: 'lni lni-instagram', value:'instagram'},
    {label: 'invention', className: 'lni lni-invention', value:'invention'},
    {label: 'graduation', className: 'lni lni-graduation', value:'graduation'},
    {label: 'invest-monitor', className: 'lni lni-invest-monitor', value:'invest-monitor'},
    {label: 'island', className: 'lni lni-island', value:'island'},
    {label: 'italic', className: 'lni lni-italic', value:'italic'},
    {label: 'java', className: 'lni lni-java', value:'java'},
    {label: 'javascript', className: 'lni lni-javascript', value:'javascript'},
    {label: 'jcb', className: 'lni lni-jcb', value:'jcb'},
    {label: 'joomla-original', className: 'lni lni-joomla-original', value:'joomla-original'},
    {label: 'joomla', className: 'lni lni-joomla', value:'joomla'},
    {label: 'jsfiddle', className: 'lni lni-jsfiddle', value:'jsfiddle'},
    {label: 'juice', className: 'lni lni-juice', value:'juice'},
    {label: 'key', className: 'lni lni-key', value:'key'},
    {label: 'keyboard', className: 'lni lni-keyboard', value:'keyboard'},
    {label: 'keyword-research', className: 'lni lni-keyword-research', value:'keyword-research'},
    {label: 'hacker-news', className: 'lni lni-hacker-news', value:'hacker-news'},
    {label: 'google', className: 'lni lni-google', value:'google'},
    {label: 'laravel', className: 'lni lni-laravel', value:'laravel'},
    {label: 'layers', className: 'lni lni-layers', value:'layers'},
    {label: 'layout', className: 'lni lni-layout', value:'layout'},
    {label: 'leaf', className: 'lni lni-leaf', value:'leaf'},
    {label: 'library', className: 'lni lni-library', value:'library'},
    {label: 'licencse', className: 'lni lni-licencse', value:'licencse'},
    {label: 'life-ring', className: 'lni lni-life-ring', value:'life-ring'},
    {label: 'line-dashed', className: 'lni lni-line-dashed', value:'line-dashed'},
    {label: 'line-dotted', className: 'lni lni-line-dotted', value:'line-dotted'},
    {label: 'line-double', className: 'lni lni-line-double', value:'line-double'},
    {label: 'line-spacing', className: 'lni lni-line-spacing', value:'line-spacing'},
    {label: 'line', className: 'lni lni-line', value:'line'},
    {label: 'lineicons-alt', className: 'lni lni-lineicons-alt', value:'lineicons-alt'},
    {label: 'lineicons', className: 'lni lni-lineicons', value:'lineicons'},
    {label: 'link', className: 'lni lni-link', value:'link'},
    {label: 'linkedin-original', className: 'lni lni-linkedin-original', value:'linkedin-original'},
    {label: 'linkedin', className: 'lni lni-linkedin', value:'linkedin'},
    {label: 'list', className: 'lni lni-list', value:'list'},
    {label: 'lock-alt', className: 'lni lni-lock-alt', value:'lock-alt'},
    {label: 'lock', className: 'lni lni-lock', value:'lock'},
    {label: 'magnet', className: 'lni lni-magnet', value:'magnet'},
    {label: 'magnifier', className: 'lni lni-magnifier', value:'magnifier'},
    {label: 'mailchimp', className: 'lni lni-mailchimp', value:'mailchimp'},
    {label: 'map-marker', className: 'lni lni-map-marker', value:'map-marker'},
    {label: 'map', className: 'lni lni-map', value:'map'},
    {label: 'mashroom', className: 'lni lni-mashroom', value:'mashroom'},
    {label: 'mastercard', className: 'lni lni-mastercard', value:'mastercard'},
    {label: 'medall-alt', className: 'lni lni-medall-alt', value:'medall-alt'},
    {label: 'medall', className: 'lni lni-medall', value:'medall'},
    {label: 'medium', className: 'lni lni-medium', value:'medium'},
    {label: 'laptop', className: 'lni lni-laptop', value:'laptop'},
    {label: 'investment', className: 'lni lni-investment', value:'investment'},
    {label: 'laptop-phone', className: 'lni lni-laptop-phone', value:'laptop-phone'},
    {label: 'megento', className: 'lni lni-megento', value:'megento'},
    {label: 'mic', className: 'lni lni-mic', value:'mic'},
    {label: 'microphone', className: 'lni lni-microphone', value:'microphone'},
    {label: 'menu', className: 'lni lni-menu', value:'menu'},
    {label: 'microscope', className: 'lni lni-microscope', value:'microscope'},
    {label: 'money-location', className: 'lni lni-money-location', value:'money-location'},
    {label: 'minus', className: 'lni lni-minus', value:'minus'},
    {label: 'mobile', className: 'lni lni-mobile', value:'mobile'},
    {label: 'more-alt', className: 'lni lni-more-alt', value:'more-alt'},
    {label: 'mouse', className: 'lni lni-mouse', value:'mouse'},
    {label: 'move', className: 'lni lni-move', value:'move'},
    {label: 'music', className: 'lni lni-music', value:'music'},
    {label: 'network', className: 'lni lni-network', value:'network'},
    {label: 'night', className: 'lni lni-night', value:'night'},
    {label: 'nodejs-alt', className: 'lni lni-nodejs-alt', value:'nodejs-alt'},
    {label: 'nodejs', className: 'lni lni-nodejs', value:'nodejs'},
    {label: 'notepad', className: 'lni lni-notepad', value:'notepad'},
    {label: 'npm', className: 'lni lni-npm', value:'npm'},
    {label: 'offer', className: 'lni lni-offer', value:'offer'},
    {label: 'opera', className: 'lni lni-opera', value:'opera'},
    {label: 'package', className: 'lni lni-package', value:'package'},
    {label: 'page-break', className: 'lni lni-page-break', value:'page-break'},
    {label: 'pagination', className: 'lni lni-pagination', value:'pagination'},
    {label: 'paint-bucket', className: 'lni lni-paint-bucket', value:'paint-bucket'},
    {label: 'paint-roller', className: 'lni lni-paint-roller', value:'paint-roller'},
    {label: 'pallet', className: 'lni lni-pallet', value:'pallet'},
    {label: 'paperclip', className: 'lni lni-paperclip', value:'paperclip'},
    {label: 'more', className: 'lni lni-more', value:'more'},
    {label: 'pause', className: 'lni lni-pause', value:'pause'},
    {label: 'paypal-original', className: 'lni lni-paypal-original', value:'paypal-original'},
    {label: 'microsoft', className: 'lni lni-microsoft', value:'microsoft'},
    {label: 'money-protection', className: 'lni lni-money-protection', value:'money-protection'},
    {label: 'pencil', className: 'lni lni-pencil', value:'pencil'},
    {label: 'paypal', className: 'lni lni-paypal', value:'paypal'},
    {label: 'pencil-alt', className: 'lni lni-pencil-alt', value:'pencil-alt'},
    {label: 'patreon', className: 'lni lni-patreon', value:'patreon'},
    {label: 'phone-set', className: 'lni lni-phone-set', value:'phone-set'},
    {label: 'phone', className: 'lni lni-phone', value:'phone'},
    {label: 'pin', className: 'lni lni-pin', value:'pin'},
    {label: 'pinterest', className: 'lni lni-pinterest', value:'pinterest'},
    {label: 'pie-chart', className: 'lni lni-pie-chart', value:'pie-chart'},
    {label: 'pilcrow', className: 'lni lni-pilcrow', value:'pilcrow'},
    {label: 'plane', className: 'lni lni-plane', value:'plane'},
    {label: 'play', className: 'lni lni-play', value:'play'},
    {label: 'plug', className: 'lni lni-plug', value:'plug'},
    {label: 'plus', className: 'lni lni-plus', value:'plus'},
    {label: 'pointer-down', className: 'lni lni-pointer-down', value:'pointer-down'},
    {label: 'pointer-left', className: 'lni lni-pointer-left', value:'pointer-left'},
    {label: 'pointer-right', className: 'lni lni-pointer-right', value:'pointer-right'},
    {label: 'pointer-up', className: 'lni lni-pointer-up', value:'pointer-up'},
    {label: 'play-store', className: 'lni lni-play-store', value:'play-store'},
    {label: 'pizza', className: 'lni lni-pizza', value:'pizza'},
    {label: 'postcard', className: 'lni lni-postcard', value:'postcard'},
    {label: 'pound', className: 'lni lni-pound', value:'pound'},
    {label: 'power-switch', className: 'lni lni-power-switch', value:'power-switch'},
    {label: 'printer', className: 'lni lni-printer', value:'printer'},
    {label: 'producthunt', className: 'lni lni-producthunt', value:'producthunt'},
    {label: 'protection', className: 'lni lni-protection', value:'protection'},
    {label: 'pulse', className: 'lni lni-pulse', value:'pulse'},
    {label: 'pyramids', className: 'lni lni-pyramids', value:'pyramids'},
    {label: 'python', className: 'lni lni-python', value:'python'},
    {label: 'pointer', className: 'lni lni-pointer', value:'pointer'},
    {label: 'popup', className: 'lni lni-popup', value:'popup'},
    {label: 'quotation', className: 'lni lni-quotation', value:'quotation'},
    {label: 'radio-button', className: 'lni lni-radio-button', value:'radio-button'},
    {label: 'rain', className: 'lni lni-rain', value:'rain'},
    {label: 'quora', className: 'lni lni-quora', value:'quora'},
    {label: 'react', className: 'lni lni-react', value:'react'},
    {label: 'question-circle', className: 'lni lni-question-circle', value:'question-circle'},
    {label: 'php', className: 'lni lni-php', value:'php'},
    {label: 'reddit', className: 'lni lni-reddit', value:'reddit'},
    {label: 'reload', className: 'lni lni-reload', value:'reload'},
    {label: 'restaurant', className: 'lni lni-restaurant', value:'restaurant'},
    {label: 'road', className: 'lni lni-road', value:'road'},
    {label: 'rocket', className: 'lni lni-rocket', value:'rocket'},
    {label: 'rss-feed', className: 'lni lni-rss-feed', value:'rss-feed'},
    {label: 'ruler-alt', className: 'lni lni-ruler-alt', value:'ruler-alt'},
    {label: 'ruler-pencil', className: 'lni lni-ruler-pencil', value:'ruler-pencil'},
    {label: 'ruler', className: 'lni lni-ruler', value:'ruler'},
    {label: 'rupee', className: 'lni lni-rupee', value:'rupee'},
    {label: 'save', className: 'lni lni-save', value:'save'},
    {label: 'school-bench-alt', className: 'lni lni-school-bench-alt', value:'school-bench-alt'},
    {label: 'school-bench', className: 'lni lni-school-bench', value:'school-bench'},
    {label: 'scooter', className: 'lni lni-scooter', value:'scooter'},
    {label: 'scroll-down', className: 'lni lni-scroll-down', value:'scroll-down'},
    {label: 'search-alt', className: 'lni lni-search-alt', value:'search-alt'},
    {label: 'search', className: 'lni lni-search', value:'search'},
    {label: 'select', className: 'lni lni-select', value:'select'},
    {label: 'seo', className: 'lni lni-seo', value:'seo'},
    {label: 'service', className: 'lni lni-service', value:'service'},
    {label: 'share-alt', className: 'lni lni-share-alt', value:'share-alt'},
    {label: 'share', className: 'lni lni-share', value:'share'},
    {label: 'shield', className: 'lni lni-shield', value:'shield'},
    {label: 'shift-left', className: 'lni lni-shift-left', value:'shift-left'},
    {label: 'shift-right', className: 'lni lni-shift-right', value:'shift-right'},
    {label: 'ship', className: 'lni lni-ship', value:'ship'},
    {label: 'shopify', className: 'lni lni-shopify', value:'shopify'},
    {label: 'shopping-basket', className: 'lni lni-shopping-basket', value:'shopping-basket'},
    {label: 'shortcode', className: 'lni lni-shortcode', value:'shortcode'},
    {label: 'shovel', className: 'lni lni-shovel', value:'shovel'},
    {label: 'shuffle', className: 'lni lni-shuffle', value:'shuffle'},
    {label: 'signal', className: 'lni lni-signal', value:'signal'},
    {label: 'sketch', className: 'lni lni-sketch', value:'sketch'},
    {label: 'skipping-rope', className: 'lni lni-skipping-rope', value:'skipping-rope'},
    {label: 'skype', className: 'lni lni-skype', value:'skype'},
    {label: 'slack', className: 'lni lni-slack', value:'slack'},
    {label: 'slice', className: 'lni lni-slice', value:'slice'},
    {label: 'slideshare', className: 'lni lni-slideshare', value:'slideshare'},
    {label: 'slim', className: 'lni lni-slim', value:'slim'},
    {label: 'reply', className: 'lni lni-reply', value:'reply'},
    {label: 'sort-alpha-asc', className: 'lni lni-sort-alpha-asc', value:'sort-alpha-asc'},
    {label: 'remove-file', className: 'lni lni-remove-file', value:'remove-file'},
    {label: 'sort-amount-dsc', className: 'lni lni-sort-amount-dsc', value:'sort-amount-dsc'},
    {label: 'sort-amount-asc', className: 'lni lni-sort-amount-asc', value:'sort-amount-asc'},
    {label: 'soundcloud', className: 'lni lni-soundcloud', value:'soundcloud'},
    {label: 'souncloud-original', className: 'lni lni-souncloud-original', value:'souncloud-original'},
    {label: 'spiner-solid', className: 'lni lni-spiner-solid', value:'spiner-solid'},
    {label: 'revenue', className: 'lni lni-revenue', value:'revenue'},
    {label: 'spinner', className: 'lni lni-spinner', value:'spinner'},
    {label: 'spellcheck', className: 'lni lni-spellcheck', value:'spellcheck'},
    {label: 'spotify', className: 'lni lni-spotify', value:'spotify'},
    {label: 'spray', className: 'lni lni-spray', value:'spray'},
    {label: 'sprout', className: 'lni lni-sprout', value:'sprout'},
    {label: 'snapchat', className: 'lni lni-snapchat', value:'snapchat'},
    {label: 'stamp', className: 'lni lni-stamp', value:'stamp'},
    {label: 'star-empty', className: 'lni lni-star-empty', value:'star-empty'},
    {label: 'star-filled', className: 'lni lni-star-filled', value:'star-filled'},
    {label: 'star-half', className: 'lni lni-star-half', value:'star-half'},
    {label: 'star', className: 'lni lni-star', value:'star'},
    {label: 'stats-down', className: 'lni lni-stats-down', value:'stats-down'},
    {label: 'spinner-arrow', className: 'lni lni-spinner-arrow', value:'spinner-arrow'},
    {label: 'steam', className: 'lni lni-steam', value:'steam'},
    {label: 'stackoverflow', className: 'lni lni-stackoverflow', value:'stackoverflow'},
    {label: 'stop', className: 'lni lni-stop', value:'stop'},
    {label: 'strikethrough', className: 'lni lni-strikethrough', value:'strikethrough'},
    {label: 'sthethoscope', className: 'lni lni-sthethoscope', value:'sthethoscope'},
    {label: 'stumbleupon', className: 'lni lni-stumbleupon', value:'stumbleupon'},
    {label: 'sun', className: 'lni lni-sun', value:'sun'},
    {label: 'support', className: 'lni lni-support', value:'support'},
    {label: 'surf-board', className: 'lni lni-surf-board', value:'surf-board'},
    {label: 'swift', className: 'lni lni-swift', value:'swift'},
    {label: 'syringe', className: 'lni lni-syringe', value:'syringe'},
    {label: 'tab', className: 'lni lni-tab', value:'tab'},
    {label: 'tag', className: 'lni lni-tag', value:'tag'},
    {label: 'target-customer', className: 'lni lni-target-customer', value:'target-customer'},
    {label: 'target-revenue', className: 'lni lni-target-revenue', value:'target-revenue'},
    {label: 'target', className: 'lni lni-target', value:'target'},
    {label: 'taxi', className: 'lni lni-taxi', value:'taxi'},
    {label: 'stats-up', className: 'lni lni-stats-up', value:'stats-up'},
    {label: 'telegram-original', className: 'lni lni-telegram-original', value:'telegram-original'},
    {label: 'telegram', className: 'lni lni-telegram', value:'telegram'},
    {label: 'text-align-center', className: 'lni lni-text-align-center', value:'text-align-center'},
    {label: 'text-align-justify', className: 'lni lni-text-align-justify', value:'text-align-justify'},
    {label: 'text-align-left', className: 'lni lni-text-align-left', value:'text-align-left'},
    {label: 'text-format-remove', className: 'lni lni-text-format-remove', value:'text-format-remove'},
    {label: 'text-align-right', className: 'lni lni-text-align-right', value:'text-align-right'},
    {label: 'text-format', className: 'lni lni-text-format', value:'text-format'},
    {label: 'thought', className: 'lni lni-thought', value:'thought'},
    {label: 'thumbs-down', className: 'lni lni-thumbs-down', value:'thumbs-down'},
    {label: 'thumbs-up', className: 'lni lni-thumbs-up', value:'thumbs-up'},
    {label: 'thunder-alt', className: 'lni lni-thunder-alt', value:'thunder-alt'},
    {label: 'thunder', className: 'lni lni-thunder', value:'thunder'},
    {label: 'ticket-alt', className: 'lni lni-ticket-alt', value:'ticket-alt'},
    {label: 'ticket', className: 'lni lni-ticket', value:'ticket'},
    {label: 'timer', className: 'lni lni-timer', value:'timer'},
    {label: 'train-alt', className: 'lni lni-train-alt', value:'train-alt'},
    {label: 'train', className: 'lni lni-train', value:'train'},
    {label: 'trash', className: 'lni lni-trash', value:'trash'},
    {label: 'travel', className: 'lni lni-travel', value:'travel'},
    {label: 'tree', className: 'lni lni-tree', value:'tree'},
    {label: 'trees', className: 'lni lni-trees', value:'trees'},
    {label: 'trello', className: 'lni lni-trello', value:'trello'},
    {label: 'trowel', className: 'lni lni-trowel', value:'trowel'},
    {label: 'tshirt', className: 'lni lni-tshirt', value:'tshirt'},
    {label: 'tumblr', className: 'lni lni-tumblr', value:'tumblr'},
    {label: 'twitch', className: 'lni lni-twitch', value:'twitch'},
    {label: 'twitter-filled', className: 'lni lni-twitter-filled', value:'twitter-filled'},
    {label: 'twitter-original', className: 'lni lni-twitter-original', value:'twitter-original'},
    {label: 'twitter', className: 'lni lni-twitter', value:'twitter'},
    {label: 'ubuntu', className: 'lni lni-ubuntu', value:'ubuntu'},
    {label: 'underline', className: 'lni lni-underline', value:'underline'},
    {label: 'unlink', className: 'lni lni-unlink', value:'unlink'},
    {label: 'unlock', className: 'lni lni-unlock', value:'unlock'},
    {label: 'upload', className: 'lni lni-upload', value:'upload'},
    {label: 'user', className: 'lni lni-user', value:'user'},
    {label: 'users', className: 'lni lni-users', value:'users'},
    {label: 'ux', className: 'lni lni-ux', value:'ux'},
    {label: 'vector', className: 'lni lni-vector', value:'vector'},
    {label: 'video', className: 'lni lni-video', value:'video'},
    {label: 'vimeo', className: 'lni lni-vimeo', value:'vimeo'},
    {label: 'visa', className: 'lni lni-visa', value:'visa'},
    {label: 'vk', className: 'lni lni-vk', value:'vk'},
    {label: 'volume-high', className: 'lni lni-volume-high', value:'volume-high'},
    {label: 'volume-low', className: 'lni lni-volume-low', value:'volume-low'},
    {label: 'volume-medium', className: 'lni lni-volume-medium', value:'volume-medium'},
    {label: 'volume-mute', className: 'lni lni-volume-mute', value:'volume-mute'},
    {label: 'volume', className: 'lni lni-volume', value:'volume'},
    {label: 'wallet', className: 'lni lni-wallet', value:'wallet'},
    {label: 'warning', className: 'lni lni-warning', value:'warning'},
    {label: 'website-alt', className: 'lni lni-website-alt', value:'website-alt'},
    {label: 'website', className: 'lni lni-website', value:'website'},
    {label: 'wechat', className: 'lni lni-wechat', value:'wechat'},
    {label: 'weight', className: 'lni lni-weight', value:'weight'},
    {label: 'whatsapp', className: 'lni lni-whatsapp', value:'whatsapp'},
    {label: 'wheelbarrow', className: 'lni lni-wheelbarrow', value:'wheelbarrow'},
    {label: 'wheelchair', className: 'lni lni-wheelchair', value:'wheelchair'},
    {label: 'windows', className: 'lni lni-windows', value:'windows'},
    {label: 'wordpress-filled', className: 'lni lni-wordpress-filled', value:'wordpress-filled'},
    {label: 'wordpress', className: 'lni lni-wordpress', value:'wordpress'},
    {label: 'world-alt', className: 'lni lni-world-alt', value:'world-alt'},
    {label: 'world', className: 'lni lni-world', value:'world'},
    {label: 'write', className: 'lni lni-write', value:'write'},
    {label: 'yahoo', className: 'lni lni-yahoo', value:'yahoo'},
    {label: 'ycombinator', className: 'lni lni-ycombinator', value:'ycombinator'},
    {label: 'yen', className: 'lni lni-yen', value:'yen'},
    {label: 'youtube', className: 'lni lni-youtube', value:'youtube'},
    {label: 'zip', className: 'lni lni-zip', value:'zip'},
    {label: 'zoom-in', className: 'lni lni-zoom-in', value:'zoom-in'},
    {label: 'zoom-out', className: 'lni lni-zoom-out', value:'zoom-out'},
    {label: 'teabag', className: 'lni lni-teabag', value:'teabag'},
    {label: 'stripe', className: 'lni lni-stripe', value:'stripe'},
    {label: 'spotify-original', className: 'lni lni-spotify-original', value:'spotify-original'},
];

const boot = (store) => batch(()=>{
    const dispatch = store.dispatch;

    sitePages.forEach(adminPage=>dispatch(registerSitePage(adminPage.id, adminPage)))
    adminPages.forEach(adminPage=>dispatch(registerAdminPage(adminPage.id, adminPage)))
    pageTypes.forEach(pageType=>dispatch(registerPageType(pageType)));

    formFields.forEach(formField=>dispatch(registerFormField(formField)));

    settingsFields.forEach(formField=>dispatch(addSettingsField(formField, formField.configName)));

    dispatch(registerWebFonts(webFonts));
    dispatch(registerAppFonts(appFonts));
    icons.forEach(icon=>dispatch(registerIcon(icon)));

    dataSources.forEach(dataSource => dispatch(registerDataSource(dataSource)))
});

export default boot;
