import "./config";
import "./externals";
import "./index.css";
import initApp from "./initApp";
import installedPlugins from "./installed-plugins";
import * as serviceWorker from "./serviceWorker";


initApp(installedPlugins);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();