import React, {useCallback, useEffect, useState} from "react";
import {pagesCollection} from "../../../../redux/reducers";
import { connect } from "react-redux";
import {constantCase, paramCase} from "change-case";
import {pick} from 'lodash';
import { withFormik } from "formik";
import FormGenerator from "../../../../generators/FormGenerator";
import { withRouter } from "react-router";
import { compose} from 'recompose';
import {elements} from '@evlop/web-components';
import Pages from "..";
const { Button, Row, Col, Section } = elements;

const pageFields = [
    {
        label: 'Name',
        name: 'name'
    },
    {
        label: 'Deep Link',
        name: 'deeplink'
    },
    {
        label: 'Title',
        name: 'title'
    },
];

const onlyUpdate =['name', 'title', 'pageType', 'slug', 'deeplink', 'routeName', 'pageTypeName'];
const onSubmit = (values, {props: {dispatch, history}})=>{
    const {id, name, deeplink = ''} = values;
    let appendValues = {};
    
    if(!values.title){appendValues.title = values.name;}
    if(!values.routeName){appendValues.routeName = constantCase(id || name)}
    appendValues.deeplink = `${deeplink}`.trim();
    if(!appendValues.deeplink){appendValues.deeplink = '/'+paramCase(values.name);}
    if(!appendValues.deeplink.startsWith('/')) appendValues.deeplink = '/'+appendValues.deeplink;
    appendValues.slug = '/__app__'+appendValues.deeplink;

    const selectedValues = pick(values, onlyUpdate);
    const formData = {
        ...selectedValues, ...appendValues, platform: 'APP'};

    const action = id?  pagesCollection.update(id, formData) : pagesCollection.add(formData);
    dispatch(action).then(()=>{
        history.goBack();
    });
};


const AddWebPage = ({handleSubmit, isSubmitting, dispatch, setValues, setFieldValue, match: {params: {id}}, ...props}) => {

    const [fields, setFields] = useState(pageFields);

    const onChangePageType = useCallback((pageType)=>{
        if(pageType) {
            setFieldValue('pageType', pageType.value);
            setFieldValue('name', pageType.label);
            setFieldValue('deeplink', '/' + pageType.path);
            setFieldValue('routeName', pageType.value);
            setFieldValue('pageTypeName', pageType.label);
        }else{
            setFieldValue('pageType', '');
            setFieldValue('routeName', '');
            setFieldValue('pageTypeName', '');
        }
    }, [setFieldValue]);

    useEffect(()=>{
        const pageTypeField = {
                label: 'Page Type',
                name: 'pageType',
                useFullValue: true,
                isClearable: true,
                type: 'page-type',
                onChange: onChangePageType,
            };

        setFields([
            pageTypeField,
            ...pageFields,
        ]);
    }, [onChangePageType]);

    useEffect(()=>{        
        if(id) {
            dispatch(pagesCollection.findById(id))
                .then(({response:data = {}})=>{
                    setValues(data);
                });
        }
    }, [id, dispatch]);

    return (
        <Pages>
            <form onSubmit={handleSubmit}>
                <h1>App Screen Details</h1>
                <FormGenerator fields={fields} />
                <Section paddings={[10, 0]}>
                <Row right>
                    <Col>
                    <Button disabled={isSubmitting}>Save</Button>
                    </Col>
                </Row>
                </Section>
            </form>
            </Pages>
    );
};

export default compose(
    connect(),
    withRouter,
    withFormik({ handleSubmit: onSubmit })
    )(AddWebPage);
