import React from 'react';
import UserManagement from '..';
import breadForm from "../../../../generators/breadForm";
import {UserRole} from "./index";

const AddUserRoleForm = breadForm(UserRole);

const AddUserRole = () => {
    return ( <UserManagement>
        <AddUserRoleForm />
    </UserManagement> );
}
 
export default AddUserRole;
