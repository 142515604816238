import React from "react";
import Modal from "./components/Modal";
import {useSelector} from "react-redux";

const AppModals = () => {
    const modals = useSelector(s=>s.modals);
    return (
        <div>
            {modals.map(modal=><Modal {...modal} />)}
        </div>
    );
};

export default AppModals;
