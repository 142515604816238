import { Model } from '@evlop/commons';
import React from 'react';
import UserManagement from "..";
import breadBackendListView from "../../../../generators/breadBackendListView";


export const schema = {
    name:{label: 'Name'},
    permissions:{
        label: 'Permissions', 
        type: 'select', 
        multiple: true,
        options: [
            {value: '*', label: 'Everything'}, 
            {value: 'ENTER_ADMIN_PANNEL', label: 'Enter admin Admin'}, 
            {value: 'MANAGE_COLLECTIONS', label: 'Manage Collection'},
            {value: 'MANAGE_PAGES', label: 'Manage Pages'},
            {value: 'MANAGE_MENUS', label: 'Manage menus'},
            {value: 'UPDATE_SETTINGS', label: 'Update settings'},
            {value: 'MANAGE_PLUGINS', label: 'Manage Plugins'},
            {value: 'MANAGE_PUBLISHING', label: 'Manage Domains and App Builds'},
            {value: 'MANAGE_USERS', label: 'Manage Users'},
            {value: 'MANAGE_RESOURCES', label: 'Manage Resources'},
        ],
    },
};

export const options = {
    modelName: 'user-roles',
    singularName: 'User Role',
    collectionName: 'user-roles',
    canDelete: true,
    primaryFields: [ 'name', 'updatedAt'],
    schema,
};

export class UserRole extends Model{}
UserRole.config(schema, options);

const UsersList = breadBackendListView(UserRole);

const UserRoles = () => {
    return (
    <UserManagement>
        <UsersList />
    </UserManagement>  );
}
 
export default UserRoles;
