import React, { useMemo } from 'react';
import {chain} from 'lodash';
import { matchPath, useLocation } from 'react-router';
import { Tab, Link } from '@evlop/web-components/dist/src/Elements';
import { useDispatch } from 'react-redux';
import { getPath } from '@evlop/commons';

const TabMenu = ({menuItems}) => {

    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const itemsMapped = useMemo(()=>{
        return chain(menuItems)
        .map(item=> ({
            key: item.routeName,
            path: dispatch(getPath(item.routeName, item.params)),
            ...item,
        }))
        .map(item=>({
            isActive: !!matchPath(pathname, {path: item.path}),
            ...item,
        }))
        .value()
    }, [pathname, menuItems]);

    return ( 
    <Tab>
        {itemsMapped.map(item=>(
            <Tab.Item key={item.key} active={item.isActive}>
                <Link to={item.path}>{item.label}</Link>
            </Tab.Item>))}
    </Tab> );
}
 
export default TabMenu;