import React, {useCallback, useEffect} from "react";
import FormGenerator from "../../generators/FormGenerator";
import _, { get } from 'lodash';
import { withFormik } from "formik";

const dataSourcesFields = [
    {
        type: 'array',
        name: 'dataSources',
        label: 'Data Sources',
        children: [
            {
                type: 'text',
                label: 'Identifier',
                hint: 'Name of variable used to access this datasource ',
                name: 'name',
            },
            {
                type: 'data-source',
                name: 'dataSource',
                label: 'Get data from',
            }
        ]
    }
]

const onChange = _.debounce((newValue, {iframe:{current:{contentWindow}}} ) => {
    const dataSources = get(newValue, 'dataSources', []);
    contentWindow.postMessage({type: 'pageDataSourcesUpdated', data: dataSources}, '*');
}, 50);


const ConfigurationForm = (props) => {
    const {handleSubmit, setValues, iframe, isActive} = props;

    useEffect(()=>{
        if(iframe.current && isActive){
            iframe.current.contentWindow.postMessage({type: 'getPageDataSources'}, '*');
        }
    }, [iframe, isActive]);

    const onWindowMessage = useCallback(({data} = {})=>{
        const {type, data: messageData} = data;
        switch (type) {
            case 'setPageDataSources':
                setValues({dataSources: messageData});
            break;
            default:
        }

    }, [setValues]);

    useEffect(()=>{
        window.addEventListener('message', onWindowMessage)
        return ()=> window.removeEventListener('message', onWindowMessage)
    }, [onWindowMessage]);

    useEffect(()=>{
        if(props.values.dataSources){
            onChange(props.values, props)
        }
    },[props.values]);

    return (
        <form onSubmit={handleSubmit}>
            <div style={{padding: 10, width: 300, display: isActive ? 'block' : 'none'}}>
                <FormGenerator fields={dataSourcesFields} />
            </div>
        </form>
    );
};

export default withFormik({
    mapPropsToValues: ()=>({}),
})(ConfigurationForm);
