import React, { useMemo } from 'react';
import {connect} from "react-redux";
import {openMenuEditModal} from "../../../redux/reducers/editor";
import SelectField from './SelectField';

const MenuSelectField = ({ menus:{collection:menus = {}} = {}, ...props})=>{
    const options = useMemo(()=>{
        let menuOptions = Object.values(menus);
        menuOptions = menuOptions.map(menu=>({label: menu.name, value: menu.id}))
        return menuOptions;
    }, [menus])
    return (<SelectField isClearable {...props} options={options} />);
};

const mapStateToProps = state=>({
    menus: state.menus
});

const mapDispatchToProps = dispatch=>({
    editMenu: params => dispatch(openMenuEditModal(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuSelectField);
