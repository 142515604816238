import {
    GET_TOKEN_SUCCESS,
    getMe,
    LOGIN,
    LOGIN_TOKEN_SUCCESS,
    LOGOUT,
    REFRESH_TOKEN_SUCCESS, 
    refreshToken
} from "../reducers/user";
import {INIT_APP} from "../store";
import {configRestApi, restApiConfig} from "@mukti107/redux-rest";
import environment from "../../environment";
import { GET_TOKEN_FAILED, REFRESH_TOKEN_FAILED } from "@evlop/commons";

const authMiddleware = store => next => action =>{
    switch (action.type) {
        case INIT_APP:
            const refresh_token = localStorage.getItem('refresh_token');
            if(refresh_token) {
                store.dispatch(refreshToken(refresh_token));
            } else {
                next({type: LOGOUT})
            }
            window.addEventListener('message', ({data}) => {
                if(typeof data === 'object' && data.type){
                    store.dispatch(data);
                }
            } , true);
            break;
        case LOGOUT: {
            localStorage.removeItem('token');
            const {options:{headers = {}} = {}} = restApiConfig;

            configRestApi({
                options:{
                    headers: {
                        ...headers,
                        'Authorization': undefined,
                    }
                }
            });
        }
            break;
        case LOGIN: {
            const oauth2Url = new URL(environment.authUrl);
            const {protocol, host, href} = window.location;
            // oauth2Url.searchParams.append('client_id', window.siteConfig.id);
            oauth2Url.searchParams.append('redirect_uri', `${protocol}//${host}/auth_token`);
            oauth2Url.searchParams.append('response_type', 'code');
            oauth2Url.searchParams.append('scope', 'openid offline_access');
            // oauth2Url.searchParams.append('state', btoa(JSON.stringify({client_id: window.siteConfig.id, returnUrl: href})));
            const loginRedirect = `/login?returnTo=${encodeURIComponent(global.location)}`
            window.location.replace(loginRedirect);
            break;
        }
        case REFRESH_TOKEN_FAILED:
        case GET_TOKEN_FAILED:
            next({type: LOGOUT});
            break;
        case REFRESH_TOKEN_SUCCESS:
        case GET_TOKEN_SUCCESS:
            {
            let {access_token: token, expires_at, refresh_token} = action.response;
            const {options: {headers = {}} = {}} = restApiConfig;
            localStorage.setItem('token', token);
            localStorage.setItem('refresh_token', refresh_token);
            localStorage.setItem('token_expires_at', expires_at);
            configRestApi({
                options: {
                    headers: {
                        ...headers,
                        'Authorization': `Bearer ${token}`,
                    }
                }
            });
            store.dispatch(getMe());
            break;
        }
        case LOGIN_TOKEN_SUCCESS: {
            let {token} = action;
            const {options:{headers = {}} = {}} = restApiConfig;
            localStorage.setItem('token', token);
            configRestApi({
                options:{
                    headers: {
                        ...headers,
                        'Authorization': `Bearer ${token}`,
                    }
                }
            });
            store.dispatch(getMe());
        }break;
        default:
    }
    return next(action);
};

export default authMiddleware;
