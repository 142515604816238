/* eslint-disable no-restricted-globals */
import { collectionDeleteRecord, getPath, useCollection } from "@evlop/commons";
import { elements } from '@evlop/web-components';
import { constantCase } from "change-case";
import * as Papa from 'papaparse';
import pluralize from "pluralize";
import React, { useCallback } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const { Button, Row, Col, Container, Section, Icon } = elements;


const mapDispatchToProps = dispatch=>({
    getPath: (...params)=>dispatch(getPath(...params)),
    dispatch,
});

const breadBackendListView = ({ formFields, canEditRecord = true, canDeleteRecord = true, canAddRecord = true, actions = [], routeId, primaryFields=['name'], singularName, pluralName = pluralize(singularName), collectionName = pluralName})=>withRouter(connect(null, mapDispatchToProps)((props)=>{

    const { dispatch, getPath, history, match:{params:{id} = {}} = {}} = props;
    
    const items = useCollection(collectionName);

    const fieldTitles = primaryFields
        .map(fieldName=>formFields.find(f=>f.name===fieldName))
        .map(field=> field && field.label);

    const onClickImport = useCallback(()=>{

    }, []);
    const onSelectImportFile = useCallback((event)=>{
        const {target:{files: [file] = []}} = event;
        const {type} = file;
        if(!type.startsWith('text/csv')) {
            alert('Only CSV import supported');
            return;
        }
        const parsed = Papa.parse(file, {
            complete: function(results) {
                console.log('Parsed: ', results);
            }
        });

    }, []);

    const deleteRecord = useCallback((recordId)=>{
        dispatch(collectionDeleteRecord(collectionName, recordId))
    }, [collectionName, id]);

    const routeNameSingular = constantCase(singularName);

    return (
        <div className="admin">
            <Container fluid>
                {/* <input onChange={onSelectImportFile} ref={fileInput} type="file" /> */}
                {/* <button onClick={onClickImport} className=" float-right btn btn-primary">Import {pluralName}</button> */}

                <Row spaceBetween middle>
                    <Col><h1>{pluralName}</h1></Col>
                    <Col right>{canAddRecord && <Button  onClick={()=>history.push(getPath(`${routeNameSingular}_ADD`))} >
                        <Icon size="xxxs" color="white" className="lni lni-plus" /> &nbsp; Add New {singularName}
                        </Button>}</Col>
                </Row>
                <Section backgroundColor="white" padding={5}>
                    <table width="100%">
                    <tr style={{ textAlign: 'left' }}>
                        {fieldTitles.map(title=><th>{title}</th>)}
                        <th width="150">Actions</th>
                    </tr>
                    {items.map((item)=><tr>
                        {primaryFields.map(fieldName=><td>{item[fieldName]}</td>)}
                        <td>
                                {Array.isArray(actions) && actions.map(a=>a(item))}
                                {canEditRecord && <Button buttonStyle="link" size="xxxs" onClick={()=>history.push(getPath(`${routeNameSingular}_EDIT`, {id: item.id}))} >
                                    <Icon size="xxs" className="lni lni-pencil" />
                                </Button>}
                                {canDeleteRecord && <Button buttonStyle="link" size="xxxs" onClick={()=> confirm(`Are you sure you want to delete this ${singularName}?`) && deleteRecord(item.id)}>
                                    <Icon size="xxs" color="danger-darken-20" className="lni lni-trash-can" />
                                    </Button>}
                        </td>
                    </tr>)}
                </table>
                </Section>
                {items.hasMore &&<Button disabled={items.isLoading} block buttonStyle="link" onClick={items.loadMore}>More</Button>}
            </Container>
        </div>
    )
}));

export default breadBackendListView;
