import { chain, find } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Select from 'react-select';

const styles = {
    control(provided, {selectProps: { menuIsOpen }}){
        return {...provided, backgroundColor: menuIsOpen ? '#fff' : 'transparent', borderWidth: 0, boxShadow: null};
    },
    dropdownIndicator(provided, state){
        return provided
    },
    indicatorSeparator(){
        return {display: 'none'}
    },
    // input(p, {selectProps: { menuIsOpen } = {}}){
    //     return {opacity: menuIsOpen ? 1 : 0}
    // },
    menu(provided){
        return {...provided, borderRadius: '0 0 5px 5px'};
    },
    menuList(){
        return {};
    }
};

const PageSelector = ({value, platform, onChange}) => {
    const history = useHistory();
    const pagesCollection = useSelector(s=> s.pages.collection);
    const pages = useMemo(()=>chain(pagesCollection)
    .values()
    .filter({platform})
    .map(page=> ({
        label: page.name,
        value: page.id,
        slug: page.slug,
    }))
    .value()
    ,[platform, pagesCollection]);

    const onSelectPage = useCallback((selection)=>{
        onChange(selection);
        const newUrl = btoa(selection.slug);
        history.push('/editor/'+newUrl)
    }, [history, onChange]);

    const selection = useMemo(()=>find(pages, {value}), [value, pages]);

    return ( <Select placeholder="Edit page" styles={styles} options={pages} value={selection} onChange={onSelectPage} /> );
}
 
export default PageSelector;