import React, {useMemo} from "react";
import PageMeta from "react-helmet";
import {usePage, useTemplate} from '@evlop/commons';

const PageHeader = () => {
    const { metaTitle: metaTitleRaw, metaDescription: metaDescriptionRaw, metaImage: metaImageRaw} = usePage();
    const template = useTemplate();
    const {favicon, metaTitle, metaDescription, metaImage} = useMemo(()=> {
        return{
            favicon: template('{{config.web.favicon}}'),
            metaTitle: template(metaTitleRaw),
            metaDescription: template(metaDescriptionRaw), 
            metaImage: template(metaImageRaw),
        }
    }, [template, metaTitleRaw, metaDescriptionRaw, metaImageRaw])

    return (
        <PageMeta>
            <title>{metaTitle}</title>
            <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
            <meta name="description" content={metaDescription} />
            <meta name="og:title" content={metaTitle} />
            <meta name="og:description" content={metaDescription} />
            <meta name="og:image" content={metaImage} />
            <meta name="twitter:title" content={metaTitle} />
            <meta name="twitter:description" content={metaDescription} />
            <meta name="twitter:image" content={metaImage} />
        </PageMeta>
    );
};

export default PageHeader;
