import styled from 'styled-components';

const Grid = styled.div({
    display: 'grid',
    placeItems: 'start',
    gridGap: 5,
}, ({columns = 3})=>({
        gridTemplateColumns: Array(columns).fill('1fr').join(' ')
    }));

export default Grid;
