import { Model } from '@evlop/commons';
import React from 'react';
import Publishing from '..';
import breadBackendListView from "../../../../generators/breadBackendListView";


export const schema = {
    platform:{label: 'Platform', type: 'select', options: [{label: 'iOS', value: 'ios'}, {label: 'Android', value: 'android'}]},
    displayName:{label: 'App Display Name', type: 'text'},
    splashImage:{label: 'Splash Screen Image', type: 'image'},
    splashBackgroundColor:{label: 'Splash Screen Background', type: 'raw-color'},
    icon:{label: 'Icon', type: 'image'},
    bundleIdentifier:{label: 'Bundle Identifier'},
    version:{label: 'Version'},
    deeplinkSchemas:{label: 'Version', type: 'tags', placeholder: "eg. myapp://  https://myapp.com",},
    downloadUrl:{label: 'Download URL', type: 'hidden', readOnly: true},
    status:{label: 'Status', type: 'hidden', readOnly: true},
};

export const options = {
    modelName: 'app-build',
    listPageRouteName : 'APP_BUILDS',
    detailsPageRouteName : 'APP_BUILD_EDIT',
    singularName: 'App Build',
    collectionName: 'app-builds',
};

export class AppBuild extends Model{
    static canEditRecord= false
    static canDeleteRecord= false
    static primaryFields= ['platform', 'version', 'status']
    static actions= [({downloadUrl})=>!!downloadUrl ? <a aria-disabled={!downloadUrl} href={downloadUrl}>Download</a> : '-'];
}
AppBuild.config(schema, options);

const AppBuildsListView = breadBackendListView(AppBuild);

const AppBuilds = () => {
    return ( <Publishing>
        <AppBuildsListView />
    </Publishing> );
}
 
export default AppBuilds;
