import { toPath } from 'lodash';
import React, { useMemo } from 'react';
import { connect } from "react-redux";
import FormGenerator from '../../../generators/FormGenerator';
import SelectField from "./SelectField";

const ConnectionTypePageSelectField = ({externalData:{resolvers: dataResolvers = []} = {}, ...props})=>{
    const options = useMemo(()=>{
        return dataResolvers.map(dataResolver=>({label: dataResolver.connectionTypeName, value: dataResolver.connectionType}))    
    }, [dataResolvers])

    const optionsFieldName = useMemo(()=>{
        const optionsPathSegments = toPath(props.field.name)
        optionsPathSegments.splice(-1, 1, 'options')
        return optionsPathSegments.join('.');
    }, [props.field.name]);

    const dataResolver = useMemo(()=>{
        return dataResolvers.find(dataResolver => dataResolver.connectionType === props.field.value);
    }, [props.field.value, dataResolvers]);

    return (<>
        <SelectField {...props} options={options}/>
        {!!dataResolver &&<FormGenerator parent={optionsFieldName} fields={dataResolver.connectionConfigurationOptions} /> }
    </>)
}


const mapStateToProps = state=>({
    externalData: state.externalData
});

export default connect(mapStateToProps)(ConnectionTypePageSelectField);
