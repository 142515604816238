import { Model } from '@evlop/commons';
import React from 'react';
import Developer from '..';
import breadBackendListView from "../../../../generators/breadBackendListView";
import timezones from './timezones';

export const schema = {
    name:{label: 'Name'},
    repetition:{label: 'Repetition', placeholder: 'Select repetition or enter cron pattern', 
    multiple: false,
    type: 'tags', 
    options: [
        {label: 'Every 2 mitues', value: '*/2 * * * *'},
        {label: 'Every 5 mitues', value: '*/5 * * * *'},
        {label: 'Every 10 mitues', value: '*/10 * * * *'},
        {label: 'Every 15 mitues', value: '*/15 * * * *'},
        {label: 'Every 30 mitues', value: '*/30 * * * *'},
        {label: 'Hourly', value: '0 * * * *'},
        {label: 'Every 2 Hours', value: '0 */2 * * *'},
        {label: 'Every 3 Hours', value: '0 */3 * * *'},
        {label: 'Every 4 Hours', value: '0 */4 * * *'},
        {label: 'Every 6 Hours', value: '0 */6 * * *'},
        {label: 'Every 12 Hours', value: '0 0,12 * * *'},
        {label: 'Daily', value: '0 0 * * *'},
        {label: 'Every Sunday', value: '0 0 * * 0'},
        {label: 'Every Monday', value: '0 0 * * 1'},
        {label: 'Every Tuesday', value: '0 0 * * 2'},
        {label: 'Every Wednesday', value: '0 0 * * 3'},
        {label: 'Every Thursday', value: '0 0 * * 4'},
        {label: 'Every Friday', value: '0 0 * * 5'},
        {label: 'Every Saturday', value: '0 0 * * 6'},
        {label: 'Monthly', value: '0 0 1 * *'},
        {label: 'Twice a monthly', value: '0 0 1,15 * *'},
        {label: 'Yearly', value: '0 0 1 1 *'},
    ]},
    timezone: {
        label: 'Timezone', type: 'select', options: timezones.map(timezone=>({label: timezone, value: timezone}))
    },
    code:{label: 'Code', type: 'code'},
};

export const options = {
    modelName: 'scheduled-tasks',
    singularName: 'Scheduled Task',
    collectionName: 'scheduled-tasks',
    primaryFields: [ 'name'],
    schema,
};

export class Worker extends Model{}
Worker.config(schema, options);

const WorkersListView = breadBackendListView(Worker);

const ScheduledTasks = () => {
    return (<Developer><WorkersListView /></Developer>  );
}
 
export default ScheduledTasks;
