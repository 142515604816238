import React, { useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import PagesList from "../../../../components/Admin/PagesList";
import _ from "lodash";
import {elements} from '@evlop/web-components';
import Pages from "..";
import { pagesCollection } from "../../../../redux/reducers";
const { Link, Row, Col, Icon } = elements;

const WebPages = () => {
    const allPages = useSelector(s=>s.pages.records.data);
    const pages = _.filter(allPages, {platform: 'WEB'});

    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(pagesCollection.all());
    }, [dispatch])

    return (
        <Pages>
            <Row middle spaceBetween>
                <Col><h4>Website pages</h4></Col>
                <Col><Link linkStyle="button" to="/admin/pages/web/add"><Icon color="white" size="xxs" className="lni lni-plus" /> Add Page</Link></Col>
            </Row>
            <PagesList pages={pages}/>
        </Pages>
    );
};

export default WebPages;
