import React from "react";

const AdminDashboard = () => {
    return (
        <div>
            Admin Dashboard
        </div>
    );
};

export default AdminDashboard;
