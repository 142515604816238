import React, {useEffect} from "react";
import FormGenerator from "../../generators/FormGenerator";
import {themeFields} from "./themeFields";
import {useSelector} from "react-redux";
import {defaultTheme} from "../../theme";
import _ from 'lodash';
import { withFormik } from "formik";

let oldValue = {};

const onChange = _.debounce((newValue, d, {iframe:{current:{contentWindow}}} ) => {
    const keys = _.union(_.keys(newValue), _.keys(oldValue));
        _.forEach(keys, (key)=>{
            if(oldValue[key]!==newValue[key] && contentWindow){
                contentWindow.postMessage({type: 'themeValueUpdated', key, value: newValue[key]}, '*');
            }
    })
    oldValue = newValue;
}, 50);


const ConfigurationForm = (props) => {
    const {handleSubmit, setValues, isActive} = props;
    const { data:{ theme } = {} } = useSelector(s=>s.config);

    useEffect(()=>{
        setValues({
            ...defaultTheme,
            ...(theme||{})
        });
    }, [setValues, theme]);

    useEffect(()=>{
        onChange(props.values, {}, props)
    },[props, props.values]);

    return (
        <form onSubmit={handleSubmit}>
            <div style={{padding: 10, width: 300, display: isActive ? 'block' : 'none'}}>
                <FormGenerator fields={themeFields} />
            </div>
        </form>
    );
};

export default withFormik({})(ConfigurationForm);
