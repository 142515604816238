import { elements } from '@evlop/web-components';
import { restApiCollection } from "@mukti107/redux-rest";
import { Field, Formik } from "formik";
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { changeNodeAtPath, getNodeAtPath, removeNodeAtPath, default as SortableTree } from 'react-sortable-tree';
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app
import {v4 as uuid} from 'uuid';
import WebActionSelectField from "../../../components/Admin/FormFields/WebActionSelectField";

const Menu = restApiCollection('menus');
const getNodeKey = ({treeIndex, node:{key = treeIndex}}) => key;

const nodeTypeMenuItem = 'menuItem';
const DraggableMenuItem = (props)=>{
  // const [, drag] = useDrag({
  //   item: {type: nodeTypeMenuItem},
  //   begin: ()=>({ node: { key: uuid(), label: '' } })
  // })

  const drag = useRef();
  return (<span ref={drag}><elements.Button {...props}/></span>)
};


const EditMenu = () => {

  const [menuItems, setmenuItems] = useState([]);

  const [name, setName] = useState('');
  const {id} = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  console.log('history',history);

  const [selectedItemPath, setSelectedItemPath] = useState(null);

  const selectedItem = useMemo(()=>{
    const item = getNodeAtPath({
      treeData: menuItems, path: selectedItemPath,getNodeKey});
      return item && item.node;
  }, [selectedItemPath, menuItems])

  const save = useCallback(()=>{
    const data = {name, items: menuItems};
    const action = id? Menu.update(id, data) : Menu.add(data);
    dispatch(action).then(()=>{
      history.goBack();
    });
  }, [dispatch, history, id, menuItems, name])

  useEffect(()=>{
    if(!id) return;
    dispatch(Menu.findById(id)).then(({response: menu})=>{
      setName(menu.name);
      setmenuItems(menu.items)
    })
  }, [id]);

  const getNode = useCallback(({node, path})=>({
    title: (<elements.Input
       style={{border: 0}} 
       value={node.label} 
       placeholder="Type menu label"
       onChange={({target: {value} })=>setmenuItems(treeData=>changeNodeAtPath({
          treeData,
          path,
          getNodeKey,
          newNode: { ...node, label: value },
        }))} 
    />),
    buttons: [
      <elements.Button size="xxxs"
        onClick={()=> setSelectedItemPath(path)}
       buttonStyle="link"><elements.Icon size="xxxs" color="black" className="lni lni-cog" /></elements.Button>,
    <elements.Button size="xxxs"
     onClick={()=>setmenuItems(treeData=>removeNodeAtPath({treeData, path, getNodeKey}))}
     buttonStyle="link"><elements.Icon size="xxxs" color="danger-darken-40" className="lni lni-trash" /></elements.Button>,
  ]
  }), [setmenuItems, setSelectedItemPath, selectedItemPath])

  return ( <div style={{ height: "calc(100vh - 50px)", position: "relative", backgroundColor: '#fff', paddingTop: 40, paddingBottom: 50 }}>
  <div style={{position: 'absolute', right: 0, left: 0, zIndex: 1, top: 0}}>
    <elements.Row>
        <elements.Col auto>
          <elements.Input style={{padding: 12}} placeholder="Menu Name" value={name} onChange={({target: {value}})=> setName(value)} />
        </elements.Col>
        <elements.Col>
          <elements.Button sharp size="sm" onClick={save}>Save</elements.Button>
        </elements.Col>
    </elements.Row>
  </div>
  {!!selectedItem && (
    <div style={{position: 'absolute', right: 20, top: '50%', width: 200, transform: 'translateY(-50%)', zIndex: 1}}>
      {selectedItem.label}
    <Formik key={selectedItem.key} initialValues={{action: selectedItem.action}}>
      {({values: {action}})=>{
        if(action!== selectedItem.action){
          setmenuItems(treeData=>changeNodeAtPath({
            treeData,
            path: selectedItemPath,
            getNodeKey,
            newNode: { ...selectedItem, action },
          }));
        }
        return <Field name="action" component={WebActionSelectField} />
      }}
    </Formik>
    </div>
    )}
    
    <SortableTree
      treeData={menuItems}
      onChange={setmenuItems}
      getNodeKey={getNodeKey}
      generateNodeProps= {getNode}
      dndType={nodeTypeMenuItem}
    />
  <div style={{position: 'absolute', bottom: 0, left: 0}}>
    <DraggableMenuItem onClick={()=>{setmenuItems(menuItems=> menuItems.concat({key: uuid(), label: '', action: {path: '/'}}))}} sharp buttonStyle="link">+ Add menu item</DraggableMenuItem>
  </div>
</div> );
}
 
export default EditMenu;