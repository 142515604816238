import { iconInfo } from '@evlop/commons';
import { get, map, take } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { Icon } from "react-native-elements";
import { connect } from 'react-redux';
import AsyncSelect, { components } from 'react-select';
import styled from "styled-components";

const IconOptionComponent = styled.div({flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center'});

const options = map(iconInfo, ({id, name})=>({ label: name, value: id }))

const { Option, SingleValue } = components;

const IconOption = (props) => {
    const iconDetails = iconInfo[props.data.value]
    return (
    <Option {...props}>
        <IconOptionComponent>
            <div style={{fontSize: 10}}>
                <b>{iconDetails.name}</b><br/>
                by {iconDetails.type}
            </div>
            <Icon type={iconDetails.type} name={iconDetails.name} />
        </IconOptionComponent>
    </Option>
)};

const IconValue = (props) => {
    const iconDetails = props.data
    if(!iconDetails) return null;
    return (
    <SingleValue {...props}>
        <IconOptionComponent>
            <Icon type={iconDetails.type} name={iconDetails.name} />
            <div style={{fontSize: 10, marginLeft: 7, lineHeight: 1}}>
                <b>{iconDetails.name}</b><br/>
                by {iconDetails.type}
            </div>
        </IconOptionComponent>
    </SingleValue>
)};

const IconSelectField = (props)=>{
    const { form, field, label, ...otherProps} = props;
    const [page, setPage] = useState(1);
    const [searchInput, setSearch] = useState('');

    const onInputChange = useCallback((input)=>{
        const search = String(input).toLowerCase();
        setSearch(search);
        setPage(1);
    }, []);

    const onScrollToBottom = useCallback((...p)=>{
        setPage(Math.max(5, page+1));
    }, [page]);

    const filteredOptions = useMemo(()=>{
        return options.filter((item)=>{
                return item.label.includes(searchInput);
            });
    }, [searchInput]);

    const activeOptions = useMemo(()=>{
        return take(filteredOptions, page * 50)
    }, [page, filteredOptions]);

    return (
        <div>
            <label>{label}</label>
            <AsyncSelect
                isClearable
                {...otherProps}
                value={iconInfo[field.value]}
                options={activeOptions}
                placeholder={`Search from ${options.length} icons`}
                onMenuScrollToBottom={onScrollToBottom}
                onInputChange={onInputChange}
                pageSize={30}
                onChange={selectedOption=> form.setFieldValue(field.name, get(selectedOption, 'value')) }
                defaultOptions
                components={{
                    Option: IconOption,
                    SingleValue: IconValue,
                }}
            />
    </div>
    )
};

const mapStateToProps = state=>({
    icons: state.icons,
});

export default connect(mapStateToProps)(IconSelectField);
