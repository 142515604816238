import styled from "styled-components";

const ModalContent = styled.div({
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 5,
    borderRadius: 5,
    backgroundColor: '#fff',
    // overflow: 'hidden',
}, ({large})=>large && ({
    width: 'calc(100vw - 100px)',
    height: 'calc(100vh - 100px)',
}));

export default ModalContent;
