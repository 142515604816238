import {applyMiddleware, combineReducers, createStore} from "redux";
import thunk from "redux-thunk";
import { reducers,dataSourceMiddleware, restApiMiddleware, socketMiddleware, platformEventsMiddleware } from "@evlop/commons";
import authMiddleware from "./middlewares/authMiddleware";
import environment from "../environment";
import { chain } from "lodash";

export const INIT_APP = 'INIT_APP';

export default function createReduxStore(installedPlugins, initialState = {}){
    const pluginReducers = chain(installedPlugins)
                            .flatMap('reducers')
                            .keyBy('key')
                            .value();

    const pluginMiddlewares = chain(installedPlugins)
                            .flatMap('reduxMiddlewares')
                            .filter()
                            .value();
    return createStore(
        combineReducers({...reducers, ...pluginReducers}), 
        initialState,
        applyMiddleware(thunk, dataSourceMiddleware, /* socketMiddleware, */ restApiMiddleware, platformEventsMiddleware, authMiddleware, ...pluginMiddlewares, ...environment.middlewares),
    );
}


// export default store;
