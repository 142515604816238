import React, {useContext, useMemo, useState} from "react";
import styled, {ThemeContext} from "styled-components";
import {elements} from '@evlop/web-components';
import { flatten, reverse, slice } from "lodash";

const ColorOption = styled.button({
    width: 'calc(9.0909% - 4px)',
    height: 20,
    margin: 2,
    borderRadius: 0,
    border: 'solid 1px #fff',
    outline: 'solid #999 1px',
    transition: 'all 0.5s',
    ':hover, :focus':{
        outline: 'solid #999 1px',
        transform: 'scale(1.2)'
    }
}, ({color, active})=>({
    backgroundColor: color,
    transform: active && 'scale(1.2)'
}))

const styles = {
        color: {
            width: '39px',
            height: '17px',
            borderRadius: '2px',
        },
        swatch: {
            padding: '4px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
        },
        popover: {
            position: 'relative',
            zIndex: '2',
        },
        cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        },
    };

const ThemeColorPicker = (props) => {
        const {field:{name, value}, form: {setFieldValue}, label, hint} = props;
        const [showPicker, setShowPicker] = useState(false);

        const { baseColors, colors, colors: {[value]: selectedColor, white, black}} = useContext(ThemeContext);

        const colorPallet = useMemo(()=>flatten(Object.keys(baseColors).map(colorName=>{
            const color = baseColors[colorName];
            const lightenFactors = [50, 40, 30, 20, 10];
            const darkenFactors = reverse(slice(lightenFactors));
            return[
                ...lightenFactors.map((level)=>({
                    name: `${colorName}-lighten-${level}`,
                    color: colors[`${colorName}-lighten-${level}`]
                })),
                {
                    name: colorName,
                    color,
                },
                ...darkenFactors.map((level)=>({
                    name: `${colorName}-darken-${level}`,
                    color: colors[`${colorName}-darken-${level}`],
                })),
            ];
        })), [baseColors, colors]);

        return(
                <elements.Container>
                    <elements.Row spaceBetween middle>
                        <elements.Col>
                            <label>{label}</label>
                        </elements.Col>
                        <elements.Col>
                        <div className="control">
                    <div style={styles.swatch} onClick={()=>setShowPicker(!showPicker)}>
                        <div style={{...styles.color, backgroundColor: selectedColor}} />
                    </div>
                    {showPicker ? <div style={ styles.popover }>
                        <div style={ styles.cover } onClick={()=> setShowPicker(false)}/>
                        <div style={{backgroundColor: '#fff', padding: 4, lineHeight: 1, fontSize: 10, border: '1px solid #e8e8e8', position: 'absolute', zIndex: 3, right: 0, width: 280 }}>
                            {colorPallet.map(c=><ColorOption
                                    type="button"
                                    key={c.name}
                                    onClick={()=>setFieldValue(name, c.name)}
                                    active={c.name===value}
                                    color={c.color}
                                />)}
                            <ColorOption
                                type="button"
                                onClick={()=>setFieldValue(name, 'white')}
                                active={'white'===value}
                                color={white}
                            />
                            <ColorOption
                                type="button"
                                onClick={()=>setFieldValue(name, 'black')}
                                active={'black'===value}
                                color={black}
                            />
                        </div>
                    </div> : null}
                </div>

                        </elements.Col>
                    </elements.Row>
                    </elements.Container>
            )
    }

export default ThemeColorPicker
