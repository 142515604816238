import { getConfig, pagesCollection } from "@evlop/commons";
import { Col, Row } from "@evlop/web-components";
import { get } from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import Components from "./Components";
import ConfigurationForm from "./ConfigurationForm";
import PageDataSourcesForm from "./PageDataSourcesForm";
import PageSelector from "./PageSelector";
import ThemeSettingsForm from "./ThemeSettingsForm";

const DESKTOP = 'DESKTOP';
const TABLET = 'TABLET';
const MOBILE = 'MOBILE';

const devices = [
    {
        type: DESKTOP, 
        label: 'Desktop View',
        screenSize:[1366, 768],
        icon: 'lni lni-display-alt',
        platform: ['WEB'],
    },
    {
        type: TABLET, 
        label: 'Tablet View',
        screenSize:[1024, 768],
        icon: 'lni lni-tab',
        platform: ['WEB'],
    },
    {
        type: MOBILE, 
        label: 'Mobile View',
        screenSize:[480, 800],
        icon: 'lni lni-mobile',
        platform: ['APP', 'WEB'],
    },
    {
        type: 'LANDSCAPE', 
        label: 'Landscape View',
        screenSize:[800, 480],
        icon: 'lni lni-tab',
        platform: ['APP'],
    },
];

const IFrame = styled.iframe({
    position: 'absolute',
    transition: 'all 0.25s',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: '1px solid #e8e8e8',
    borderRadius: 1,
    // maxWidth: '100%',
    // maxHeight: '100%',
}, ({scale = 1, width, height, minHeight, minWidth})=>({
    transform: `translate(-50%, -50%) scale(${scale})`,
    width,
    minWidth,
    minHeight,
    height,
}));

const Wrapper = styled.div({
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    maxHeight: '100vh'
}, 
({loading})=>loading && {opacity: 0}
);

const SidePanel = styled.div({
    width: 0,
    overflowX: 'hidden',
    overflowY: 'overlay',
    position: 'relative',
    backgroundColor: '#e8e8e8',
    borderRight: '1px solid #e8e8e8',
    height: '100%',
    transition: 'width 0.25s',
    '::-webkit-scrollbar': {
        width: 7,
        backgroundColor: 'transparent',
    },
    '::-webkit-scrollbar-thumb' :{
        background: 'rgba(90, 90, 90, 0.5)',
        borderRadius: 10,
      },
}, ({show}) => show && {width: 300,});

const PanelToggleButton = styled.button({
    zIndex: 100,
    position: 'absolute',
    left: '100%',
    top: '50%',
    transform: 'translateY(-50%)',
    height: 40,
    width: 40,
    backgroundColor: '#e8e8e8',
    color: '#000',
    borderWidth: 0,
});

const Header = styled.div({
    backgroundColor: '#f8f8f8',
    borderBottom: '1px solid #e8e8e8',
    padding: 10,
});

const Icon = styled.i({
    fontSize: 24,
    position: 'relative',
    transition: 'color 0.25s',
    cursor: 'pointer',
    color: '#999',
    padding: '0 10px',
    '&:hover': {
        color: '#666'
    }
}, ({active})=>active && {
    '&:hover': {
        color: '#000'
    },
    color: '#000'
}, ({label})=>label && ({
    '&:after':{
        pointerEvents: 'none',
        transition: 'opacity 0.5s, transform 0.5s',
        opacity: 0,
        fontFamily: 'Tahoma',
        whiteSpace: 'nowrap',
        position: 'absolute',
        fontSize: 10,
        top: '100%',
        left: '50%',
        marginTop: 10,
        padding: '3px 10px',
        zIndex: 1000,
        transform: 'translate(-50%, 100%)',
        borderRadius: 3,
        content: `"${label}"`,
        color: '#fff',
        backgroundColor: '#000',
    },
    '&:hover:after':{
        transform: 'translate(-50%, 0)',
        opacity: 1,
    }
}));

const EditorArea = styled.div({
    backgroundColor: '#f8f8f8',
    maxHeight: 'calc(100vh - 59px)',
    flex: 1,
    flexWrap: "wrap"
});

const Editor = () => {

    const { params: { urlHash } } = useRouteMatch();
    const pageUrl = useMemo(()=> atob(urlHash), [urlHash]);
    const iframe = useRef();
    const availableArea = useRef();
    const [isSaving, setIsSaving] = useState(false);
    const [device, setDevice] = useState(DESKTOP);
    const [showPanel, setShowPanel] = useState(null);
    const [activePage, setActivePage] = useState(null);
    const [previewParams, setPreviewParams] = useState({});
    const dispatch = useDispatch();

    const platform = get(activePage, 'platform', 'APP');
    const pageId = get(activePage, 'id');

    const availableDevices = useMemo(()=>{
        return devices.filter(device => device.platform.includes(platform));
    }, [platform]);

    useEffect(()=>{
        setDevice(availableDevices[0].type);
    }, [availableDevices]);

    const activeDevice = useMemo(() => devices.find(d => d.type === device), [device]);

    const onPageChange = useCallback(()=>{
        setActivePage(null);
        setShowPanel(null);
    }, []);

    const adjustSize = useCallback(()=>{
        const {current: availableContainer} = availableArea;
        // if(!availableContainer) return;
        const {height: availableHeight} = availableContainer.getBoundingClientRect();
        const [requiredWidth, requiredHeight] = activeDevice.screenSize;

        const availableWidth = window.innerWidth - (showPanel? 300 : 0)

        const scale = Math.min(availableWidth/requiredWidth, availableHeight/requiredHeight, 1)*0.85;
        setPreviewParams({scale, width: requiredWidth, height: requiredHeight, ...(device === DESKTOP ? {width: Math.max(availableWidth, availableWidth/scale), height: Math.max(availableHeight, availableHeight/scale), minWidth: requiredWidth, minHeight: requiredHeight} : {}) })
        // setPreviewParams({scale, width: requiredWidth, height: requiredHeight })
    }, [showPanel, device, activeDevice, availableArea])

    useEffect(adjustSize, [adjustSize]);

    const enableEditMode = useCallback((event, value, pv, name)=>{
        const { current:{contentWindow} } = iframe;
        contentWindow.postMessage({type: 'enablePageEditing'}, '*');
    }, [iframe]);

    const savePage = useCallback(()=>{
        const { current:{contentWindow} } = iframe;
        contentWindow.postMessage({type: 'savePage'}, '*');
    }, [iframe]);

    const openPreview = useCallback(()=>{
        window.open(pageUrl, pageUrl);
    }, [pageUrl]);

    const toggleSidebar = useCallback(()=>{
        if(showPanel) return setShowPanel(null);
        return setShowPanel('COMPONENTS');
    }, [showPanel]);

    const onConfigUpdate = useCallback(({ data = {} })=>{
        const { type} = data;
        switch(type) {
            case 'savePageStarted':
                setIsSaving(true);
                break;
            case 'savePageFailed':
                alert('An error occurred while trying save changes');
                break;
            case 'savePageCompleted':
                setIsSaving(false);
                dispatch(pagesCollection.all())
                dispatch(getConfig('theme'))
                break;
            case 'pageLoaded':
                setActivePage(data.page);
                enableEditMode();
                break;
            default:
        }
    }, [dispatch, enableEditMode]);

    useEffect(()=>{
        window.addEventListener('message', onConfigUpdate)
        window.addEventListener('resize', adjustSize)
        return ()=> {
            window.removeEventListener('message', onConfigUpdate)
            window.removeEventListener('resize', adjustSize)
        }
    }, [onConfigUpdate, adjustSize]);

    return (
        <>
        {!activePage && <div style={{position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>Loading</div>}
        <Wrapper loading={!activePage}>
            <Header>
                <Row middle spaceBetween>
                    <Col>
                        <div style={{minWidth: 200}}>
                            <PageSelector onChange={onPageChange} value={pageId} platform={platform} />
                        </div>
                    </Col>
                    <Col>
                        <div>
                            {availableDevices.map(d=><Icon key={d.type} label={d.label} onClick={()=> setDevice(d.type)} active={device === d.type} className={d.icon}/>)}
                        </div>
                    </Col>
                    <Col>
                        <Icon active label="Add Component" className="lni lni-plus" onClick={()=> setShowPanel('COMPONENTS')} />
                        <Icon active label="Theme Settings" className="lni lni-pallet" onClick={()=> setShowPanel('THEME')} />
                        <Icon active label="Data Sources" className="lni lni-database" onClick={()=> setShowPanel('DATA_SOURCES')} />
                        <Icon active label={!isSaving && "Save"} className={`lni ${isSaving? 'lni-spinner lni-is-spinning' : 'lni-save'}`} type="button" onClick={savePage} />
                        <Icon active label="Preview" className="lni lni-eye" type="button" onClick={openPreview} />
                    </Col>
                </Row>
            </Header>
            <EditorArea>
                <Row style={{height: '100%'}} noGutters>
                    <Col style={{ height: '100%', position: 'relative'}}>
                        <PanelToggleButton onClick={toggleSidebar}>
                            {showPanel ? <i className="lni lni-close" /> : <i className="lni lni-plus" />}
                        </PanelToggleButton>
                        <SidePanel show={!!showPanel}>
                            <ConfigurationForm isActive={showPanel === 'CONFIGURATION_FORM'} changePanel={setShowPanel} iframe={iframe} />
                            <PageDataSourcesForm isActive={showPanel === 'DATA_SOURCES'} changePanel={setShowPanel} iframe={iframe} />
                            <Components platform={activePage && activePage.platform} changePanel={setShowPanel} isActive={showPanel === 'COMPONENTS'}/>
                            <ThemeSettingsForm changePanel={setShowPanel} isActive={showPanel === 'THEME'} iframe={iframe}/>
                        </SidePanel>
                    </Col>
                    <Col auto >
                        {/* <div style={{ height: '100%', width: '100%', overflow: 'scroll'}}> */}
                            <div ref={availableArea} style={{ maxHeight: '100%', height: '100%', position: 'relative', }}>
                                <IFrame ref={iframe} {...previewParams} name="editor" src={pageUrl} />
                            </div>
                        {/* </div> */}
                    </Col>
                </Row>
            </EditorArea>
        </Wrapper>
    </>
    );
};

export default Editor;
