import { Model } from '@evlop/commons';
import React from 'react';
import UserManagement from "..";
import breadBackendListView from "../../../../generators/breadBackendListView";


export const schema = {
    uuid:{label: 'UUID', readOnly: true},
    name:{label: 'Name', readOnly: true},
    email:{label: 'Email', readOnly: true},
    role:{label: 'Role', type: 'reference', collectionName: 'user-roles'},
    createdAt:{label: 'Created', readOnly: true},
    updatedAt:{label: 'Last Updated', readOnly: true},
};

export const options = {
    modelName: 'users',
    listPageRouteName : 'USERS',
    detailsPageRouteName : 'USER_EDIT',
    singularName: 'User',
    collectionName: 'users',
    schema,
};

export class User extends Model{
    static canDeleteRecord = false
    static canAddRecord = false
    static primaryFields = [ 'name', 'email', 'updatedAt'];
}
User.config(schema, options);

const UsersList = breadBackendListView(User);

const Users = () => {
    return (
    <UserManagement>
        <UsersList />
    </UserManagement>  );
}
 
export default Users;
