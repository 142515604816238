import React from "react";
import Grid from "../Grid";
import PageListItem from "./PageListItem";


const PagesList = ({pages}) => {
    return (
        <Grid columns={3}>
            {pages.map(page=><PageListItem key={page.id} {...page}/>)}
        </Grid>
    );
};

export default PagesList;
