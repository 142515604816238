import { DataProvider, PageProvider } from "@evlop/commons";
import React from "react";
import { useParams} from "react-router";
import {Component} from "@evlop/web-components";

const ComponentPreview = () => {

    const { componentId } = useParams();

    return (
        <div id="component-preview-wrapper" style={{backgroundColor: '#fff'}}>
            <PageProvider value={{}}>
                <DataProvider value={{}}>
                    <Component type={componentId} uuid={componentId} />
                </DataProvider>
            </PageProvider>
        </div>
    );
};

export default ComponentPreview;
