import { getFiles, getSignedUrl, getStockImages } from "@evlop/commons";
import { elements } from '@evlop/web-components';
import axios from "axios";
import _, { uniqBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchInput from "../Admin/SearchInput/index";
import ModalBody from "../Modal/ModalBody";
import ModalContent from "../Modal/ModalContent";
import ModalTitleBar from "../Modal/ModalTitleBar";
const { Container, Row, Col, Button, Icon, Image } = elements;

const ImageSelectorModal = ({closeModal, onSelect}) => {

    const {files, loading, loaded} = useSelector(s=>s.files);
    const uploadsSorted = useMemo(()=> _.reverse(_.sortBy(files, 'LastModified')) , [files])
    const [stockImage, setStockImage] = useState(null);
    const dispatch = useDispatch();
    const uploadField = useRef();
    useEffect(()=>{
        if(!loading && !loaded) {
            dispatch(getFiles());
        }
    }, [loading, loaded, dispatch]);

    const selectFiles = useCallback(()=>{
        const {current} = uploadField;
        current.click();
    }, [uploadField]);

    const searchStockImages = useCallback((searchString, page = 1)=>{
            setStockImage(stockImage=>({
                ...stockImage,
                isLoading: true,
                images: page === 1 ? [] : stockImage.images
            }));
        dispatch(getStockImages(searchString, page)).then(({type, response})=>{
            if(type === 'GET_STOCK_IMAGES_SUCCESS') {
                const {results = [], total, total_pages} = response;

                setStockImage((stockImage) => stockImage && ({
                    isLoading: false,
                    query: searchString,
                    images: uniqBy([...stockImage.images, ...results], 'id'),
                    total,
                    total_pages,
                    page,
                }));
            }
        })
    }, [dispatch]);

    const searchStockImagesDebounced = useCallback(_.debounce(searchStockImages, 500), [searchStockImages]);

    const onSearchChange = useCallback((e)=>{
        const searchString = e.target && e.target.value;
        if(searchString) {
            searchStockImagesDebounced(searchString);
        } else {
            setStockImage(null)
        }

    }, [searchStockImagesDebounced]);

    const uploadFile = useCallback((e)=>{
        const files = e.target.files;

        for(let i=0; i<files.length; i++) {
            const formData = new FormData();
            const file = files.item(i);
            formData.append('file', file);
            dispatch(getSignedUrl({name: file.name, type: file.type})).then(({response: {url, signedUrl}}) => {
                const options = {
                    headers: {
                        'Content-Type': file.type,
                        'x-amz-acl': 'public-read'
                    }
                };
                axios.put(signedUrl, file, options).then(res => {
                    dispatch(getFiles()).then(()=>{
                        setStockImage(null)
                    });
                });
            });
        }

    }, [dispatch]);

    return (
        <ModalContent large>
            <ModalTitleBar
                title="Select Image"
            >
            </ModalTitleBar>
            <Container fluid>
                <Row spaceBetween middle>
                    <Col auto>
                        <SearchInput onChange={onSearchChange} placeholder="Search free images online (from unsplash)" />
                    </Col>
                    <Col>
                    or
                    </Col>
                    <Col>
                        <Button color="primary-darken-10" sharp onClick={selectFiles}>
                            <Icon color="white" size="xxs" className="lni lni-upload" /> &nbsp; Upload Image from Computer
                        </Button>
                        <input style={{display: 'none'}} ref={uploadField} accept="image/*" multiple type="file" onChange={uploadFile}/>
                    </Col>
                </Row>
            </Container>
            <ModalBody>
                    {/* <Masonry windowScroll={false} totalCount={stockImage && stockImage.total} loadMore={()=>{searchStockImages(stockImage.query, stockImage.page+1)}} isLoading={!!(stockImage && stockImage.isLoading)}>
                        { stockImage && stockImage.images.map(({id, width, height, user: author, urls:{thumb, regular}, ...rest})=>(
                            <div>
                            <elements.Image aspectRatio={width/height} size='fluid' style={{borderRadius: 4, border: '1px solid #e8e8e8'}} key={id} onClick={()=>{onSelect(regular); close()}} src={thumb} />
                            by {author.name}
                            </div>
                        )) }
                    </Masonry> */}
                <Row>
                    {!stockImage && uploadsSorted.map(f=><Col span={2} key={f.Key}>
                        <Image style={{backgroundColor: '#f8f8f8', border: '3px solid #f5f5f5'}} size="fluid" aspectRatio={1} resizeMode="contain" onClick={()=>{onSelect(f.url); closeModal()}} src={f.url} />
                    </Col>)}
                    {stockImage && stockImage.images.map(({id, width, height, description, user: author, urls:{thumb, regular}, ...rest})=><Col span={2} key={id}>
                        <div style={{position: 'relative'}}>
                        <Image style={{backgroundColor: '#f8f8f8', border: '3px solid #f5f5f5'}} size="fluid" aspectRatio={1} resizeMode="cover" onClick={()=>{onSelect(regular); closeModal()}} src={thumb} />
                        <div style={{position: 'absolute', bottom: 0, right:0, backgroundColor: 'rgba(255,255,255,0.7)', color: '#000', padding: '3px 10px 5px', borderTopLeftRadius: 5}}>
                        <a href={author.links.html} target="_blank" rel="noreferrer" style={{color: '#000', textDecoration: 'none'}}><i class="lni lni-unsplash"></i> <em><small>{author.name}</small></em></a>
                        </div>
                        </div>
                    </Col>)}
                </Row>
            </ModalBody>
        </ModalContent>
    );
};

export default ImageSelectorModal;
