import React from 'react';
import Publishing from "..";
import breadForm from "../../../../generators/breadForm";
import {Domain} from "./index";

const DomainAddForm = breadForm(Domain);

const AddDomain = () => {
    return ( <Publishing>
        <DomainAddForm />
    </Publishing> );
}
 
export default AddDomain;
