import React from 'react';
import { Section, Container, Text, Icon, Row, Col } from '@evlop/web-components';
import styled from 'styled-components';
import {Link, useHistory} from "react-router-dom";
import {useSelector} from "react-redux";

const ButtonWrapper = styled.div(({ delay }) => {
    return {
        padding: 10,
        transition: 'all 0.15s linear',
        transitionDelay: delay,
        transform: 'translateY(100%)',
        opacity: 0,
    };
});

const FloatingMenuItem = styled.div({
    position: 'absolute',
    bottom: '100%',
    left: '50%',
    transform: 'translateX(-50%)',
    // overflow: 'hidden',
    // padding: '0px 20px 0px 10px',
    transition: 'all 0.25s linear',
    pointerEvents: 'none',
});

const FloatingButton = styled.div({
    cursor: 'pointer',
    width: 56,
    height: 56,
    borderRadius: '50%',
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // opacity: 0.8,
    transition: 'all 0.5s',
    backgroundColor: 'tomato',
});

const AbsoluteDiv = styled.div({
    position: 'absolute',
    right: '100%',
    backgroundColor: '#fff',
    top: '50%',
    transform: 'translateY(-50%)',
    transition: 'all 0.5s linear',
    padding: '3px 8px',
    borderRadius: 5,
    border: '1px solid #e8e8e8',
    opacity: 0,
    whiteSpace: 'nowrap',
    marginRight: 10,
});

const SmallFloatingButton = styled.div({
    width: 40,
    height: 40,
    borderRadius: '50%',
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.5s',
    backgroundColor: '#fff',
    [`:hover~${AbsoluteDiv}`]: {
        opacity: 1,
    },
});

const RelativeDiv = styled.div({
    cursor: 'pointer',
    position: 'relative',
});

const FloatingMenuWrapper = styled.div({
    position: 'relative',
    width: 56,
    [`&:hover ${FloatingMenuItem}`]: {
        pointerEvents: 'all',
    },
    [`&:hover ${FloatingButton}`]: {
        // opacity: 1,
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.8), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    },
    [`&:hover ${ButtonWrapper}`]: {
        transform: 'translateY(0%)',
        opacity: 1,
},
})

const StyleIcon = {
    color: '#fff'
};

const FloatingToolbar = () => {

    const contexts = useSelector(s=>s.context);
    const history = useHistory();

    return (
        <div style={{ position: 'fixed', bottom: 15, right: 15 }}>
            <FloatingMenuWrapper>
                <FloatingMenuItem>
                    <ButtonWrapper delay="0s">
                        <RelativeDiv onClick={()=> history.push('/admin')}>
                            <SmallFloatingButton>
                                <i className="lni lni-dashboard"/>
                            </SmallFloatingButton>
                            <AbsoluteDiv>
                                <div title="Admin Dashboard">Go to dashboard</div>
                            </AbsoluteDiv>
                        </RelativeDiv>
                    </ButtonWrapper>

                    {Object.values(contexts).map(({adminActions = []})=>{
                        return adminActions.map(({action, icon, label}) => <ButtonWrapper delay="0.15s">
                            <RelativeDiv onClick={action}>
                                <SmallFloatingButton>
                                    <i className={icon}/>
                                </SmallFloatingButton>
                                <AbsoluteDiv>
                                    <div>{label}</div>
                                </AbsoluteDiv>
                            </RelativeDiv>
                        </ButtonWrapper>)
                    })}
                    ​
                </FloatingMenuItem>
                <FloatingButton>
                    <i className="lni lni-pencil" style={StyleIcon}/>
                </FloatingButton>
            </FloatingMenuWrapper>
        </div>
    );
};

export default FloatingToolbar;
