import { chain, defaults, get } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { connect, useSelector } from "react-redux";
import Select from 'react-select';
import FormGenerator from '../../../generators/FormGenerator';
import { openMenuEditModal } from "../../../redux/reducers/editor";

const AppActionSelectField = ({field: { name, value } = {}, form, label, fields, platform, ...props})=>{
    // action type extracted from value
    const dataSources = useSelector(s=>s.dataSource.dataSources)
    const sourceType = get(value, 'type', 'static');

    const onChangeSourceType = useCallback((selectedOption)=>{
        const selectedDataSource = dataSources[selectedOption.value];
        form.setFieldValue(name, defaults({type: selectedOption.value}, get(selectedDataSource, 'defaultValues')));
    }, [name, form, dataSources]);

    

    const datasourceConfigurationFields = useMemo(()=>{
        switch(sourceType){
            case 'static':
                return fields || [];
            default:
                return get(dataSources, [sourceType, 'configurationOptions'], []);
                
        }
    }, [sourceType, fields, dataSources]);

    const options = useMemo(()=>{
        return chain(dataSources)
        .values()
        .map(dataSource=>({label: dataSource.displayName, value: dataSource.identifier}))
        .value()
    }, [dataSources]);

    const selectedSource = useMemo(()=>{
        return options.find(a=> a.value === sourceType);
    }, [options, sourceType])

    return (
        <div>
            <label>{label}</label>
            <Select {...props} value={selectedSource} onChange={onChangeSourceType} options={options}/>
            <FormGenerator parent={name} fields={datasourceConfigurationFields} />
        </div>
        );
}


const mapStateToProps = state=>({
    pages: state.pages
});

const mapDispatchToProps = dispatch=>({
    editMenu: id => dispatch(openMenuEditModal(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppActionSelectField);
