import React from 'react';
import UserManagement from '..';
import breadForm from "../../../../generators/breadForm";
import {User} from "./index";

const AddUserFrom = breadForm(User);

const AddUser = () => {
    return ( 
    <UserManagement>
        <AddUserFrom/>
    </UserManagement> );
}
 
export default AddUser;
