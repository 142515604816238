import { collectionGetRecord, request, showModal, useCollectionRecord } from '@evlop/commons';
import { elements } from '@evlop/web-components';
import { withFormik } from 'formik';
import { map, pick } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import Developer from "../..";
import { DynamicFormField } from '../../../../../generators/FormGenerator';
import {Collection} from "../index";
import AddEditFormFieldModal from './AddEditFormFieldModal';


const EditCollection = ({setValues, values: {name}}) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const record = useCollectionRecord(Collection.collectionName, id);
    const {formFields} = record;

    useEffect(()=>{
        setValues(pick(record, ['name']))
    }, [record, setValues])
    
    const addField = useCallback(()=>{
        dispatch(showModal(AddEditFormFieldModal, { collection: record  }));
    }, [dispatch, record]);
    
    const editField = useCallback((field)=>{
        dispatch(showModal(AddEditFormFieldModal, { collection: record, field }));
    }, [dispatch, record]);

    const deleteField = useCallback(async (field)=>{
        await dispatch(request(`/collections/${record.id}/fields/${field.id}`, {}, {
            method: 'DELETE'
        }));
        dispatch(collectionGetRecord('collections', record.id))
    }, [dispatch, record.id]);

    const hasNameChanges = useMemo(()=>name !== record.name, [name, record.name]);
    
    const saveName = useCallback(()=>{
        console.log(name);
    }, [name]);

    return (
    <Developer>
        <form>
            <DynamicFormField field={{type: 'text', label:"Name", name: "name"}} />
            {hasNameChanges && <elements.Button onClick={saveName} buttonStyle="link" type="button">Update</elements.Button>}
            {map(formFields, field=>(
                <elements.Section margins={[5, 0]} padding={5} style={{border: '1px solid #ccc', backgroundColor: '#fff'}}>
                <elements.Row middle spaceBetween>
                    <elements.Col>
                        <div>{field.label} <em>({field.type})</em></div>
                    </elements.Col>
                    <elements.Col right>
                        <elements.Button buttonStyle="link" type="button" onClick={()=>deleteField(field)}>Delete</elements.Button>
                        <elements.Button type="button" onClick={()=>editField(field)}>Edit</elements.Button>
                    </elements.Col>
                </elements.Row>
                </elements.Section>
                ))}
        </form>
        <elements.Button onClick={addField} type="button">Add Field</elements.Button>
    </Developer>  );
}
 
export default withFormik({

})(EditCollection);
