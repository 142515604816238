import styled from "styled-components";

const ModalBody = styled.div({
    flexGrow: 1,
    padding: 5,
    backgroundColor: '#fff',
    overflow: 'auto',
});

export default ModalBody;
