import { collectionGetRecord, request } from '@evlop/commons';
import { elements } from '@evlop/web-components';
import { withFormik } from 'formik';
import { find } from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {v4 as uuid} from 'uuid';
import { Collection } from '..';
import ModalContent from '../../../../../components/Modal/ModalContent';
import ModalTitleBar from '../../../../../components/Modal/ModalTitleBar';
import FormGenerator from '../../../../../generators/FormGenerator';

const addFieldsFormFields = find(Collection.formFields, {name: 'formFields'}).children;

function AddEditFormFieldModal(props) {
    const {field, setValues, closeModal, handleSubmit, isSubmitting} = props;

    useEffect(()=>{
        if(field){
            setValues(field)
        } else {
            setValues({id: uuid() })
        }
    }, [field, setValues]);

    return (
    <ModalContent>
        <ModalTitleBar title={field? 'Edit field' : 'Add field'} />
        <form onSubmit={handleSubmit}>
            <elements.Container>
                <FormGenerator fields={addFieldsFormFields} />
            </elements.Container>
            <elements.Container>
            <elements.Section margins={[10, 0, 0, 0]}>
                <elements.Row right>
                    <elements.Col>
                        <elements.Button disabled={isSubmitting} buttonStyle="link" onClick={closeModal} type="button">Cancel</elements.Button>
                        <elements.Button disabled={isSubmitting} loading={isSubmitting}>Save</elements.Button>
                    </elements.Col>
                </elements.Row>
                </elements.Section>
            </elements.Container>
        </form>
    </ModalContent>)
}

export default connect()(withFormik({
    handleSubmit:async (values, {setSubmitting,props})=>{
        const {collection, field, dispatch, closeModal} = props;
        setSubmitting(true);
        if(field){
            await dispatch(request(`/collections/${collection.id}/fields/${field.id}`, values, {method: 'PUT'}));
        } else {
            await dispatch(request(`/collections/${collection.id}/fields`, values, {method: 'POST'}));
        }
        setSubmitting(false);
        await dispatch(collectionGetRecord('collections', collection.id));
        closeModal();
    }
})(AddEditFormFieldModal));