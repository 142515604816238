let environment = {};

switch(process.env.REACT_APP_ENV || process.env.NODE_ENV) {
    case 'production':
        environment = require('./environment.production');
        break;
    case 'debug':
        environment = require('./environment.debug');
        break;
    case 'ssr':
        environment = require('./environment.production');
        break;
    default:
        environment = require('./environment.development');
}

export default environment;

