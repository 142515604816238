import {generatePath} from "react-router-dom";
import Settings from "../../pages/Admin/settings";
import Domains from "../../pages/Admin/publishing/domains";
import AddDomains from "../../pages/Admin/publishing/domains/AddDomain";
import AdminLogin from "../../pages/Login";
import Menus from "../../pages/Admin/menus";
import AddMenu from "../../pages/Admin/menus/AddMenu";
import AdminDashboard from "../../pages/Admin/Dashboard";
import Plugins from "../../pages/Admin/plugins";
import AddPlugin from "../../pages/Admin/plugins/AddPlugin";

import WebPages from "../../pages/Admin/pages/web";
import AddWebPage from "../../pages/Admin/pages/web/AddWebPage";
import AppPages from "../../pages/Admin/pages/app";
import AddAppPage from "../../pages/Admin/pages/app/AddAppPage";


export const REGISTER_PAGE_ROUTE = 'REGISTER_PAGE_ROUTE';
export const REGISTER_PAGE_TYPE = 'REGISTER_PAGE_TYPE';

export const ROUTE_TYPE_PATH = 'PATH';
export const SITE_PAGE = 'SITE_PAGE';
export const VIEW_PAGE = 'PAGE_VIEWER';
export const PAGE_EDITOR = 'PAGE_EDITOR';
export const ROUTE_404 = 'ANY_ROUTE';

export function registerPageType(pageType){
    return {
        type: REGISTER_PAGE_TYPE,
        pageType: pageType
    };
}

export function registerSitePage(id, data){
    return {
        type: REGISTER_PAGE_ROUTE,
        id,
        key: 'site',
        data: data
    };
}

export function registerAdminPage(id, data){
    return {
        type: REGISTER_PAGE_ROUTE,
        id,
        key: 'admin',
        data: data
    };
}

export function getPath (type, options){
    return (dispatch, getState)=>{

        let routeId = type;

        if(type === ROUTE_TYPE_PATH) return options.path;
        if(type === SITE_PAGE) routeId = options.pageType;
        if(type === VIEW_PAGE) return options.slug||options.id;

        const { routes:{site, admin} } = getState();
        const adminRoutesMapped = admin.map(({path, ...routeProps})=>({...routeProps, path: `/admin${path}` }));
        const routes = [...site, ...adminRoutesMapped];
        const route = routes.find(({id})=>id===routeId);
        if(route) {
            return generatePath(route.path, options);
        } else{
            return false;
        }
    }
}

let initialState = {
    site: [
        {
            id: "LOGIN",
            exact:true,
            path:"/login",
            component: AdminLogin
        },
        {
            id: "LOGOUT",
            exact:true,
            path:"/login",
            component: AdminLogin
        },
    ],
    pageTypes:[],
    admin:[
        {
            id: "ADMIN_DASHBOARD",
            exact:true,
            path:"/",
            component: AdminDashboard
        },
        {
            id: "DOMAIN_ADMIN_LIST",
            exact:true,
            path:"/domains",
            component:Domains
        },
        {
            id: "DOMAIN_ADD",
            exact:true,
            path:"/domains/add",
            component:AddDomains
        },
        {
            id: "DOMAIN_EDIT",
            exact:true,
            path:"/domains/:id/edit",
            component:AddDomains
        },
        {
            id: "LIST_WEB_PAGES",
            exact:true,
            path:"/pages/web",
            component:WebPages
        },
        {
            id: "ADD_WEB_PAGE",
            exact:true,
            path:"/pages/web/add",
            component:AddWebPage
        },
        {
            id: "EDIT_WEB_PAGE",
            exact:true,
            path:"/pages/web/:id/edit",
            component:AddWebPage
        },
        {
            id: "LIST_APP_PAGES",
            exact:true,
            path:"/pages/app",
            component:AppPages
        },
        {
            id: "ADD_APP_PAGE",
            exact:true,
            path:"/pages/app/add",
            component:AddAppPage
        },
        {
            id: "EDIT_APP_PAGE",
            exact:true,
            path:"/pages/app/:id/edit",
            component:AddAppPage
        },
        {
            id: "MENUS_ADMIN_LIST",
            exact:true,
            path:"/menus",
            component:Menus
        },
        {
            id: "MENUS_ADD",
            exact:true,
            path:"/menus/add",
            component:AddMenu
        },
        {
            id: "MENUS_EDIT",
            exact:true,
            path:"/menus/:id/edit",
            component:AddMenu
        },
        {
            id: "PLUGINS_ADMIN_LIST",
            exact:true,
            path:"/plugins",
            component:Plugins
        },
        {
            id: "PLUGIN_ADD",
            exact:true,
            path:"/plugins/add",
            component:AddPlugin
        },
        {
            id: "PLUGIN_EDIT",
            exact:true,
            path:"/plugins/:id/edit",
            component:AddPlugin
        },
        {
            id: "SETTINGS",
            exact:true,
            path:"/settings",
            component:Settings
        },
    ]
};


export default (state = initialState, action)=>{
    switch (action.type) {
        case REGISTER_PAGE_ROUTE:{
            const {id, key, data} = action;
            const {[key]: oldRoutes} = state;
            const newRoutes = [...oldRoutes];
            const routeData = {id, exact: true, ...data};
            const existingIndex = newRoutes.findIndex(r=>r.id === id);
            if(existingIndex>-1){
                newRoutes.splice(existingIndex, 1, routeData);
            } else {
                newRoutes.push(routeData);
            }
            return {
                ...state,
                [key]: newRoutes
            };
        }
        case REGISTER_PAGE_TYPE:
            return{
                ...state,
                pageTypes:[
                    ...state.pageTypes,
                    action.pageType,
                ]
            };
        default:
            return state;
    }
}
