export const OPEN_MODAL_EDIT_PLUGIN = 'OPEN_MODAL_EDIT_PLUGIN';
export const OPEN_MODAL_EDIT_MENU = 'OPEN_MODAL_EDIT_MENU';
export const OPEN_MODAL_EDIT_PAGE = 'OPEN_MODAL_EDIT_PAGE';

let initialState = {
    menu: {},
    page: {},
    plugin: {}
};

export const openMenuEditModal = data=>({
    type: OPEN_MODAL_EDIT_MENU,
    data,
});

export const openPluginEditModal = data=>({
    type: OPEN_MODAL_EDIT_PLUGIN,
    data,
});

export const openPageEditModal = data=>({
    type: OPEN_MODAL_EDIT_PAGE,
    data,
});

export default (state = initialState, action)=>{
    switch (action.type) {
        case OPEN_MODAL_EDIT_MENU:
            return {
                ...state,
                menu: action.data
            };
        case OPEN_MODAL_EDIT_PLUGIN:
            return {
                ...state,
                plugin: action.data
            };
        case OPEN_MODAL_EDIT_PAGE:
            return {
                ...state,
                page: action.data
            };
        default:
            return state;
    }
}
