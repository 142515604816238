import React, { useCallback, useEffect, useState } from 'react';
import {debounce} from 'lodash';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-markdown";
import "ace-builds/src-noconflict/theme-textmate";

const options = {
    enableBasicAutocompletion: true,
    // enableLiveAutocompletion: true,
    enableSnippets: true,
    showLineNumbers: false,
    tabSize: 2,
    maxLines: 100,
    showGutter: false,
    minLines: 7,
    highlightActiveLine: false,
    wrap: true
}

const InputField = ({field, form, placeholder, label, hint})=>{
    const updateFormState = useCallback(debounce((value)=>{
        form.setFieldValue(field.name, value)
    }, 200), []);

    const onChange = useCallback((value)=>{
        setEditorValue(value);
        updateFormState(value);
    }, []);

    const [editorValue, setEditorValue] = useState(field.value);

    useEffect(()=>{
        setEditorValue(field.value);
    }, [field.value]);

    return (
    <div className="form-group">
        <label className="form-label">{label}</label>
        {/* <elements.Textarea className="form-input" rows={4} placeholder={placeholder} {...field} /> */}
        <AceEditor 
            mode="markdown"
            theme="textmate"
            showPrintMargin={false}
            placeholder={placeholder} 
            {...field} 
            value={editorValue}
            onChange={onChange} 
            padding={10}
            name={'code_editor'}
            editorProps={{ $blockScrolling: true, $highlightPending: true }}
            setOptions={options}
            style={{width: '100%', border: '1px solid #ccc'}}
        />  
        <p className="form-input-hint">{hint}</p>
    </div>)};

export default InputField;
