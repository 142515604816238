import { Model } from '@evlop/commons';
import React from 'react';
import Developer from '..';
import breadBackendListView from "../../../../generators/breadBackendListView";

export const schema = {
    name:{label: 'Name'},
    eventPath:{label: 'Event Name/Path'},
    code:{label: 'Code', type: 'code'},
};

export const options = {
    modelName: 'workers',
    singularName: 'Worker',
    collectionName: 'workers',
    primaryFields: [ 'name', 'eventPath'],
    schema,
};

export class Worker extends Model{}
Worker.config(schema, options);

const WorkersListView = breadBackendListView(Worker);

const Workers = () => {
    return (<Developer><WorkersListView /></Developer>  );
}
 
export default Workers;
