import {GET, POST} from "@mukti107/redux-rest";

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';

export const LOGIN = 'LOGIN';
export const LOGIN_TOKEN_SUCCESS = 'LOGIN_TOKEN_SUCCESS';
export const LOGOUT = 'LOGOUT';

export const GET_ME_SUCCESS = 'GET_ME_SUCCESS';
export const GET_ME_REQUEST = 'GET_ME_REQUEST';
export const GET_ME_FAILED = 'GET_ME_FAILED';

export const GET_TOKEN_REQUEST = 'GET_TOKEN_REQUEST';
export const GET_TOKEN_SUCCESS = 'GET_TOKEN_SUCCESS';
export const GET_TOKEN_FAILED = 'GET_TOKEN_FAILED';

export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILED = 'REFRESH_TOKEN_FAILED';

export function getMe(){
    return {
        types:[GET_ME_REQUEST, GET_ME_SUCCESS, GET_ME_FAILED],
        endpoint: 'auth/me',
        method: GET,
        isRestRequest: true,
    };
}

export function getToken(data){
    return {
        types:[GET_TOKEN_REQUEST, GET_TOKEN_SUCCESS, GET_TOKEN_FAILED],
        endpoint: 'auth/token',
        method: POST,
        data,
        isRestRequest: true,
    };
}

export function refreshToken(refresh_token){
    return {
        types:[REFRESH_TOKEN_REQUEST, REFRESH_TOKEN_SUCCESS, REFRESH_TOKEN_FAILED],
        endpoint: 'auth/refresh',
        method: POST,
        data:{refresh_token},
        isRestRequest: true,
    };
}

export function login(auto = false) {
    return {
        type: LOGIN,
        auto
    }
}

export function register(data){
    return {
        types:[REGISTER_USER_REQUEST, REGISTER_USER_SUCCESS, REGISTER_USER_FAILURE],
        endpoint: 'auth/register',
        method: POST,
        data,
        isRestRequest: true,
    };
}

export const fetch = (endpoint, options)=>({
    endpoint,
    types:["REQUEST", "SUCCESS", "FAILURE"],
    method: GET,
    isRestRequest: true,
    ...options
});

export function logout(){
    return {
        type:LOGOUT,
    };
}

const initialState = {isLoggedIn: null};
const user = (state =initialState, action)=>{
    switch (action.type) {
        // case LOGIN_SUCCESS:
        //     return {
        //         ...state,
        //         // isLoggedIn: true,
        //     };
        case GET_ME_SUCCESS:
            return {
                ...state,
                ...action.response,
                isLoggedIn: true,
            };
        case GET_ME_FAILED:
        case LOGOUT:
            return {isLoggedIn: false};
        default:
            return state;
    }
}

export default user;
