import { Model } from '@evlop/commons';
import React from 'react';
import Developer from '..';
import breadBackendListView from "../../../../generators/breadBackendListView";

export const schema = {
    name:{label: 'Name'},
    method:{label: 'HTTP Method', type: 'select', options: [
        {label: 'GET', value: 'get',},
        {label: 'POST', value: 'post',},
        {label: 'PUT', value: 'put',},
        {label: 'PATCH', value: 'patch',},
        {label: 'DELETE', value: 'delete',}
    ]},
    path:{label: 'Path'},
    code:{label: 'Code', type: 'code'},
};

export const options = {
    modelName: 'rest-apis',
    listPageRouteName : 'REST_APIS',
    detailsPageRouteName : 'REST_API_EDIT',
    singularName: 'Rest Api',
    collectionName: 'rest-apis',
    primaryFields: [ 'name', 'path'],
    schema,
};

export class RestApi extends Model{}
RestApi.config(schema, options);

const RestApisList = breadBackendListView(RestApi);

const RestApis = () => {
    return ( <Developer>
        <RestApisList />
    </Developer> );
}
 
export default RestApis;
