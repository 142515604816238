import { get } from 'lodash';
import React, { useMemo } from 'react';
import {connect} from "react-redux";
import FormGenerator from '../../../generators/FormGenerator';
import {openMenuEditModal} from "../../../redux/reducers/editor";


const PageParamsField = ({pages:{collection:pages = {}} = {}, field, form, pageField = 'page', ...props})=>{
    const pageId = get(form, `values.${pageField}`)
    const selectedPage = useMemo(()=>Object.values(pages).find(p => p.id === pageId), [pageId])
    const paramsFields = useMemo(()=>{
        if(!selectedPage) return [];
        const slug = selectedPage.slug;
        let matches = `${slug}`.match(/:\w+/g);
        if(!matches) return [];
        return matches.map(field=>({
            type: 'text',
            label: `${selectedPage.name} - ${field.substr(1)}`,
            name: field.substr(1),
        }))
    }, [selectedPage]);
    console.log(field.value);
    return <FormGenerator parent={field.name} fields={paramsFields}></FormGenerator>;
}


const mapStateToProps = state=>({
    pages: state.pages
});

const mapDispatchToProps = dispatch=>({
    editMenu: id => dispatch(openMenuEditModal(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageParamsField);
