import React from "react";
import {ChromePicker} from 'react-color'
import {elements} from '@evlop/web-components'

const styles = {
        color: {
            width: '39px',
            height: '17px',
            borderRadius: '2px',
        },
        swatch: {
            padding: '4px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
        },
        popover: {
            position: 'absolute',
            right: 0,
            zIndex: '2',
        },
        cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        },
    };

export default class ColorPicker extends React.Component{
    state={
        showPicker: false,
    };
    render(){
        const { field, label, form, placeholder, hint} = this.props;
        const {showPicker} = this.state;
        return(
            <div>
                <elements.Container>
                    <elements.Row spaceBetween middle>
                        <elements.Col>
                            <label>{label}</label>
                        </elements.Col>
                        <elements.Col>
                            <div>
                                <div style={styles.swatch} onClick={()=>this.setState({showPicker:!showPicker })}>
                                    <div style={{...styles.color, backgroundColor: field.value}} />
                                </div>
                                <div style={{position: 'relative'}}>
                                    {showPicker ? <div style={ styles.popover }>
                                        <div style={ styles.cover } onClick={()=>this.setState({showPicker:false })}/>
                                        <ChromePicker disableAlpha onChange={({hex})=>form.setFieldValue(field.name, hex)} color={field.value} />
                                    </div> : null}
                                </div>
                            </div>
                        </elements.Col>
                    </elements.Row>
                    <div>{hint}</div>
                </elements.Container>
            </div>
            )
    }
}
