import React, { Suspense } from "react";
import {Route, Switch} from "react-router-dom";
import Frontend from "./pages/Frontend";
import Editor from "./pages/Editor";

const Admin = React.lazy(() => import("./pages/Admin"))

const AdminRouteComponent = () => <Suspense fallback="Please wait..."><Admin /></Suspense>

const AppRoutes = () => {
    return (
        <Switch>
            <Route path="/admin" component={AdminRouteComponent} />
            <Route path="/editor/:urlHash" component={Editor} />
            <Route path="/" component={Frontend} />
        </Switch>
    );
};

export default AppRoutes;
