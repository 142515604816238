import React,{useEffect} from "react";
import {withRouter} from 'react-router-dom';
import {parse} from 'query-string';
import {connect} from "react-redux";
import {getToken} from "../../../redux/reducers/user";
import { useUser } from "@evlop/commons";


const AuthToken = (props) => {
    const  {getToken, history} = props;
    const {search, protocol, host} = window.location;
    const user = useUser();

    useEffect(()=>{
        const queryParams = parse(search);
        getToken({redirect_uri: `${protocol}//${host}/auth_token`, ...queryParams})
    }, [getToken, host, protocol, search]);

    useEffect(()=>{
        if(!user.isLoggedIn) return;
        const queryParams = parse(search);
        try{
            const {state:stateEncoded} = queryParams;
            const {returnUrl} = JSON.parse(atob(stateEncoded));
            const {pathname, search} = new URL(returnUrl);
            if(pathname === '/login') throw new Error('Login')
            history.push(`${pathname}${search}`);
        }catch(e){
            history.push('/');
        }
    }, [history, search, user.isLoggedIn]);

    return (
        <div>
        </div>
    );
};

const mapDispatchToProps = dispatch=> ({
    getToken : data=> dispatch(getToken(data)),
    registerToken: 1
});

export default withRouter(connect(null, mapDispatchToProps)(AuthToken));
