import { saveConfig } from "@evlop/commons";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { reduxForm } from "redux-form";

const onSubmit = (theme, dispatch)=>{
    dispatch(saveConfig(theme, 'theme'));
};

const ComponentsSideBar = ({change, initialize})=> {

    const { data:{ theme = {} } = {} } = useSelector(s=>s.config);

    useEffect(()=>{
        if(theme) initialize(theme);
    }, [theme]);

    const onWindowMessage = useCallback(({data} = {})=>{
        const {type} = data;
        switch (type) {
            case 'themeValueUpdated':
                const {key, value} = data;
                change(key, value);
                break;
            default:
        }
    }, [change]);

    useEffect(()=>{
        window.addEventListener('message', onWindowMessage)
        return ()=> window.removeEventListener('message', onWindowMessage)
    }, [onWindowMessage]);

    return null;
};

export default reduxForm({
    form: 'theme',
    onSubmit,
})(ComponentsSideBar);
