import React from "react";
import {useFormikContext, Field, FieldArray} from 'formik';
import {useSelector} from 'react-redux';
import {singular} from 'pluralize';
import {get} from 'lodash';
import {v4 as uuid} from 'uuid';
import {elements} from '@evlop/web-components';

export const renderFieldArray = (config) => (props) => {
    const { form: {values} = {}, name, push, remove} = props;
    const {children = [], label} = config
    const arrayFieldValues = get(values, name, [])
    return (
    <div>
        <div>
            <label>{label}</label>
        </div>
        {arrayFieldValues.map((field, index) =>(
            <div style={{border: '1px solid #e8e8e8', backgroundColor: '#fff', padding: 7, marginBottom: 5, position: 'relative'}}>
                <div>
                    <div onClick={() => remove(index)} style={{position: 'absolute', zIndex: 1, top: 0, right: 0, backgroundColor: '#f8f8f8', padding: '1px 3px'}}>
                        <elements.Icon color="danger-darken-10" size="xxxs" className="lni lni-close" />
                    </div>
                    <div auto><DynamicFormField parent={`${name}.${index}`} field={children}/></div>
                </div>
            </div>))}
        <elements.Button type="button" buttonStyle="link" onClick={() => push({id: uuid()})}>+ Add {singular(label || 'Field')}</elements.Button>
    </div>)};

export const DynamicFormField = ({field, parent}) =>{
    let dynamicField = field;
    const form = useFormikContext();
    const fieldComponents = useSelector(state => state.components.fieldComponents)
    if(field instanceof Function){
        dynamicField = field(form.values);
    } else if(field.type === 'function'){
        try{
            // eslint-disable-next-line no-new-func
            const fieldGeneratorFunction = new Function('return '+field.function)();
            dynamicField = fieldGeneratorFunction(form.values);
            if(!dynamicField) return null;
        } catch(e) {}
    }
    
    if(Array.isArray(dynamicField)){
        return <FormGenerator parent={parent} fields={dynamicField} />
    }
    const {name, type,  ...otherProps} = dynamicField;
    const fieldName = parent? `${parent}.${name}` : name
    switch (type) {
        case 'panel':
            return (
                <div style={{marginTop: 10, marginBottom: 10, padding: 5, border: '1px solid #e8e8e8', borderRadius: 3, backgroundColor: '#fff'}}>
                    <div>
                        <div className="">{field.label}</div>
                    </div>
                    <div className="card-body">
                        <FormGenerator fields={field.children}/>
                    </div>
                </div>);
        case 'array':
            return <FieldArray name={fieldName} render={renderFieldArray(otherProps)} />;
        case 'row':
        default:
            const {component} = fieldComponents.find(({selector}) => (Array.isArray(selector) && selector.includes(type)) || selector === type)||{};
            return component ? <Field name={fieldName} fieldNameWithoutParent={name} component={component} {...otherProps} /> : null;
    }
};

const FormGenerator = ({parent, fields}) => {
    return fields.map((field)=><DynamicFormField parent={parent} field={field} />);
}
 
export default FormGenerator;
