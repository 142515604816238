import { generateFontSizes, generateSpacingSizes, generateLineHeights } from '@evlop/commons';

const theme = {
    breakpoints: ['480px', '768px', '990px', '1440px'],
    space: generateSpacingSizes(20),
    fontSizes: generateFontSizes(16),
    lineHeights: generateLineHeights(16),
    primaryColor: '#008f7b',
    secondaryColor: '#008f7b',
    headingColor: '#222222',
    textColor: '#666666',
    linkColor: '#008f7b',
    successColor: '#008f7b',
    infoColor: '#008f7b',
    warningColor: '#ff00ff',
    dangerColor: '#ff0000',
    whiteColor: '#ffffff',
    blackColor: '#000000',
    transparentColor: 'transparent',

    adminPrimaryColor: '#E91E63',

    admin: {
        headingSize: 30,
        textSize: 12,
        primary: '#333',
        secondary: '#29335c',
        info: '#008f7b',
        warning: '#ff00ff',
        danger: '#ff0000',
        black: '#111',
        white: '#eee',
    },

    font: {url: "https://fonts.googleapis.com/css?family=Oswald", value: "Oswald"},
    headingFont: {url: "https://fonts.googleapis.com/css?family=Oswald", value: "Oswald"},
    fontSize: 16,

    buttonFontSize: 15,
    buttonFilled: false,
    buttonBordered: true,
    buttonPaddingHorizontal: 10,
    buttonPaddingVertical: 5,
    buttonBorderRadius: 5,
    buttonBorderWidth: 5,

    maxZIndex: 9999999999,

    isWeb: true,
};

export const defaultTheme = theme;

export default theme;
