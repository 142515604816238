import React from "react";
import { DndProvider, DndContext, createDndContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import Home from "./Home";

const context = createDndContext(HTML5Backend);

const App =({store})=>{
    return (
        <Provider store={store}>
            <DndContext.Provider value={context}>
                <Router>
                    <Home />
                </Router>
            </DndContext.Provider>
        </Provider>
    );
};

export default App;
