import React from 'react';
import TabMenu from '../../../components/Admin/TabMenu';


const menuItems = [
    { routeName: 'COLLECTIONS_ADMIN_LIST', label: 'Collections' },
    { routeName: 'REST_APIS_ADMIN_LIST', label: 'Rest Apis' },
    { routeName: 'WORKERS_ADMIN_LIST', label: 'Workers' },
    { routeName: 'SCHEDULED_TASKS_ADMIN_LIST', label: 'Scheduled Tasks' },
]

const Developer = ({children}) => {
    return (
    <div>
        <h1>Resources</h1>
        <TabMenu menuItems={menuItems} />
        {children}
    </div>);
}
 
export default Developer;