import { LayoutProvider, useEditable, VERTICAL_LAYOUT } from "@evlop/commons";
import {VerticalLayout} from '@evlop/web-components';
import React, { useCallback, useMemo } from "react";
import Dropable from "./Dropable";

const PageLayout = ({components, blocks, onChange}) => {

    const editable = useEditable();

    const setBlocks = useCallback((blocks)=>{
        if(onChange) onChange(blocks);
    }, [onChange]);

    const updateIndex = useCallback((index, block)=>{
        if(VERTICAL_LAYOUT === block.type) {
            const {data: {blocks}} = block;
            setBlocks(blocks);
        }else{
            setBlocks([block]);
        }
    }, [setBlocks]);

    const addBlock = useCallback((block)=>{
        const newBlocks = [...blocks, block];
        setBlocks(newBlocks);
    }, [blocks, setBlocks]);

    const onDrop = useCallback((block)=>{
        const { uuid, urls:[url], typeId = uuid, data = {}} = block;
        if(url) {
            const {protocol, pathname} = new URL(url);
            if(protocol === 'component:'){
                const block = {type: pathname, uuid: Math.random(), data: {}};
                addBlock(block);
            }
        } else {
            addBlock({
                uuid: Math.random(),
                type: typeId,
                data,
            });
        }
    }, [ addBlock]);

    const pageLayoutContextData = useMemo(()=>{
        return {
            type: 'layout/page',
            blocks,
            components,
            updateIndex,
        };
    }, [components, blocks, updateIndex]);

    return (
        <LayoutProvider value={pageLayoutContextData}>
            <VerticalLayout editable={editable} index={null} blocks={blocks}/>
            {editable && <Dropable onDrop={onDrop}/>}
        </LayoutProvider>
    );
};

export default PageLayout;
