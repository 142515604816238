import React from "react";
import styled from "styled-components";

const SearchWrapper = styled.div({
    position: 'relative',
    width: '100%',
    border: '1px solid #ccc',
    padding: 8,
});

const SearchInputField = styled.input({
    border: 0,
    width: '100%',
    background: 'transparent',
    paddingLeft: 30,
    outline: 0
});

const SearchIcon = styled.img({
    position: 'absolute',
    height: 20,
    top: '50%',
    left: 15,
    transform: 'translateY(-50%)'
});

const SearchInput = ({placeholder = "Type to search", ...props}) => {
    return (
        <SearchWrapper>
            <SearchInputField placeholder={placeholder} {...props} />
            <SearchIcon src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAC3UlEQVRoQ+2YgW0VMQyG/04AnQA6ATABMAF0AtoJChMAEwATQCcAJgAmaDdoOwF0glaflEhWlNzZybtyr3qWKlV6TuLPdmzn9rTlsrfl9msH8L8jOBqBV5JeS3os6aGkpwnoXNI/SZeSfkj6uRRoDwCGfkqG879HgAHkXQLzrHHpRAHeS3qbvO06oFAC5LOkjz2La2u8AHj6l0mR0fNJsZebiIYHgLzG+Fq6XKXU+J3yHcMQ1nAvXqRUe1Qh5n4cSsprupwyB4DRFxXjMfyDpG/OU4+SfgkCxLORSMwBnFXShoqCQeRzRHAGwFQuK0QAiC6ZAsDDXForp8n4rsPSIiDeFBtwqTkvLC2AWurgeWr+JoSSaiNBNA86otocJUovkfNczGjatGBxEPn/wCh0RbcVgb/FxT0OXFhvhLhHX40yztn3Ls56NQDS5LvZCO9TEpcQomArE2WV9HJLDaBMny+p+7o3DSjSlU+MfvisGgBN6bnZNOyVAEAZ7T+p+bm3qAGUtZ8aPdQtJ6yhMHBelnBPqAHcFAfONTu3txqKQ+fdSwDC+MR46y5TKFzx7uUl3voyWpY2mg1zyhLCqG6bZLhktyoMbd3OKXcxSlwnmNC81QIo02j44VGErzbtdo3UU+N0OS0yoxDiTQizVjmak6acGZLog4bIkE4jwgTKJGqly/tsMNdly57AGiIBRChX03iO8aXnw7Xfks8B1B4erCfUPAF5hHiEJySTZ+tDWHeRmAPAOAYuvN76NMJvTLB40n5WQT9/VvG8J7ogPABA4DmMtCOGx/MtHWBrDglDeAGyIaQNnxZtj4iC5AtbPinzPiGIKECOBvnMZfSC0KSoYKyzpXIYogfAehwI+3k9pxgpgqHcCVKPv1bVGoIYBYimT0u/G2ItAIB1QawJYAqiOWasDaAGMVmV1ghgIWZL6loBgKB7z06nawZwVbgdgMtNCyrtIrCgc11bb30EbgGuk5sx/8CJNAAAAABJRU5ErkJggg=="/>
        </SearchWrapper>
    );
};

export default SearchInput;
