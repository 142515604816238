import { Model } from "@evlop/commons";
import breadBackendListView from "../../../generators/breadBackendListView";

export const schema = {
    name:{label: 'Menu Name'},
};

export const options = {
    modelName: 'menus',
    listPageRouteName : 'MENUS',
    detailsPageRouteName : 'MENUS_EDIT',
    singularName: 'Menu',
    collectionName: 'menus',
    schema,
};

export class Menu extends Model{}
Menu.config(schema, options);

const Menus = breadBackendListView(Menu);

export default Menus;
