import React from "react";
import styled from "styled-components";

const StyledTitleBar = styled.div({
    top: 0,
    left: 0,
    right: 0,
    padding: 15,
    zIndex: 1,
    borderBottom: '1px solid #ccc',
    marginBottom: 10,
});

const ModalTitleBar = ({title, children}) => {
    return (
        <StyledTitleBar>
            <span>{title}</span>
            {children}
        </StyledTitleBar>
    );
};

export default ModalTitleBar;
