import { Model } from '@evlop/commons';
import React from 'react';
import Developer from '..';
import breadBackendListView from "../../../../generators/breadBackendListView";

export const schema = {
    name:{label: 'Name'},
    dataSchema:{label: 'Schema', type: 'json', defaultValue: {name: {label: 'Name'}}},
    options:{label: 'Options', type: 'json'},
};

export const options = {
    modelName: 'collections',
    listPageRouteName : 'COLLECTIONS',
    singularName: 'Collection',
    collectionName: 'collections',
    primaryFields: [ 'name', 'eventPath'],
    schema,
};

export class Collection extends Model{}
Collection.config(schema, options);
Collection.form = [
    {name: 'name', label: 'Collection Name'},
    {name: 'options.hasCMS', type: 'checkbox', label: 'Manageable By Admin'},
    ({options: {hasCMS} = {}})=> hasCMS ? [
        {name: 'options.canAddRecord', type: 'checkbox', label: 'Can add record manually'},
        {name: 'options.canEditRecord', type: 'checkbox', label: 'Can edit record manually'},
        {name: 'options.canDeleteRecord', type: 'checkbox', label: 'Can delete record manually'},
    ] : [],
    {name: 'options.isAccessibeleFromFrontend', type: 'checkbox', label: 'Can read from frontend'},
    ({formFields = []})=>([
        {name: 'options.slugifyField', type: 'select', label: 'Generate slug from', options: formFields.map(f=> ({label: f.label, value: f.name, key: f.name}))},
        {name: 'options.primaryFields', multiple: true, type: 'select', label: 'Show in list view', options: formFields.map(f=> ({label: f.label, value: f.name}))},
    ]),

    {name: 'formFields', label: 'Form Fields', type: 'array', children:[
        {name: 'label', 'label': 'Field Label'},
        {name: 'name', 'label': 'Name of Field'},
        {name: 'type', 'label': 'Field Type', type: 'select', options:[
            {label: 'Text', value: 'text'},
            {label: 'Image', value: 'image'},
            {label: 'Boolean (Trye/False)', value: 'checkbox'},
            {label: 'Color', value: 'color'},
            {label: 'E-mail', value: 'email'},
            {label: 'URL', value: 'url'},
            {label: 'Long Text', value: 'textarea'},
            {label: 'Rich Text', value: 'rich-text'},
            {label: 'JSON field', value: 'json'},
        ]},
        
    ]},
];

const CollectionsListView = breadBackendListView(Collection);

const Collections = () => {
    return (<Developer><CollectionsListView /></Developer>  );
}
 
export default Collections;
